import React from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

type Props =
  | { showContinue: false }
  | {
      showContinue: true;
      onContinue: () => void;
    };

const HealthSafetyWarning = (props: Props) => {
  const { t } = useTranslation();

  let continueButton = null;
  if (props.showContinue)
    continueButton = (
      <S.Button onClick={props.onContinue}>{t("Continue")}</S.Button>
    );

  return (
    <S.HealthSafetyWarning>
      <S.WarningIcon icon={faExclamationTriangle} />
      <p>
        {t("Please ensure you are in a safe location before you continue.")}
      </p>
      {continueButton}
    </S.HealthSafetyWarning>
  );
};

export default HealthSafetyWarning;
