import querystring from "querystring";
import { expired } from "../utils/token";
import { isSuccess, NetworkError } from "./Responses";
import { useApiContext } from "../contexts/ApiContext";
import { useMemo } from "react";
import {
  fetchWithRetry,
  addAuthorizationHeader,
  getHeaders,
  parseResponse,
  parseBlob,
} from "./utils";

type GlobalState = {
  token: string | null;
};

const globalState: GlobalState = { token: null };

const setGlobalToken = (token: string) => (globalState.token = token);

type TokenRefreshResult =
  | { refreshed: true }
  | { refreshed: false; unauthorised: boolean };

const refreshToken = async (): Promise<TokenRefreshResult> => {
  const response = await fetchWithRetry("api/auth/refresh", {
    method: "POST",
    headers: getHeaders("1.1"),
  });

  if (response.status !== 200) {
    return { refreshed: false, unauthorised: response.status === 401 };
  }

  try {
    const result = await parseResponse<string>(response);
    if (isSuccess(result)) {
      setGlobalToken(result.data);
      return { refreshed: true };
    }
  } catch (e) {
    console.error("failed to parse token refresh response", e);
  }

  return { refreshed: false, unauthorised: false };
};

type NetworkErrorHandler = (e: NetworkError) => void;
type UnauthenticatedHandler = () => void;

class Api {
  private onNetworkError: NetworkErrorHandler;
  private onUnauthenticated?: UnauthenticatedHandler;

  constructor(
    onNetworkError: NetworkErrorHandler,
    onUnauthenticated?: UnauthenticatedHandler,
  ) {
    this.onNetworkError = onNetworkError;
    this.onUnauthenticated = onUnauthenticated;
  }

  async get<T>(
    url: string,
    version: string,
    params: querystring.ParsedUrlQueryInput = {},
  ) {
    const queryString = querystring.encode(params);
    const fullUrl = (queryString && `${url}?${queryString}`) || url;
    let response;

    try {
      response = await this.fetch(fullUrl, {
        method: "GET",
        headers: getHeaders(version),
      });
    } catch (e) {
      const networkError = new NetworkError(e);
      this.onNetworkError(networkError);
      return networkError;
    }

    return parseResponse<T>(response);
  }

  async getBlob(
    url: string,
    version: string,
    params: querystring.ParsedUrlQueryInput = {},
  ) {
    const queryString = querystring.encode(params);
    const fullUrl = (queryString && `${url}?${queryString}`) || url;
    let response;

    try {
      response = await this.fetch(fullUrl, {
        method: "GET",
        headers: getHeaders(version),
      });
    } catch (e) {
      const networkError = new NetworkError(e);
      this.onNetworkError(networkError);
      return networkError;
    }

    return parseBlob(response);
  }

  async post<T>(url: string, version: string, body?: object) {
    let response;

    try {
      response = await this.fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: getHeaders(version),
      });
    } catch (e) {
      const networkError = new NetworkError(e);
      this.onNetworkError(networkError);
      return networkError;
    }

    return parseResponse<T>(response);
  }

  async postForm<T>(url: string, version: string, form: FormData) {
    let response;

    const headers = getHeaders(version);
    delete headers["Content-Type"]; // allow the browser to set the Content-Type for the request

    try {
      response = await this.fetch(url, {
        method: "POST",
        body: form,
        headers,
      });
    } catch (e) {
      const networkError = new NetworkError(e);
      this.onNetworkError(networkError);
      return networkError;
    }

    return parseResponse<T>(response);
  }

  async delete<T>(url: string, version: string) {
    let response;

    try {
      response = await this.fetch(url, {
        method: "DELETE",
        headers: getHeaders(version),
      });
    } catch (e) {
      const networkError = new NetworkError(e);
      this.onNetworkError(networkError);
      return networkError;
    }

    return parseResponse<T>(response);
  }

  async fetch(url: string, opts: RequestInit): Promise<Response> {
    if (!globalState.token) {
      return await fetchWithRetry(url, opts);
    }

    if (globalState.token && expired(globalState.token)) {
      await refreshToken();
    }

    const response = await fetchWithRetry(
      url,
      addAuthorizationHeader(opts, globalState.token),
    );
    const unauthorised = response.status === 401;
    const tokenExpired = expired(globalState.token);

    if (unauthorised && tokenExpired) {
      const refreshResult = await refreshToken();

      if (refreshResult.refreshed) {
        return await fetchWithRetry(
          url,
          addAuthorizationHeader(opts, globalState.token),
        );
      }

      if (refreshResult.unauthorised && this.onUnauthenticated) {
        this.onUnauthenticated();
      }
    }

    return response;
  }
}

const useApi = () => {
  const { onNetworkError, onUnauthenticated } = useApiContext();

  return useMemo(
    () => new Api(onNetworkError, onUnauthenticated),
    [onUnauthenticated, onNetworkError],
  );
};

export { useApi, Api, setGlobalToken };
export * from "./Responses";
