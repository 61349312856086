import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as M from "360";
import { useApi, mapSuccess, isSuccess } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import GeneralErrors from "../../general-errors/GeneralErrors";
import Documents from "./Documents";
import S from "./styles";

type DocumentsResponse = {
  key: string;
  documents: M.Document[];
};

const getSupplierDocumentUrl = (id: string, key: string) =>
  `api/documents/supplier/${id}?v=1&key=${key}`;

const SupplierDocuments = ({ onBackClick }: { onBackClick: () => void }) => {
  const [documents, setDocuments] = useState<DocumentsResponse | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<any>("api/documents/supplier", "1.0");
        const mapped = mapSuccess(
          response,
          (data): DocumentsResponse => ({
            ...data,
            documents: data.documents.map((document: any) => ({
              ...document,
              effective:
                document.effective !== null
                  ? new Date(document.effective)
                  : null,
              expiry:
                document.expiry !== null ? new Date(document.expiry) : null,
            })),
          })
        );

        if (isSuccess(mapped)) {
          setDocuments(mapped.data);
          return;
        }
      } catch {}

      setErrors([
        t(
          "Failed to load required data. Please reload 360\u{B0}Smart and try again."
        ),
      ]);
    })();
  }, [t, api]);

  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={onBackClick} />;

  if (documents === null) return <Spinner />;

  const documentsWithUrls = documents.documents.map((d) => ({
    ...d,
    url: getSupplierDocumentUrl(d.id, documents.key),
  }));

  return (
    <div>
      <Documents documents={documentsWithUrls} />
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
    </div>
  );
};

export default SupplierDocuments;
