import styled from "styled-components";

const HelpMessage = styled.p`
  margin-bottom: 5px;
  text-align: center;
`;

const DefaultNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export default { HelpMessage, DefaultNumber };
