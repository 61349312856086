import styled from "styled-components";
import _Button from "../../../../../button/Button";
import _FieldErrors from "../../../../../field-errors/FieldErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colours from "../../../../../../utils/colours";

const TyreSize = styled.div`
  & > * {
    display: block;
    margin-bottom: 5px;
  }
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const FieldErrors = styled(_FieldErrors)``;

const Warning = styled.div`
  display: flex;
  align-items: center;
  max-width: 16rem;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-right: 5px;
  color: ${colours.yellow};
`;

export default { TyreSize, Button, FieldErrors, Warning, WarningIcon };
