import React from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  onBackClick: () => void;
};

const SupplierGuideComingSoon = (props: Props) => {
  const { onBackClick } = props;
  const { t } = useTranslation();
  return (
    <S.Home>
      <S.Text>{t("Coming soon.")}</S.Text>
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
    </S.Home>
  );
};

export default SupplierGuideComingSoon;
