import React from "react";
import S from "./styles";

type Props = {
  src: string;
  position?: string;
  onClick: () => void;
};

const Thumbnail = (props: Props) => {
  const { src, position } = props;
  return (
    <S.Thumbnail onClick={props.onClick}>
      {position && <S.Position>{position}</S.Position>}
      <S.Image src={src} />
    </S.Thumbnail>
  );
};

export default Thumbnail;
