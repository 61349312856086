import React, { useState, useEffect } from "react";
import RemovalReasonType from "../removal-reason-type/RemovalReasonType";
import TyreSize from "../tyre-size/TyreSize";
import ListComponent, { Option } from "../list-component/ListComponent";
import { useTranslation } from "react-i18next";
import { RemovalReason } from "360";
import Camera from "../../../../../camera/Camera";
import { useApi, isSuccess, isNoContent } from "../../../../../../api/Api";
import Spinner from "../../../../../spinner/Spinner";
import GeneralErrors from "../../../../../general-errors/GeneralErrors";

type Props = {
  positionId: number;
  reference: string;
  onComplete: () => void;
  onFail: () => void;
  onBackClick: () => void;
};

const Tyre = (props: Props) => {
  const { positionId, reference, onComplete, onFail, onBackClick } = props;
  const [isRemovalReasonDamage, setIsRemovalReasonDamage] = useState<
    boolean | null
  >(null);
  const [removalReason, setRemovalReason] = useState<RemovalReason | null>(
    null
  );
  const [removalReasons, setRemovalReasons] = useState<RemovalReason[]>([]);
  const [tyreSize, setTyreSize] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const api = useApi();
  const { t } = useTranslation();

  const submitTyre = async (
    reference: string,
    positionId: number,
    removalReasonId: number,
    tyreSize: string,
    photos: File[]
  ) => {
    const form = new FormData();
    form.append("removalReasonId", removalReasonId.toString());
    form.append("tyreSize", tyreSize);

    for (var photo of photos) form.append("photos", photo);

    return api.postForm(
      `api/breakdowns/${reference}/work-required/${positionId}`,
      "1.0",
      form
    );
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<RemovalReason[]>(
          "api/removal-reasons",
          "1.0"
        );
        setLoading(false);

        if (isSuccess(response) && response.data.length > 0) {
          return setRemovalReasons(response.data);
        }
      } catch {}

      onFail();
    })();
  }, [onFail, api]);

  const handleRemovalReasonSelect = (o: Option) => {
    const removalReason = removalReasons.find(
      (rr) => rr.id === Number(o.value)
    );

    if (removalReason) {
      setRemovalReason(removalReason);
    }
  };

  const handleCameraComplete = (photos: File[]) => {
    complete(photos);
  };

  const complete = async (photos: File[]) => {
    if (removalReason === null || tyreSize === null)
      throw new Error(
        "attempted to submit tyre with no removal reason or tire size"
      );

    try {
      setLoading(true);
      const response = await submitTyre(
        reference,
        positionId,
        removalReason.id,
        tyreSize,
        photos
      );
      setLoading(false);

      if (isNoContent(response)) return onComplete();
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;
  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  if (isRemovalReasonDamage === null) {
    return (
      <RemovalReasonType
        onSelect={setIsRemovalReasonDamage}
        onBackClick={onBackClick}
      />
    );
  }

  if (removalReason === null) {
    const heading =
      isRemovalReasonDamage === true
        ? t("Specify damage")
        : t("Specify reason");
    const options = removalReasons
      .filter((rr) => rr.isDamage === isRemovalReasonDamage)
      .map((rr) => ({ value: rr.id.toString(), label: t(rr.description) }));

    return (
      <ListComponent
        heading={heading}
        options={options}
        onSelect={handleRemovalReasonSelect}
        onBackClick={() => setIsRemovalReasonDamage(null)}
      />
    );
  }

  if (tyreSize === null)
    return (
      <TyreSize
        reference={reference}
        positionId={positionId}
        onSelect={setTyreSize}
        isSkippable={false}
        onSkip={() => {}}
        onBackClick={() => setRemovalReason(null)}
      />
    );

  return (
    <Camera
      onComplete={handleCameraComplete}
      onBackClick={() => setTyreSize(null)}
    />
  );
};

export default Tyre;
