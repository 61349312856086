import React from "react";
import S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faTruck,
  faUser,
  faHome,
  faHourglassEnd,
  faWarehouse
} from "@fortawesome/pro-solid-svg-icons";
import Button from "../../../button/Button";
import JobLocationType from "../../../job-location-type/job-location-type-icon/JobLocationTypeIcon";
import { useTranslation } from "react-i18next";
import { JobDetail } from "360";
import JobStatusIcon from "../../../job-status/JobStatusIcon";

const convertLocationType = (locationTypeId: number) => {
  switch (locationTypeId) {
    case 1:
      return "At site/yard";
    case 2:
      return "Roadside";
    case 6:
      return "Servicing dealer";
    default:
      return "Other";
  }
};

type Props = {
  jobDetail: JobDetail;
  onClicked: (jobId: number) => void;
  loneWorkerStatus: boolean;
};

const AllocatedJob = (props: Props) => {
  const { jobDetail, onClicked, loneWorkerStatus } = props;
  const { t } = useTranslation();

  return (
    <S.JobListing loneWorkerStatus={loneWorkerStatus}>
      <S.JobDetailsContainer>
        <S.JobDetails>
          <S.DetailContainer>
            <S.IconContainer>
              <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
            </S.IconContainer>
            {jobDetail.vehicleRegistration}
          </S.DetailContainer>
          <S.DetailContainer>
            <S.IconContainer>
              <FontAwesomeIcon icon={faClipboardList}></FontAwesomeIcon>
            </S.IconContainer>
            {jobDetail.jobId}
          </S.DetailContainer>

          <S.DetailContainer>
            <S.IconContainer>
              <FontAwesomeIcon icon={faHourglassEnd}></FontAwesomeIcon>
            </S.IconContainer>
            {jobDetail.estimatedTimeOfArrival
              ? t("DisplayDate", {
                  date: jobDetail.estimatedTimeOfArrival
                })
              : "N/A"}
          </S.DetailContainer>

          <S.DetailContainer>
            <S.IconContainer>
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
            </S.IconContainer>
            {jobDetail.customer}
          </S.DetailContainer>

          <S.DetailContainer>
            <S.IconContainer>
              <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
            </S.IconContainer>
            {jobDetail.endUser}
          </S.DetailContainer>

          <S.DetailContainer>
            <S.IconContainer>
              <FontAwesomeIcon icon={faWarehouse}></FontAwesomeIcon>
            </S.IconContainer>
            {jobDetail.serviceProviderDepot}
          </S.DetailContainer>
        </S.JobDetails>
      </S.JobDetailsContainer>
      <S.JobFlagContainer>
        {jobDetail.jobStatus !== null && (
          <JobStatusIcon jobStatus={jobDetail.jobStatus}></JobStatusIcon>
        )}

        <JobLocationType
          locationType={convertLocationType(jobDetail.locationTypeId)}
          height={30}
          width={30}
        ></JobLocationType>
      </S.JobFlagContainer>
      <Button onClick={() => onClicked(jobDetail.jobId)}>{t("View")}</Button>
    </S.JobListing>
  );
};

export default AllocatedJob;
