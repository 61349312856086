import React, { useState, useEffect } from "react";
import S from "./styles";
import ReasonItem from "../../time-reason/ReasonItem";
import { useApi, isSuccess, isNoContent, isFailure } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import VR from "../../../utils/ValidationResult";
import Spinner from "../../spinner/Spinner";

type Props = {
  jobId: number;
  onCancelled: () => void;
  onReturn: () => void;
  onFail: (errors?: string[]) => void;
};

const JobCancelled = (props: Props) => {
  const { jobId, onCancelled, onReturn, onFail } = props;
  const [reasons, setReasons] = useState<ReasonItem[]>([]);
  const [reasonId, setReasonId] = useState<number | null>(null);
  const [vr, setVr] = useState<VR>(VR.empty);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<ReasonItem[]>(
          "api/allocation-cancellation-reasons",
          "1.0"
        );
        setLoading(false);

        if (isSuccess(response)) {
          if (response.data.length > 0) return setReasons(response.data);
        }
      } catch {}

      onFail();
    })();
  }, [onFail, api]);

  const handleReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(e.target.value);
    setReasonId(id);
  };

  const handleContinueClick = () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    cancel(reasonId!);
  };

  const cancel = async (reasonId: number) => {
    try {
      setLoading(true);
      const response = await api.post(
        `api/allocated-jobs/${jobId}/cancelled`,
        "1.0",
        { reasonId }
      );
      setLoading(false);

      if (isFailure(response)) {
        return onFail(response.errors);
      }

      if (isNoContent(response)) return onCancelled();
    } catch {}

    onFail();
  };

  const validate = (): VR => {
    const vr = VR.empty;

    if (!reasonId) vr.add("reason", t("Field is required."));

    return vr;
  };

  if (loading) return <Spinner />;

  return (
    <S.JobCancelled>
      <S.Title htmlFor="reason">{t("Reason for cancellation")}</S.Title>
      <S.Select
        id="reason"
        value={reasonId || ""}
        onChange={handleReasonChange}
        errors={vr.getErrors("reason")}
      >
        <option value="" disabled hidden></option>
        {reasons
          .sort((x, y) => {
            return x.id - y.id;
          })
          .map((c) => (
            <option key={c.id} value={c.id}>
              {t(c.description)}
            </option>
          ))}
      </S.Select>
      <S.Button onClick={handleContinueClick}>{t("Continue")}</S.Button>
      <S.Button onClick={onReturn}>{t("Return to job")}</S.Button>
    </S.JobCancelled>
  );
};

export default JobCancelled;
