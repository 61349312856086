import styled, { keyframes } from "styled-components";
import colours from "../../utils/colours";

const stretchDelay = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.4)
  }
  20% {
    transform: scaleY(1.0)
  }
`;

const First = styled.div``;
const Second = styled.div``;
const Third = styled.div``;
const Fourth = styled.div``;
const Fifth = styled.div``;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  text-align: center;

  & div {
    background-color: ${colours.lightOrange}
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 1px;

    animation: ${stretchDelay} 1.2s infinite ease-in-out;
  }

  & ${Second} {
    animation-delay: -1.1s;
  }

  & ${Third} {
    animation-delay: -1.0s;
  }

  & ${Fourth} {
    animation-delay: -0.9s;
  }

  & ${Fifth} {
    animation-delay: -0.8s;
  }
`;

export default { First, Second, Third, Fourth, Fifth, Spinner };
