import styled from "styled-components";
import _Select from "../../select/Select";
import _Button from "../../button/Button";

const Customer = styled.div`
  & > * {
    display: block;
    margin-bottom: 5px;
  }

  max-width: 16rem;
`;

const Select = styled(_Select)`
  width: 16rem;
`;

const Button = styled(_Button)`
  width: 16rem;
`;

export default { Customer, Select, Button };
