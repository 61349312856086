import React, { useState } from "react";
import S from "./styles";

type Props = {
  onChange: (value: Date) => void;
  value: Date | null;
  errors?: string[];
};

const padValue = (value: number) => {
  if (value < 10) return "0" + value.toString();
  return value;
};

const DateTimeInput = (props: Props) => {
  const setUpDate = () => {
    if (props.value === null) return "";
    return (
      props.value.getFullYear() +
      "-" +
      padValue(props.value.getMonth() + 1) +
      "-" +
      padValue(props.value.getDate())
    );
  };

  const setUpTime = () => {
    if (props.value === null) return "";

    return (
      padValue(props.value.getHours()) +
      ":" +
      padValue(props.value.getMinutes())
    );
  };

  const [date, setDate] = useState<string>(setUpDate());
  const [time, setTime] = useState<string>(setUpTime());

  const handleDateChange = (value: string) => {
    setDate(value);
    handleOnChange(value, time);
  };

  const handleTimeChange = (value: string) => {
    setTime(value);
    handleOnChange(date, value);
  };

  const handleOnChange = (date: string, time: string) => {
    var datetime = date + " " + time;

    if (date === "" || time === "") {
      datetime = "";
    }

    props.onChange(new Date(datetime));
  };

  var error: string[] = props.errors !== undefined ? ["", ""] : [];

  return (
    <S.DateTime>
      <S.InputWrapper>
        <S.Date
          type={"date"}
          onChange={e => {
            handleDateChange(e.target.value);
          }}
          value={date}
          errors={error}
        ></S.Date>

        <S.Time
          type={"time"}
          onChange={e => {
            handleTimeChange(e.target.value);
          }}
          value={time}
          errors={error}
        ></S.Time>
      </S.InputWrapper>
      {error.length > 0 && <S.Error>{props.errors![0]}</S.Error>}
    </S.DateTime>
  );
};

export default DateTimeInput;
