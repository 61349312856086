import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi, isSuccess, isNoContent } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import ImageGallery from "../../image-gallery/ImageGallery";
import S from "./styles";
import Camera from "../../camera/Camera";

type imageDetails = {
  id: number;
  path: string;
  thumbnailPath: string;
};

type imageResponse = {
  images: imageDetails[];
  key: string;
};

type Props = {
  jobId: number;
  positionId: number;
  userPhotoCount: number;
  onContinue: (photosAddedCount: number) => void;
  onReturn: () => void;
  onFail: (errors?: string[]) => void;
};

const Gallery = (props: Props) => {
  const {
    onContinue,
    onReturn,
    onFail,
    jobId,
    positionId,
    userPhotoCount,
  } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [imageSources, setImageSource] = useState<string[]>([]);
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const [otpKey, setOtpKey] = useState<string | null>(null);
  const [currentPhotoCount, setCurrentPhotoCount] = useState<number>(
    userPhotoCount
  );
  var maxPhotoCount = 4 - currentPhotoCount;

  const api = useApi();

  const submitPhotos = async (
    photos: File[],
    jobId: number,
    positionId: number
  ) => {
    const form = new FormData();
    form.append("positionId", positionId.toString());

    for (var photo of photos) form.append("photos", photo);

    return api.postForm(`api/allocated-jobs/${jobId}/photos`, "1.0", form);
  };

  const onImagesSelected = async (files: File[]) => {
    if (files.length === 0) {
      setShowCamera(true);
      setLoading(false);
      return onContinue(files.length);
    }

    try {
      setLoading(true);
      const response = await submitPhotos(files, jobId, positionId);
      setCurrentPhotoCount(currentPhotoCount + files.length);

      if (isNoContent(response)) {
        setShowCamera(true);
        setLoading(false);
        return onContinue(files.length);
      }
    } catch {}

    onFail();
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<imageResponse>(
          `api/allocated-jobs/${jobId}/photos/position/${positionId}`,
          "1.0"
        );
        setLoading(false);

        if (isSuccess(response)) {
          setOtpKey(response.data.key);
          return setImageSource(
            response.data.images.map((d) => d.id.toString())
          );
        }
      } catch {}

      onFail();
    })();
  }, [api, jobId, positionId, onFail]);

  if (loading) return <Spinner />;

  const images = imageSources.map((item) => ({
    src: `api/jobs/${jobId}/photos/${item}?v=1&key=${otpKey}`,
  }));

  if (showCamera)
    return (
      <Camera
        onComplete={onImagesSelected}
        photoLimit={maxPhotoCount}
        photoCount={imageSources.length}
        onBackClick={() => setShowCamera(false)}
      />
    );

  return (
    <div>
      {imageSources.length !== 0 ? (
        <ImageGallery images={images}></ImageGallery>
      ) : (
        <S.NoPhotosFound>{t("No Photos Uploaded")}</S.NoPhotosFound>
      )}

      <S.ButtonContainer>
        {imageSources.length < 4 && (
          <S.Button
            onClick={() => {
              setShowCamera(true);
            }}
          >
            {t("Add Photo")}
          </S.Button>
        )}

        <S.Button onClick={onReturn}>{t("Back")}</S.Button>
      </S.ButtonContainer>
    </div>
  );
};

export default Gallery;
