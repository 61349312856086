import React from "react";
import S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faHandHoldingBox,
  faFlagCheckered,
  faLocationArrow,
  faThumbsUp
} from "@fortawesome/pro-solid-svg-icons";

type Props = {
  jobStatus: string;
};

const GetStatusIcon = (status: string) => {
  switch (status) {
    case "Allocated":
      return faHandHoldingBox;
    case "EnRoute":
      return faLocationArrow;
    case "OnScene":
      return faFlagCheckered;
    case "Completed":
      return faThumbsUp;
    default:
      return faQuestion;
  }
};

const JobStatusIcon = (props: Props) => {
  return (
    <S.JobStatusIcon width={30} height={30}>
      <FontAwesomeIcon icon={GetStatusIcon(props.jobStatus)}></FontAwesomeIcon>
    </S.JobStatusIcon>
  );
};

export default JobStatusIcon;
