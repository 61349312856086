import React, { useEffect, useState } from "react";
import Configuration from "../configuration/Configuration";
import { Configuration as Config } from "360";
import { useApi, isSuccess } from "../../../api/Api";
import Job from "../../job-details/Job";
import ConfigurationChooser from "../../breakdown/work-required/configuration-chooser/ConfigurationChooser";
import Spinner from "../../spinner/Spinner";

type Props = {
  onContinue: () => void;
  onFail: (errors?: string[]) => void;
  jobId: number;
  job: Job;
};

const Camera = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showConfigChooser, setShowConfigChooser] = useState<boolean>(false);
  const [selectedConfig, setSelectedConfig] = useState<Config>();
  const { onFail, onContinue, jobId, job } = props;
  const api = useApi();

  const onChangeConfig = () => {
    setShowConfigChooser(true);
    setSelectedConfig(undefined);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response;
        //If the job already has a config set go get that config,
        //else get a config based on other details present in the job.
        if (job.configId !== null) {
          response = await api.get<Config>(
            `api/configurations/${job.configId}`,
            "1.1"
          );
        } else {
          response = await api.get<Config>(
            `api/allocated-jobs/${jobId}/default-config`,
            "1.1"
          );
        }

        if (isSuccess(response)) {
          if (response.data !== null) {
            setLoading(false);
            return setSelectedConfig(response.data);
          }

          setLoading(false);
          return setShowConfigChooser(true);
        }
      } catch {}

      onFail();
    })();
  }, [api, jobId, job.configId, onFail]);

  if (loading) return <Spinner></Spinner>;

  if (selectedConfig !== undefined) {
    return (
      <Configuration
        configuration={selectedConfig}
        job={job}
        onContinue={onContinue}
        onFail={onFail}
        onChangeConfig={onChangeConfig}
      ></Configuration>
    );
  }

  if (showConfigChooser) {
    return (
      <div>
        <ConfigurationChooser onChoose={setSelectedConfig} onFail={onFail} />
      </div>
    );
  }

  return <Spinner></Spinner>;
};

export default Camera;
