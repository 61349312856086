import React from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";
import {
  faCheckCircle,
  faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons";
import { SubmissionResult } from "360";

type Props = {
  result: SubmissionResult;
  onContinue: () => void;
};

const Confirmation = (props: Props) => {
  const { result, onContinue } = props;
  const { jobId, fullySubmitted } = result;
  const { t } = useTranslation();

  const additionalText = fullySubmitted
    ? t("has been logged and we will advise your ETA shortly.")
    : t(
        "has been partially logged. We may be in contact shortly to confirm details."
      );
  const icon = fullySubmitted ? (
    <S.GreenIcon icon={faCheckCircle} />
  ) : (
    <S.YellowIcon icon={faExclamationTriangle} />
  );

  return (
    <S.Confirmation>
      {icon}
      <S.Header>{t("TSU Case Number")}</S.Header>
      <S.JobNumber>{jobId}</S.JobNumber>
      <S.Text>{additionalText}</S.Text>
      <S.Text>{t("Please check your notifications for updates.")}</S.Text>
      <S.Button onClick={onContinue}>{t("Home")}</S.Button>
    </S.Confirmation>
  );
};

export default Confirmation;
