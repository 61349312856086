import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";
import Creatable from "react-select/creatable";
import { useApi, isSuccess } from "../../../api/Api";
import colours from "../../../utils/colours";
import VR from "../../../utils/ValidationResult";
import Spinner from "../../spinner/Spinner";
import { WheelSecurityModel } from "360";

type Props = {
  onContinue: (wheelSecurity: WheelSecurityModel) => void;
  onReturn: () => void;
  onFail: (errors?: string[]) => void;
};

type NotRetorquedReason = {
  id: number | null;
  reason: string;
};

const getNewOptionData = (
  inputValue: string,
  _: React.ReactNode
): NotRetorquedReason => ({ reason: inputValue, id: null });

const isNotRetorquedReason = (
  o: NotRetorquedReason | readonly NotRetorquedReason[] | undefined | null
): o is NotRetorquedReason =>
  o !== undefined && o !== null && !Array.isArray(o);

const getOptionValue = (option: NotRetorquedReason) => option.reason;

const WheelSecurity = (props: Props) => {
  const { onContinue, onReturn, onFail } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [notRetorquedReasons, setNotRetorquedReasons] = useState<
    NotRetorquedReason[]
  >([]);
  const [wheelsRemoved, setWheelsRemoved] = useState<boolean | null>(null);
  const [retorqued, setRetorqued] = useState<boolean | null>(null);
  const [reasonNotRetorqued, setNotRetorquedReason] =
    useState<NotRetorquedReason | null>(null);
  const [vr, setVr] = useState<VR>(VR.empty);
  const [wrenchNumber, setWrenchNumber] = useState<string | null>(null);
  const [torqueSetting, setTorqueSetting] = useState<string | null>(null);
  const [tagNumber, setTagNumber] = useState<string | null>(null);

  const { t } = useTranslation();
  const api = useApi();

  const getOptionLabel = (option: NotRetorquedReason) => t(option.reason);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api.get<NotRetorquedReason[]>(
        "api/not-retorqued-reasons",
        "1.0"
      );
      setLoading(false);

      if (isSuccess(response)) return setNotRetorquedReasons(response.data);

      onFail();
    })();
  }, [onFail, api]);

  const handleContinue = async () => {
    const vr = validate();
    setVr(vr);
    if (!vr.ok) return;

    setLoading(true);

    const model: WheelSecurityModel = {
      wheelsRemoved: wheelsRemoved!,
      wrenchNumber,
      torqueSetting,
      tagNumber,
      retorqued: retorqued!,
      reasonNotRetorqued:
        reasonNotRetorqued && reasonNotRetorqued.id === null
          ? reasonNotRetorqued.reason
          : null,
      reasonNotRetorquedId: reasonNotRetorqued ? reasonNotRetorqued.id : null,
    };
    onContinue(model);
    setLoading(false);
  };

  const handleChange = (
    option:
      | NotRetorquedReason
      | readonly NotRetorquedReason[]
      | undefined
      | null
  ) => {
    if (isNotRetorquedReason(option) || option === null) {
      setNotRetorquedReason(option);
      setTagNumber(null);
    }
  };

  const handleCreate = (notRetorquedReason: string) => {
    setNotRetorquedReason({ reason: notRetorquedReason, id: null });
  };

  const onWheelRemovedChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Yes") setWheelsRemoved(true);
    else if (e.target.value === "No") {
      setWheelsRemoved(false);
      setTorqueSetting(null);
      setWrenchNumber(null);
      setRetorqued(null);
      setNotRetorquedReason(null);
      setTagNumber(null);
    } else {
      setWheelsRemoved(null);
      setTorqueSetting(null);
      setWrenchNumber(null);
      setRetorqued(null);
      setNotRetorquedReason(null);
      setTagNumber(null);
    }
  };

  const onRetorqueChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Yes") {
      setRetorqued(true);
      setNotRetorquedReason(null);
      setTagNumber(null);
    } else if (e.target.value === "No") setRetorqued(false);
    else {
      setRetorqued(null);
      setNotRetorquedReason(null);
      setTagNumber(null);
    }
  };

  const onTorqueSettingChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTorqueSetting(e.target.value);
  };

  const onWrenchNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWrenchNumber(e.target.value);
  };

  const onTagNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagNumber(e.target.value);
  };

  const validate = () => {
    const vr = VR.empty;

    if (wheelsRemoved === null) {
      vr.add("wheelRemoved", t("Field is required."));
    }

    if (wheelsRemoved === true && !torqueSetting) {
      vr.add("torqueSetting", t("Field is required."));
    }

    if (wheelsRemoved === true && !wrenchNumber) {
      vr.add("wrenchNumber", t("Field is required."));
    }

    if (wheelsRemoved === true && retorqued == null) {
      vr.add("retorque", t("Field is required."));
    }

    if (wheelsRemoved === true && !retorqued && !reasonNotRetorqued?.reason) {
      vr.add("NotCompleteReason", t("Field is required"));
    }

    if (
      wheelsRemoved === true &&
      !retorqued &&
      reasonNotRetorqued?.id === 1 &&
      !tagNumber
    ) {
      vr.add("torqueTag", t("Field is required."));
    }

    return vr;
  };

  var errors = vr.getErrors("NotCompleteReason") || [];

  if (loading) return <Spinner></Spinner>;

  return (
    <S.WheelSecurity>
      <S.Title>{t("Wheel Security")}</S.Title>

      <label>{t("Wheel(s) Removed?")}</label>
      <S.Select
        onChange={onWheelRemovedChanged}
        errors={vr.getErrors("wheelRemoved")}
      >
        <option>{t("Please Select ...")}</option>
        <option value="Yes">{t("Yes")}</option>
        <option value="No">{t("No")}</option>
      </S.Select>

      {wheelsRemoved === true && (
        <React.Fragment>
          <label>{t("Torque Setting")}</label>
          <S.Input
            onChange={onTorqueSettingChanged}
            errors={vr.getErrors("torqueSetting")}
          ></S.Input>

          <label>{t("Wrench Number")}</label>
          <S.Input
            onChange={onWrenchNumberChange}
            errors={vr.getErrors("wrenchNumber")}
          ></S.Input>

          <label>{t("Retorque Carried Out?")}</label>
          <S.Select
            onChange={onRetorqueChanged}
            errors={vr.getErrors("retorque")}
          >
            <option>{t("Please Select ...")}</option>
            <option value="Yes">{t("Yes")}</option>
            <option value="No">{t("No")}</option>
          </S.Select>
        </React.Fragment>
      )}

      {retorqued === false && wheelsRemoved === true && (
        <React.Fragment>
          <label>{t("Info Left With Customer")}</label>
          <Creatable
            options={notRetorquedReasons}
            placeholder={t("Search...")}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={handleChange}
            onCreateOption={handleCreate}
            getNewOptionData={getNewOptionData}
            isClearable={true}
            value={reasonNotRetorqued}
            errors={vr.getErrors("infoWithCustomer")}
            styles={{
              control: (provided, _) => ({
                ...provided,
                width: "16rem",
                borderColor:
                  errors.length > 0 ? colours.lightRed : colours.black,
                background:
                  errors.length > 0 ? colours.extraLightRed : colours.white,
              }),
              valueContainer: (provided, _) => ({
                ...provided,
                padding: "2px 5px",
              }),
            }}
          />

          {reasonNotRetorqued?.id === 1 && (
            <React.Fragment>
              <label>{t("Tag Number")}</label>
              <S.Input
                onChange={onTagNumberChange}
                errors={vr.getErrors("torqueTag")}
              ></S.Input>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <S.Button onClick={handleContinue}>{t("Continue")}</S.Button>
      <S.Button onClick={onReturn}>{t("Return to job")}</S.Button>
    </S.WheelSecurity>
  );
};

export default WheelSecurity;
