import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import colours from "../../../utils/colours";
import _Button from "../../button/Button";

const Confirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GreenIcon = styled(FontAwesomeIcon)`
  font-size: 60px;
  margin-bottom: 10px;
  color: ${colours.lightGreen};
`;

const YellowIcon = styled(FontAwesomeIcon)`
  font-size: 60px;
  margin-bottom: 10px;
  color: ${colours.yellow};
`;

const JobNumber = styled.p`
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Header = styled.p`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Text = styled.p`
  margin-bottom: 5px;
  text-align: center;
`;

const Button = styled(_Button)`
  width: 16rem;
`;

export default {
  Header,
  JobNumber,
  Confirmation,
  GreenIcon,
  YellowIcon,
  Text,
  Button
};
