import styled from "styled-components";

const JobCategory = styled.div`
  width: 100%;
  margin-top: 5px;
`;

const JobCategoryIcon = styled.div`
  text-align: center;
  font-size: 20px;
  float: right;
  margin-right: 10px;
`;

const JobCategoryContent = styled.div`
  margin: 5px;
`;

const TitleContent = styled.div`
  padding: 5px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
`;

const Title = styled.span`
  font-weight: bold;
  vertical-align: center;
`;

const EditButton = styled.button`
  float: right;
`;

export default {
  JobCategory,
  Title,
  JobCategoryIcon,
  JobCategoryContent,
  TitleContent,
  EditButton
};
