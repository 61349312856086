import React from "react";
import S from "./styles";
import JobCategory from "../job-category/JobCategory";
import JobSubCategory from "../job-sub-category/JobSubCategory";
import { useTranslation } from "react-i18next";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons";
import { TimeSlot } from "360";

type DateProps = {
  date: Date;
  availability: TimeSlot[];
};

const Date = (props: DateProps) => {
  const { date, availability } = props;
  const { t } = useTranslation();

  const sub = availability
    .sort((a, b) => a.from.getTime() - b.from.getTime())
    .map((slot, i) => {
      const crossesMidnight = slot.from.getDate() !== slot.to.getDate();
      const start = t("AVAILABILITY", { time: slot.from });
      const endFormat = crossesMidnight
        ? "AVAILABILITY_WITH_DATE"
        : "AVAILABILITY";
      const end = t(endFormat, { time: slot.to });
      return <S.DetailContent key={i}>{`${start} - ${end}`}</S.DetailContent>;
    });
  return (
    <S.DetailContainer>
      <S.Detail>{t("AVAILABILITY_DATE", { date })}</S.Detail>
      {sub}
    </S.DetailContainer>
  );
};

type Props = {
  availability: TimeSlot[];
  availabilityEditClicked?: () => void;
};

const Availability = (props: Props) => {
  const { availabilityEditClicked, availability } = props;
  const { t } = useTranslation();

  const groupedAvailability = availability.reduce(
    (acc: any, curr: TimeSlot) => {
      const key = curr.from.getDate();

      if (acc[key] === undefined) acc[key] = [];

      acc[key].push(curr);
      return acc;
    },
    {}
  );

  const children = Object.keys(groupedAvailability)
    .map((key) => groupedAvailability[key])
    .sort((a, b) => a[0] - b[0])
    .map((slots, i) => (
      <Date availability={slots} date={slots[0].from} key={i} />
    ));
  return (
    <JobCategory icon={faCalendarAlt} title={t("Availability")}>
      <JobSubCategory
        onEditClicked={
          children.length > 0 ? availabilityEditClicked : undefined
        }
      >
        {children.length === 0 ? (
          <S.DetailContainer>
            <S.Detail>{t("ASAP")}</S.Detail>
          </S.DetailContainer>
        ) : (
          children
        )}
      </JobSubCategory>
    </JobCategory>
  );
};

export default Availability;
