import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FieldErrors from "../../field-errors/FieldErrors";
import AsyncCreatable from "react-select/async-creatable";
import { ValueType, OptionsType } from "react-select";
import VR from "../../../utils/ValidationResult";
import colours from "../../../utils/colours";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import S from "./styles";
import debounce from "debounce-promise";
import { Api, useApi, Response, mapSuccess, isSuccess } from "../../../api/Api";
import GeneralErrors from "../../general-errors/GeneralErrors";
import Spinner from "../../spinner/Spinner";
import {
  BreakdownVehicle,
  ExistingVehicle,
  Vehicle as VM,
  Customer,
} from "360";

type Props = {
  reference: string;
  onContinue: (vehicle: BreakdownVehicle, customer: Customer | null) => void;
  onFail: () => void;
  onCancel: () => void;
  initialValue: BreakdownVehicle | null;
};

const getNewOptionData = (inputValue: string, _: React.ReactNode): VM => {
  return { existing: false, registration: inputValue };
};

const getOptionLabel = (option: VM): string => option.registration;

const getOptionValue = (option: VM): string => option.registration;

const isValidNewOption = (
  inputValue: string,
  _: ValueType<VM>,
  selectOptions: OptionsType<VM>
) =>
  selectOptions
    .map((o) => o.registration.toLowerCase())
    .indexOf(inputValue.toLowerCase()) === -1;

const isVehicle = (o: VM | readonly VM[] | undefined | null): o is VM => {
  return o !== undefined && o !== null && !Array.isArray(o);
};

const getVehicles = async (
  api: Api,
  registration: string
): Promise<Response<ExistingVehicle[]>> => {
  const response: Response<any[]> = await api.get(
    "api/vehicles/search",
    "1.0",
    {
      registration,
    }
  );

  return mapSuccess(response, (data): ExistingVehicle[] =>
    data.map((v) => ({
      ...v,
      existing: true,
      status: v.status === "Suspended" ? "SUSPENDED" : "ACTIVE",
    }))
  );
};

const submitVehicle = async (
  api: Api,
  reference: string,
  vehicle: string,
  attachedVehicle: string
): Promise<Response<Customer | null>> =>
  api.post(`api/breakdowns/${reference}/vehicle`, "1.1", {
    vehicle,
    attachedVehicle,
  });

const Vehicle = (props: Props) => {
  const { onContinue, onCancel, onFail, initialValue } = props;
  const [vehicle, setVehicle] = useState<VM | null>(
    initialValue?.vehicle || null
  );
  const [
    attachedVehicleRegistration,
    setAttachedVehicleRegistration,
  ] = useState<string>(initialValue?.attachedVehicleRegistration || "");
  const [vr, setVr] = useState<VR>(VR.empty);
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useApi();

  const displaySuspendedWarning =
    vehicle && vehicle.existing && vehicle.status === "SUSPENDED";

  const loadOptions = async (
    input: string,
    callback: (results: ExistingVehicle[]) => void
  ) => {
    if (input.length < 3) return callback([]);
    if (input.length > 20) return callback([]);

    try {
      const response = await getVehicles(api, input);

      if (isSuccess(response)) return callback(response.data);
    } catch {}

    // if the vehicle search call fails, we will allow the user to continue anyway
    return callback([]);
  };

  const debounced = debounce(loadOptions, 2.5e2);

  const handleContinue = async () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    if (!vehicle) throw new Error("no vehicle provided.");

    try {
      setLoading(true);
      const response = await submitVehicle(
        api,
        props.reference,
        vehicle.registration,
        attachedVehicleRegistration
      );
      setLoading(false);

      if (isSuccess(response)) {
        return onContinue(
          { vehicle, attachedVehicleRegistration },
          response.data
        );
      }
    } catch {}

    onFail();
  };

  const handleChange = (option: VM | readonly VM[] | undefined | null) => {
    if (isVehicle(option) || option === null) {
      setVehicle(option);
    }
  };

  const handleCreate = (registration: string) => {
    setVehicle({ existing: false, registration });
  };

  const handleAttachedVehicleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setAttachedVehicleRegistration(e.target.value);

  const validate = () => {
    const vr = VR.empty;

    if (!vehicle) {
      vr.add("vehicle", t("Field is required."));
    }

    if (vehicle && !vehicle.existing && vehicle.registration.length > 20) {
      vr.add(
        "vehicle",
        t("Maximum length is {{len}} characters.", { len: 20 })
      );
    }

    if (attachedVehicleRegistration.length > 20)
      vr.add(
        "attachedVehicle",
        t("Maximum length is {{len}} characters.", { len: 20 })
      );

    return vr;
  };

  var vehicleErrors = vr.getErrors("vehicle") || [];

  if (loading) return <Spinner />;

  return (
    <S.Vehicle>
      {errors.length > 0 && (
        <GeneralErrors errors={errors} onClose={() => setErrors([])} />
      )}
      <label>{t("Vehicle / unit number")}</label>
      <AsyncCreatable
        cacheOptions
        loadOptions={debounced}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={handleChange}
        onCreateOption={handleCreate}
        getNewOptionData={getNewOptionData}
        isClearable={true}
        value={vehicle}
        placeholder={t("Search...")}
        isValidNewOption={isValidNewOption}
        styles={{
          control: (provided, _) => ({
            ...provided,
            width: "16rem",
            borderColor:
              vehicleErrors.length > 0 ? colours.lightRed : colours.black,
            background:
              vehicleErrors.length > 0 ? colours.extraLightRed : colours.white,
          }),
          valueContainer: (provided, _) => ({
            ...provided,
            padding: "2px 5px",
          }),
        }}
      />
      {!vr.ok && <FieldErrors errors={vehicleErrors} />}
      {displaySuspendedWarning && <SuspendedWarning />}
      <label>{t("Attached vehicle")}</label>
      <S.Input
        value={attachedVehicleRegistration}
        onChange={handleAttachedVehicleChange}
        errors={vr.getErrors("attachedVehicle")}
      />
      <S.Button onClick={handleContinue}>{t("Continue")}</S.Button>
      <S.Button onClick={onCancel}>{t("Save for later or cancel")}</S.Button>
    </S.Vehicle>
  );
};

const SuspendedWarning = () => {
  const { t } = useTranslation();

  return (
    <S.Warning>
      <S.WarningIcon icon={faExclamationTriangle} />
      <span>
        {t(
          "This vehicle is suspended. By continuing, you accept any costs associated with this work."
        )}
      </span>
    </S.Warning>
  );
};

export default Vehicle;
