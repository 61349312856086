import styled from "styled-components";
import colours from "../../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colours.grey3};
  width: 130px;
  height: 120px;
  margin: 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${colours.grey2};
  }
`;

const Text = styled.p`
  padding-top: 10px;
  text-align: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 60px;
`;

export default { Button, Text, Icon };
