import React from "react";
import Spinner from "../../../../spinner/Spinner";
import { useTranslation } from "react-i18next";
import S from "./styles";

const GettingLocation = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <Spinner />
      <S.LoadingText>{t("Getting your current location...")}</S.LoadingText>
    </S.Container>
  );
};

export default GettingLocation;
