import React from "react";
import { Husbandry } from "./models";
import FailureDescription from "./FailureDescription";
import PositionContainer from "./PositionContainer";

type Props = {
  unconfirmedHusbandry: Husbandry;
  onComplete: (confirmedHusbandry: Husbandry) => void;
};

const HusbandryWorkConfirmation = (props: Props) => {
  const { unconfirmedHusbandry, onComplete } = props;

  const handleFailureDescriptionComplete = (failureDescription: string) => {
    return onComplete({
      id: unconfirmedHusbandry.id,
      position: unconfirmedHusbandry.position,
      failureDescription,
    });
  };

  return (
    <PositionContainer position={unconfirmedHusbandry.position}>
      <FailureDescription onComplete={handleFailureDescriptionComplete} />
    </PositionContainer>
  );
};

export default HusbandryWorkConfirmation;
