import React from "react";
import { Container, WarningIcon, Button } from "./styles";
import { useTranslation } from "react-i18next";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useApi, Response, Api } from "../../../api/Api";

interface Props {
  onComplete: () => void;
  onReturnToBreakdown: () => void;
  reference: string;
  canSave: boolean;
}

const cancelBreakdown = async (
  api: Api,
  reference: string
): Promise<Response<void>> => api.delete(`api/breakdowns/${reference}`, "1.0");

const SaveOrCancel = (props: Props) => {
  const { canSave, onComplete, onReturnToBreakdown, reference } = props;
  const { t } = useTranslation();
  const api = useApi();

  const handleCancel = async () => {
    // if we fail to cancel the breakdown at this stage we can allow the user to continue
    // because the breakdown will be cleaned up later.
    try {
      await cancelBreakdown(api, reference);
    } catch {}

    return onComplete();
  };

  return (
    <Container>
      <WarningIcon icon={faExclamationTriangle} />
      <span>
        {t(
          "If you cancel the breakdown, all progress will be lost and the breakdown will not be received by our agents."
        )}
      </span>
      {canSave && (
        <span>
          {t(
            "If you save the breakdown for later, you will have 24 hours to provide the remaining details of the breakdown and submit it to our agents."
          )}
        </span>
      )}
      <Button onClick={handleCancel}>{t("Cancel breakdown")}</Button>
      {canSave && (
        <Button onClick={onComplete}>{t("Save breakdown for later")}</Button>
      )}
      <Button onClick={onReturnToBreakdown}>{t("Return to breakdown")}</Button>
    </Container>
  );
};

export default SaveOrCancel;
