import styled from "styled-components";

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export default {
  DetailContainer,
};
