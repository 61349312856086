import React, { useState } from "react";
import S from "./styles";
import Input from "../input/Input";
import VR from "../../utils/ValidationResult";
import { useTranslation } from "react-i18next";
import JobResult from "./job-result/JobResult";
import Button from "../button/Button";
import Spinner from "../spinner/Spinner";
import GeneralErrors from "../general-errors/GeneralErrors";
import { useApi, mapSuccess, Response, isSuccess } from "../../api/Api";
import { JobDetails as JD, JobLocationType, JobStatus } from "360";
import JobDetails from "../job-details/JobDetails";

type SR = {
  id: number;
  registration: string;
  locationType: JobLocationType | null;
  jobStatus: JobStatus | null;
  date: string;
};

const SearchJobs = () => {
  const [vr, setVr] = useState<VR>(VR.empty);
  const { t } = useTranslation();
  const [registration, setRegistration] = useState<string>("");
  const [jobId, setJobId] = useState<number | null>(null);
  const [jobs, setJobs] = useState<JD[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [viewingJobId, setViewingJobId] = useState<number | null>(null);
  const api = useApi();

  const validate = (): VR => {
    const result = VR.empty;

    if (registration && registration.length < 3)
      result.add(
        "registration",
        t("Minimum length is {{len}} characters.", { len: 3 })
      );

    if (registration && registration.length > 20)
      result.add(
        "registration",
        t("Maximum length is {{len}} characters.", { len: 20 })
      );

    if (!registration && !jobId) {
      result.add("registration", t("You must complete one field."));
      result.add("jobId", t("You must complete one field."));
    }

    return result;
  };

  const searchById = async (id: number): Promise<Response<JD[]>> => {
    var response: Response<SR[]> = await api.get("api/jobs/search", "1.0", {
      jobId: id,
    });

    return mapSuccess(response, (x) =>
      x.map((f) => ({
        id: f.id,
        registration: f.registration,
        locationType: f.locationType,
        jobStatus: f.jobStatus,
        date: new Date(f.date),
      }))
    );
  };

  const searchByRegistration = async (
    registration: string
  ): Promise<Response<JD[]>> => {
    var response: Response<SR[]> = await api.get("api/jobs/search", "1.0", {
      registration,
    });

    return mapSuccess(response, (x) =>
      x.map((f) => ({
        id: f.id,
        registration: f.registration,
        locationType: f.locationType,
        jobStatus: f.jobStatus,
        date: new Date(f.date),
      }))
    );
  };

  const handleSearchClick = async () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    setLoading(true);
    try {
      const response = await (jobId !== null
        ? searchById(jobId)
        : searchByRegistration(registration));
      setLoading(false);

      if (isSuccess(response)) return setJobs(response.data);
    } catch {}

    setErrors([
      t(
        "Failed to load required data. Please reload 360\u{B0}Smart and try again."
      ),
    ]);
  };

  const handleRegistrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistration(e.target.value);
    setJobId(null);
  };

  const handleJobIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") return setJobId(null);

    const parsed = parseInt(e.target.value);

    if (!isNaN(parsed)) {
      setJobId(parsed);
      setRegistration("");
    }
  };

  if (loading) return <Spinner />;
  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  if (viewingJobId)
    return (
      <JobDetails
        jobId={viewingJobId}
        onBackClick={() => setViewingJobId(null)}
      />
    );

  return (
    <S.SearchContent>
      <S.Title>{t("Job Search")}</S.Title>
      <S.FieldContainer>
        <S.SearchFields>
          <div>
            <S.SearchLabel>{t("Vehicle / unit number")}</S.SearchLabel>
            <Input
              value={registration}
              onChange={handleRegistrationChange}
              errors={vr.getErrors("registration")}
            ></Input>
          </div>
          <div>
            <S.SearchLabel>{t("TSU Case Number")}</S.SearchLabel>
            <Input
              value={(jobId && jobId.toString()) || ""}
              onChange={handleJobIdChange}
              errors={vr.getErrors("jobId")}
            ></Input>
          </div>
          <S.Search>
            <Button onClick={handleSearchClick}>{t("Search")}</Button>
          </S.Search>
        </S.SearchFields>
      </S.FieldContainer>
      {jobs !== null && (
        <div>{t("{{length}} jobs found.", { length: jobs.length })}</div>
      )}
      {jobs !== null && (
        <S.SearchResults>
          {jobs.map((job) => (
            <JobResult
              key={job.id}
              registration={job.registration}
              id={job.id}
              locationType={job.locationType}
              date={job.date}
              onViewJob={setViewingJobId}
              jobStatus={job.jobStatus}
            ></JobResult>
          ))}
        </S.SearchResults>
      )}
    </S.SearchContent>
  );
};

export default SearchJobs;
