import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as M from "360";
import { useApi, isSuccess } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import GeneralErrors from "../../general-errors/GeneralErrors";
import Documents from "./Documents";
import S from "./styles";

type DocumentsResponse = {
  key: string;
  documents: M.Document[];
};

const getCustomerDocumentUrl = (id: string, key: string) =>
  `api/documents/customer/${id}?v=1&key=${key}`;

type Props = {
  customerId: number;
  onBackClick: () => void;
};

const CustomerDocuments = ({ customerId, onBackClick }: Props) => {
  const [documents, setDocuments] = useState<DocumentsResponse | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        var response = await api.get<DocumentsResponse>(
          `api/documents/customer?customerId=${customerId}`,
          "1.0"
        );

        if (isSuccess(response)) {
          setDocuments(response.data);
          return;
        }
      } catch {}

      setErrors([
        t(
          "Failed to load required data. Please reload 360\u{B0}Smart and try again."
        ),
      ]);
    })();
  }, [t, customerId, api]);

  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={onBackClick} />;

  if (documents === null) return <Spinner />;

  const documentsWithUrls = documents.documents.map((d) => ({
    ...d,
    url: getCustomerDocumentUrl(d.id, documents.key),
  }));

  return (
    <div>
      {documentsWithUrls.length > 0 ? (
        <Documents documents={documentsWithUrls} />
      ) : (
        <S.NoDocumentsFound>No documents found</S.NoDocumentsFound>
      )}
      <S.Button onClick={onBackClick}>{t("Return to job")}</S.Button>
    </div>
  );
};

export default CustomerDocuments;
