import React from "react";
import S from "./styles";
import JobLocationType from "../../job-location-type/job-location-type-icon/JobLocationTypeIcon";
import Button from "../../button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faClipboardList,
  faCalendarAlt
} from "@fortawesome/pro-solid-svg-icons";
import { JobLocationType as LocationType, JobStatus } from "360";
import { useTranslation } from "react-i18next";
import JobStatusIcon from "../../job-status/JobStatusIcon";

type Props = {
  registration: string;
  id: number;
  locationType: LocationType | null;
  jobStatus: JobStatus | null;
  date: Date;
  onViewJob: (id: number) => void;
};

const JobResult = (props: Props) => {
  const { registration, id, locationType, date, onViewJob } = props;
  const { t } = useTranslation();

  return (
    <S.JobListing>
      <S.JobDetails>
        <S.DetailContainer>
          <S.IconContainer>
            <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
          </S.IconContainer>
          {registration}
        </S.DetailContainer>
        <S.DetailContainer>
          <S.IconContainer>
            <FontAwesomeIcon icon={faClipboardList}></FontAwesomeIcon>
          </S.IconContainer>
          {id}
        </S.DetailContainer>
        <S.DetailContainer>
          <S.IconContainer>
            <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
          </S.IconContainer>
          {t("DisplayDate", { date: date })}
        </S.DetailContainer>
      </S.JobDetails>

      <S.JobFlagContainer>
        {props.jobStatus !== null && (
          <JobStatusIcon jobStatus={props.jobStatus}></JobStatusIcon>
        )}
        <JobLocationType
          locationType={locationType}
          height={30}
          width={30}
        ></JobLocationType>
      </S.JobFlagContainer>
      <Button onClick={() => onViewJob(id)}>{t("View")}</Button>
    </S.JobListing>
  );
};

export default JobResult;
