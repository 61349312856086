import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources";
import { format as fnsFormat } from "date-fns";
import { enGB, enUS, frCA, es } from "date-fns/locale";

var locale = enGB;

const setLocale = (countryId: number, languageId: number) => {
  switch (languageId) {
    case 1:
      locale = countryId === 2 ? enUS : enGB;
      i18n.changeLanguage("en");
      break;
    case 2:
      locale = es;
      i18n.changeLanguage("es");
      break;
    case 3:
      locale = frCA;
      i18n.changeLanguage("fr");
      break;
    default:
      locale = enGB;
      i18n.changeLanguage("en");
      break;
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    format: function (value: any, format?: string, lng?: string) {
      if (format === "uppercase") return value.toUpperCase();
      if (value instanceof Date && format !== undefined)
        return fnsFormat(value, format, { locale });

      return value;
    },
  },
});

export { setLocale };
export default i18n;
