import React from "react";
import S from "./styles";
import TimeSlotControl from "../time-slot/TimeSlot";
import { TimeSlot } from "360";
import { slotTimesEqual } from "../utils";

type Props = {
  availableSlots: TimeSlot[];
  selectedSlots: TimeSlot[];
  onSlotSelect: (slot: TimeSlot) => void;
  onSlotDeselect: (slot: TimeSlot) => void;
};

const TimeSlotSelection = (props: Props) => {
  const { availableSlots, selectedSlots, onSlotSelect, onSlotDeselect } = props;

  const handleSlotToggle = (slot: TimeSlot, selected: boolean) =>
    selected ? onSlotDeselect(slot) : onSlotSelect(slot);

  const slots = availableSlots.map((slot) => {
    const selected =
      selectedSlots.find((ss) => slotTimesEqual(slot, ss)) !== undefined;

    return { ...slot, selected };
  });

  return (
    <S.Parent>
      {slots.map((slot) => (
        <TimeSlotControl
          key={slot.from.getTime()}
          onToggle={() => handleSlotToggle(slot, slot.selected)}
          checked={slot.selected}
          slot={slot}
        />
      ))}
    </S.Parent>
  );
};

export default TimeSlotSelection;
