import styled from "styled-components";
import _Input from "../input/Input";
import _Select from "../select/Select";
import _Button from "../button/Button";
import colours from "../../utils/colours";
import background from "./login-background.jpg";
import background480 from "./login-background480.png";
import background720 from "./login-background720.png";
import background1080 from "./login-background1080.png";
import screens from "../../utils/screens";

const Login = styled.div`
  background-image: url(${background480});
  background-position: 30%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & input,
  select,
  button,
  label {
    display: block;
    margin-bottom: 5px;
  }

  @media screen and ${screens.small} {
    background-image: url(${background720});
  }

  @media screen and ${screens.large} {
    background-image: url(${background1080});
  }

  @media screen and ${screens.extraLarge} {
    background-image: url(${background});
  }
`;

const Background = styled.div`
  background-color: ${colours.black + "8"};
  padding: 15px;
  border-radius: 5px;
`;

const Label = styled.label`
  color: ${colours.white};
`;

const HelpMessage = styled.p`
  font-size: 12px;
  color: ${colours.white};
  text-align: center;
`;

const Button = styled(_Button)`
  width: 16em;
`;

const Input = styled(_Input)`
  width: 16em;
`;

const Select = styled(_Select)`
  width: 16em;
`;

export default {
  Login,
  Button,
  Select,
  Input,
  HelpMessage,
  Label,
  Background
};
