import styled from "styled-components";
import _Button from "../../button/Button";

const JobDetails = styled.div`
  width: 97%;
  height: 100%;
  max-width: 700px;

  @media screen and (min-width: 500px) {
    min-width: 500px;
  }

  @media screen and (min-width: 700px) {
    min-width: 700px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
  text-align: center;
`;

const DetailOverview = styled.div`
  width: 100%;
`;

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
`;

const AdditionalInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
`;

const Detail = styled.span`
  margin-bottom: 5px;
`;

const DetailContent = styled.span`
  margin-bottom: 5px;
  margin-left: 10px;
`;

const MapContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  & .map {
    min-height: 16rem;
    min-width: 16rem;
    border-radius: 5px;
    height: 300px;
    width: 100%;
  }
`;

const DetailTitle = styled.div`
  font-weight: bold;
`;

const Button = styled(_Button)`
  min-width: 16rem;
`;

const Footer = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const WorkInstruction = styled.div`
  & img {
    max-width: 100%;
  }
`;

const WorkImage = styled.div`
  width: 160px;
  height: 160px;
  box-sizing: border-box;
  display: inline-block;
  max-height: 160px;
  max-width: 160px;

  & div {
    width: 100%;
    text-align: center;
  }
`;

const NoItems = styled.div`
  width: 100%;
  text-align: center;
  font-style: italic;
`;

const JobWrapper = styled.div`
  width: 100%;
`;

export default {
  JobDetails,
  Title,
  DetailContent,
  Detail,
  DetailContainer,
  DetailOverview,
  MapContainer,
  DetailTitle,
  Button,
  Footer,
  WorkInstruction,
  WorkImage,
  NoItems,
  JobWrapper,
  AdditionalInfo
};
