import jwt from "jsonwebtoken";

export const expired = (token: string) => {
  if (!token) throw new Error("token must be provided");

  const decoded = jwt.decode(token);
  if (!decoded || typeof decoded === "string")
    throw new Error("token could not be decoded");

  const exp = decoded["exp"] as number;
  const expiry = new Date(exp * 1000);
  return expiry <= new Date();
};

export const getPhoneNumber = (token: string) => {
  if (!token) throw new Error("token must be provided");

  const decoded = jwt.decode(token);
  if (!decoded || typeof decoded === "string")
    throw new Error("token could not be decoded");

  return decoded["phoneNumber"];
};

export const getCountryId = (token: string) => {
  if (!token) throw new Error("token must be provided");

  const decoded = jwt.decode(token);
  if (!decoded || typeof decoded === "string")
    throw new Error("token could not be decoded");

  return parseInt(decoded["country"]);
};

export const getLanguageId = (token: string) => {
  if (!token) throw new Error("token must be provided");

  const decoded = jwt.decode(token);
  if (!decoded || typeof decoded === "string")
    throw new Error("token could not be decoded");

  return parseInt(decoded["language"]);
};

export const getUserTypeId = (token: string): number => {
  if (!token) throw new Error("token must be provided");

  const decoded = jwt.decode(token);
  if (!decoded || typeof decoded === "string")
    throw new Error("token could not be decoded");

  return parseInt(decoded["userType"]);
};

export const getUserId = (token: string): number => {
  if (!token) throw new Error("token must be provided");

  const decoded = jwt.decode(token);
  if (!decoded || typeof decoded === "string")
    throw new Error("token must be provided");

  return parseInt(decoded["sub"]);
};
