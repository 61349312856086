import React from "react";
import S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRoad,
  faWarehouse,
  faWrench,
  faQuestion
} from "@fortawesome/pro-solid-svg-icons";
import { JobLocationType as LocationType } from "360";

type Props = {
  locationType: LocationType | null;
  height: number;
  width: number;
};

const JobLocationType = (props: Props) => {
  const { height, width, locationType } = props;

  let icon = faQuestion;

  switch (locationType) {
    case "Roadside":
      icon = faRoad;
      break;
    case "At site/yard":
      icon = faWarehouse;
      break;
    case "Servicing dealer":
      icon = faWrench;
      break;
  }

  return (
    <S.JobLocation height={height} width={width}>
      <FontAwesomeIcon icon={icon} />
    </S.JobLocation>
  );
};

export default JobLocationType;
