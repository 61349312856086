import React from "react";
import DefaultNumber from "./default-number/DefaultNumber";
import SelectNumber from "./select-number/SelectNumber";

type Props = { number?: string };

const PhoneUs = (props: Props) => {
  const { number } = props;

  return !number ? <SelectNumber /> : <DefaultNumber number={number} />;
};

export default PhoneUs;
