import styled from "styled-components";
import Button from "../../../button/Button";

const JobOverview = styled.div`
  width: 100%;
`;

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const Detail = styled.span`
  margin-bottom: 5px;
`;

const DetailContent = styled.span`
  margin-bottom: 5px;
  margin-left: 10px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 3px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const EditButton = styled(Button)``;

export default {
  JobOverview,
  DetailContainer,
  Detail,
  DetailContent,
  Title,
  EditButton,
  ButtonContainer
};
