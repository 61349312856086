import React from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";
import JobSubCategory from "../job-sub-category/JobSubCategory";
import Husbandry from "../Husbandry";

type Props = {
  husbandry: Husbandry;
  jobDetailsEditClicked?: () => void;
};

const HusbandryDetails = (props: Props) => {
  const { t } = useTranslation();

  return (
    <JobSubCategory onEditClicked={props.jobDetailsEditClicked}>
      <S.HusbandryDetails>
        <S.DetailContainer>
          <S.Detail>{t("Position")}</S.Detail>
          <S.DetailContent>{props.husbandry.position}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Size")}</S.Detail>
          <S.DetailContent>{props.husbandry.size}</S.DetailContent>
        </S.DetailContainer>
        {props.husbandry.description != null && (
          <S.DetailContainer>
            <S.Detail>{t("Description")}</S.Detail>
            <S.DetailContent>{t(props.husbandry.description)}</S.DetailContent>
          </S.DetailContainer>
        )}
      </S.HusbandryDetails>
    </JobSubCategory>
  );
};

export default HusbandryDetails;
