import React from "react";
import S from "./styles";
import { Configuration, Axle, Position } from "360";
import { useTranslation } from "react-i18next";

type PositionPreviewProps = {
  position?: Position;
};

const PositionPreview = (props: PositionPreviewProps) => {
  const { position } = props;

  return <S.Position hasPosition={!!position} />;
};

type AxlePreviewProps = {
  axle?: Axle;
};

const AxlePreview = (props: AxlePreviewProps) => {
  const { axle } = props;

  const { t } = useTranslation();

  return (
    <S.Axle>
      {props.axle && (
        <S.AxleType>
          <span>{t(props.axle.axleType.description)}</span>
        </S.AxleType>
      )}
      {[1, 2, 3, 4, 5].map((i) => (
        <PositionPreview key={i} position={axle && axle.positions[i]} />
      ))}
    </S.Axle>
  );
};

type ConfigurationPreviewProps = {
  configuration: Configuration;
};

const ConfigurationPreview = (props: ConfigurationPreviewProps) => {
  const { configuration } = props;

  return (
    <S.Configuration>
      {[1, 2, 3, 4, 5].map((i) => (
        <AxlePreview key={i} axle={configuration.axles[i]} />
      ))}
    </S.Configuration>
  );
};

export default ConfigurationPreview;
