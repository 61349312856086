import styled from "styled-components";
import _Button from "../../../button/Button";
import colours from "../../../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfigurationChooser = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    display: block;
    margin-bottom: 5px;
  }
`;

const Heading = styled.p`
  width: 16rem;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LittleButton = styled(_Button)`
  flex-grow: 1;
  margin-right: 5px;

  &:last-child {
    margin-right: 0px;
  }
`;

const BigButton = styled(_Button)`
  width: 16rem;
`;

const ArrowButton = styled(_Button)`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  max-width: 16rem;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-right: 5px;
  color: ${colours.yellow};
`;

export default {
  ConfigurationChooser,
  PreviewHolder,
  ArrowButton,
  Heading,
  ButtonRow,
  LittleButton,
  BigButton,
  Warning,
  WarningIcon
};
