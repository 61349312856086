import React from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";
import Modal from "../modal/Modal";

type Props = {
  errors: string[];
  onClose: () => void;
};

const GeneralErrors = (props: Props) => {
  const { errors, onClose } = props;

  const { t } = useTranslation();

  const header = t(errors.length > 1 ? "Errors" : "Error");

  return (
    <Modal onClose={onClose}>
      <S.Container>
        <S.Header>{header}</S.Header>
        <S.List>
          {errors.map((e, i) => (
            <S.ListItem key={i}>{e}</S.ListItem>
          ))}
        </S.List>
      </S.Container>
    </Modal>
  );
};

export default GeneralErrors;
