import styled from "styled-components";

type CheckBoxProps = { isChecked: boolean };

const CheckBox = styled.div<CheckBoxProps>`
  background-color: ${props => (props.isChecked ? "orange" : "lightgray")};
  border-radius: 5px;
  width: 25px;
  height: 25px;
`;

export default { CheckBox };
