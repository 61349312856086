import styled from "styled-components";
import colors from "../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _Button from "../button/Button";

const Parent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Gallery = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

const FileSelect = styled.input`
  width: 0.1px;
  height: 0.1px;
  display: none;
`;
const UploadContainer = styled.div`
  height: 1vh;
`;

const UploadButton = styled.div`
  background-color: orange;
  width: 16rem;
  margin: auto;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: calc(5px + 1vw);
  width: 16rem;
`;

const ConfirmButton = styled(UploadButton)``;

const Upload = styled.label`
  font-size: 1rem;
  display: block;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  & span {
    font-size: 1em;
    margin-left: 10px;
  }

  &:hover {
  }
`;

const Image = styled.img`
  max-width: 200px;
  max-height: 200px;
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 1;
`;

const Footer = styled.div`
  margin-bottom: 5px;
  justify-content: space-around;
  display: flex;
  width: 100%;
`;

type ErrorProp = {
  alert: boolean;
};

const Error = styled.div<ErrorProp>`
  color: ${(props) => (props.alert ? colors.lightRed : colors.black)};
  margin-bottom: 5px;
  max-width: 16rem;
`;

const Trash = styled(FontAwesomeIcon)`
color: #808080
opacity: 0.5;
position: absolute;
z-index: 2;
left: 170px;
top: 2px;
pointer-events: none

`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 200px;
  width: 200px;
  border: 2px solid black;
  margin: 2px;
`;

const DataUsageWarning = styled.p`
  margin-bottom: 5px;
  max-width: 16rem;
`;

const BackButton = styled(_Button)`
  width: 16rem;
`;

export default {
  BackButton,
  Parent,
  Image,
  FileSelect,
  Upload,
  UploadButton,
  UploadContainer,
  ConfirmButton,
  Footer,
  Gallery,
  Error,
  DataUsageWarning,
  Trash,
  ImageContainer,
};
