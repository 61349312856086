import React, { useState, useEffect } from "react";
import S from "./styles";
import Button from "./button/Button";
import {
  faLocationArrow,
  faFlagCheckered,
  faThumbsUp,
  faHome,
  faCamera,
  faTimes,
  faClipboardList,
  faClipboardListCheck,
  faFile,
} from "@fortawesome/pro-solid-svg-icons";
import { useApi, Api, Response, isSuccess, mapSuccess } from "../../../api/Api";
import Job from "../../job-details/Job";
import Spinner from "../../spinner/Spinner";
import { useTranslation } from "react-i18next";
import JobOverview from "./job-overview/JobOverview";
import JobDetails from "../job-details/JobDetails";
import EnRoute from "../en-route/EnRoute";
import JobTakeover from "../job-takeover/JobTakeover";
import OnScene from "../on-scene/OnScene";
import WorkComplete from "../work-complete/WorkComplete";
import JobCancelled from "../job-cancelled/JobCancelled";
import ReturnedHome from "../returned-home/ReturnedHome";
import Camera from "../camera/Camera";
import CustomerDocuments from "../documents/CustomerDocuments";
import GeneralErrors from "../../general-errors/GeneralErrors";

type JobResponse = {
  key: string;
  job: Job;
};

type JobStatus = {
  enRoute: number;
  arrived: number;
  workComplete: number;
  returnedHome: number;
  cancelled: number;
};

type Props = {
  jobNumber: number;
  phoneNumber: string;
  userId: number;
  onBackClicked: () => void;
};

type ActiveComponent =
  | "JOB-MANAGEMENT"
  | "JOB-DETAILS"
  | "EN-ROUTE"
  | "ON-SCENE"
  | "WORK-COMPLETE"
  | "JOB-CANCELLED"
  | "RETURNED-HOME"
  | "CAMERA"
  | "TAKEOVER"
  | "CUSTOMER-DOCUMENTS";

const getJob = async (
  api: Api,
  jobNumber: number
): Promise<Response<JobResponse>> => {
  const response: Response<any> = await api.get(`api/jobs/${jobNumber}`, "1.0");
  return mapSuccess(response, (r) => ({
    key: r.key,
    job: {
      ...r.job,
      jobDate: new Date(r.job.jobDate),
      timeAllocated:
        r.job.timeAllocated !== null ? new Date(r.job.timeAllocated) : null,
      estimatedTimeOfArrival:
        r.job.estimatedTimeOfArrival !== null
          ? new Date(r.job.estimatedTimeOfArrival)
          : null,
      onSceneTime:
        r.job.onSceneTime !== null ? new Date(r.job.onSceneTime) : null,
      enRoute: r.job.enRoute !== null ? new Date(r.job.enRoute) : null,
      completed: r.job.completed !== null ? new Date(r.job.completed) : null,
    },
  }));
};

const getStatus = (api: Api, jobId: number): Promise<Response<JobStatus>> =>
  api.get(`api/allocated-jobs/${jobId}/job-status`, "1.0");

const JobManagement = ({
  jobNumber,
  onBackClicked,
  phoneNumber,
  userId,
}: Props) => {
  const [job, setJob] = useState<Job | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [jobStatus, setJobStatus] = useState<JobStatus | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const { t } = useTranslation();
  const [activeComponent, setActiveComponent] =
    useState<ActiveComponent>("JOB-MANAGEMENT");

  const api = useApi();

  const handleJobDetailsClicked = () => setActiveComponent("JOB-DETAILS");
  const handleEnRouteClicked = () => setActiveComponent("EN-ROUTE");
  const handleTakeoverClicked = () => setActiveComponent("TAKEOVER");
  const handleArrivedClicked = () => setActiveComponent("ON-SCENE");
  const handleWorkCompleteClicked = () => setActiveComponent("WORK-COMPLETE");
  const handleJobCancelledClicked = () => setActiveComponent("JOB-CANCELLED");
  const handleReturnedHomeClicked = () => setActiveComponent("RETURNED-HOME");
  const handleCameraClicked = () => setActiveComponent("CAMERA");
  const handleCustomerDocumentsClick = () =>
    setActiveComponent("CUSTOMER-DOCUMENTS");

  const onFail = async (errors?: string[]) => {
    await reloadJob();

    const errorsToDisplay =
      errors !== undefined && errors.length > 0
        ? errors
        : [
            "A serious error has been encountered. Please contact us for assistance.",
          ];
    setErrors(errorsToDisplay);
  };

  const setJobManagementActive = () => {
    setActiveComponent("JOB-MANAGEMENT");
  };

  const reloadJob = async () => {
    try {
      setLoading(true);
      const [job, status] = await Promise.all<
        Response<JobResponse>,
        Response<JobStatus>
      >([getJob(api, jobNumber), getStatus(api, jobNumber)]);

      if (isSuccess(job) && isSuccess(status)) {
        setJobStatus(status.data);
        setJob(job.data.job);
        setJobManagementActive();
        return setLoading(false);
      }
    } catch {}

    onFail();
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const [job, status] = await Promise.all<
          Response<JobResponse>,
          Response<JobStatus>
        >([getJob(api, jobNumber), getStatus(api, jobNumber)]);

        if (isSuccess(job) && isSuccess(status)) {
          setJobStatus(status.data);
          setJob(job.data.job);
          return setLoading(false);
        }
      } catch {}

      setErrors([
        "A serious error has been encountered. Please contact us for assistance.",
      ]);
      setLoading(false);
    })();
  }, [jobNumber, api]);

  if (loading) return <Spinner />;

  if (job === null || jobStatus === null) {
    throw new Error(
      "component has incorrect state: job or job status is null."
    );
  }

  const jobIsAllocatedToDifferentFitter =
    job && job.assignedFitterId && job.assignedFitterId !== userId;
  const canTakeOverJob =
    job && jobIsAllocatedToDifferentFitter && job.completed === null;
  const canSubmitEnRoute =
    job &&
    !jobIsAllocatedToDifferentFitter &&
    job.enRoute === null &&
    job.onSceneTime == null &&
    job.completed === null;
  const canSubmitOnScene =
    job &&
    !jobIsAllocatedToDifferentFitter &&
    job.enRoute !== null &&
    job.onSceneTime === null &&
    job.completed === null;
  const canSubmitCompletion =
    job &&
    !jobIsAllocatedToDifferentFitter &&
    job.enRoute !== null &&
    job.onSceneTime !== null &&
    job.completed === null;
  const canSubmitReturnedHome =
    job &&
    !jobIsAllocatedToDifferentFitter &&
    job.enRoute !== null &&
    job.onSceneTime !== null &&
    job.completed !== null;

  let component = <Spinner />;

  switch (activeComponent) {
    case "JOB-MANAGEMENT":
      component = (
        <div>
          <S.Title>{t("Job Management")}</S.Title>
          <JobOverview
            tsuNumber={job.id}
            locationType={job.locationType}
            vehicleReg={job.registration}
            eta={job.estimatedTimeOfArrival}
            customer={job.customerName}
            endUser={job.endUser}
            attachedVehicleReg={job.attachedRegistration}
          ></JobOverview>
          <hr></hr>
          <S.Home>
            <Button
              label={t("Job Details")}
              icon={faClipboardList}
              onClick={handleJobDetailsClicked}
              status={2}
            ></Button>
            <Button
              label={t("Customer documents")}
              icon={faFile}
              onClick={handleCustomerDocumentsClick}
              status={2}
            ></Button>
            {canTakeOverJob && (
              <Button
                label={t("Takeover Job")}
                icon={faClipboardListCheck}
                onClick={handleTakeoverClicked}
                status={3}
              ></Button>
            )}
            {canSubmitEnRoute && (
              <Button
                label={t("En Route")}
                icon={faLocationArrow}
                status={jobStatus.enRoute}
                onClick={handleEnRouteClicked}
              />
            )}
            {canSubmitOnScene && (
              <Button
                label={t("Arrived")}
                icon={faFlagCheckered}
                onClick={handleArrivedClicked}
                status={jobStatus.arrived}
              />
            )}
            {canSubmitCompletion && (
              <Button
                label={t("Work Complete")}
                icon={faThumbsUp}
                onClick={handleWorkCompleteClicked}
                status={jobStatus.workComplete}
              />
            )}
            {canSubmitReturnedHome && (
              <Button
                label={t("Returned Home")}
                icon={faHome}
                onClick={handleReturnedHomeClicked}
                status={jobStatus.returnedHome}
              />
            )}
            {!jobIsAllocatedToDifferentFitter && (
              <Button
                label={t("Photos")}
                icon={faCamera}
                onClick={handleCameraClicked}
              />
            )}
            <Button
              label={t("Job Cancelled")}
              icon={faTimes}
              onClick={handleJobCancelledClicked}
              status={jobStatus.cancelled}
            />
            <S.Button onClick={onBackClicked}>{t("Return to jobs")}</S.Button>
          </S.Home>
        </div>
      );
      break;

    case "JOB-DETAILS":
      component = (
        <JobDetails
          onReturn={() => {
            setJobManagementActive();
          }}
          jobNumber={jobNumber}
        ></JobDetails>
      );
      break;

    case "EN-ROUTE":
      component = (
        <EnRoute
          onContinue={async () => await reloadJob()}
          jobNumber={jobNumber}
          onReturn={setJobManagementActive}
          onFail={onFail}
        ></EnRoute>
      );
      break;

    case "TAKEOVER":
      component = (
        <JobTakeover
          onContinue={async () => await reloadJob()}
          jobId={jobNumber}
          eta={job.estimatedTimeOfArrival}
          onReturn={setJobManagementActive}
          onFail={onFail}
        ></JobTakeover>
      );
      break;

    case "ON-SCENE":
      component = (
        <OnScene
          onContinue={async () => await reloadJob()}
          jobNumber={jobNumber}
          onReturn={setJobManagementActive}
          onFail={onFail}
        ></OnScene>
      );
      break;

    case "WORK-COMPLETE":
      component = (
        <WorkComplete
          onContinue={async () => await reloadJob()}
          jobId={jobNumber}
          confirmationRequired={job.tyres}
          husbandriesNeedingConfirmation={job.husbandries.map((h) => ({
            id: h.id,
            position: h.position,
            failureDescription: "",
          }))}
          phoneNumber={phoneNumber}
          countryId={job.countryId}
          onReturn={setJobManagementActive}
          onReturnToPhotos={handleCameraClicked}
          onFail={onFail}
        />
      );
      break;

    case "JOB-CANCELLED":
      component = (
        <JobCancelled
          onCancelled={() => {
            onBackClicked();
          }}
          jobId={jobNumber}
          onReturn={setJobManagementActive}
          onFail={onFail}
        ></JobCancelled>
      );
      break;

    case "RETURNED-HOME":
      component = (
        <ReturnedHome
          onComplete={() => {
            var updatedStatus = jobStatus;
            updatedStatus.returnedHome = 5;
            updatedStatus.enRoute = 5;
            updatedStatus.arrived = 5;
            updatedStatus.workComplete = 5;
            updatedStatus.cancelled = 5;
            setJobManagementActive();
          }}
          jobId={jobNumber}
          onReturn={setJobManagementActive}
          onFail={onFail}
        ></ReturnedHome>
      );
      break;

    case "CAMERA":
      component = (
        <Camera
          onContinue={() => {
            setJobManagementActive();
          }}
          job={job}
          jobId={jobNumber}
          onFail={onFail}
        ></Camera>
      );
      break;

    case "CUSTOMER-DOCUMENTS":
      component = (
        <CustomerDocuments
          onBackClick={() => setJobManagementActive()}
          customerId={job.customerId}
        />
      );
      break;

    default:
      onFail();
      break;
  }

  if (errors.length > 0)
    return (
      <GeneralErrors
        errors={errors.map((e) => t(e))}
        onClose={() => {
          setErrors([]);
        }}
      />
    );

  return <React.Fragment>{component}</React.Fragment>;
};

export default JobManagement;
