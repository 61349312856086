import styled from "styled-components";
import _Button from "../../button/Button";
import _Input from "../../input/Input";
import colours from "../../../utils/colours";

const Contacts = styled.div`
  & > * {
    margin-bottom: 5px;
  }

  max-width: 16rem;
`;

const Header = styled.p``;

const Button = styled(_Button)`
  width: 16rem;
`;

const Input = styled(_Input)`
  width: 16rem;
`;

const Contact = styled(_Button)`
  width: 16rem;
  background-color: ${colours.grey3};
  word-break: break-all;

  &:hover {
    background-color: ${colours.extraLightRed};
  }
`;

export default {
  Contacts,
  Header,
  Button,
  Input,
  Contact,
};
