import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow } from "@react-google-maps/api";
import GettingLocation from "./getting-location/GettingLocation";
import LocationFailed from "./location-failed/LocationFailed";
import { useTranslation } from "react-i18next";
import S from "./styles";
import Spinner from "../../../spinner/Spinner";
import { useApi, isNoContent } from "../../../../api/Api";
import CompleteLocation from "../CompleteLocation";

type Props = {
  onComplete: (latitude: number, longitude: number, location: string) => void;
  onBackClick: () => void;
  onManualLocation: (initialLocation: string | null) => void;
  reference: string;
  onFail: () => void;
  onCancel: () => void;
};

const reverseGeocode = (coords: Coordinates): Promise<string> =>
  new Promise((resolve, reject) => {
    const position = {
      lat: coords.latitude,
      lng: coords.longitude,
    };

    new google.maps.Geocoder().geocode(
      { location: position },
      (
        result: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(result[0].formatted_address);
        } else {
          reject("Failed to geocode coordinates.");
        }
      }
    );
  });

const getLocation = async (
  onAutoLocation: (loc: CompleteLocation) => void,
  onManualLocation: () => void,
  onError: () => void
) =>
  navigator.geolocation.getCurrentPosition(
    async (pos) => {
      try {
        const location = await reverseGeocode(pos.coords);
        onAutoLocation({ formattedAddress: location, coordinates: pos.coords });
      } catch {
        onError();
      }
    },
    onManualLocation,
    { timeout: 2e4, enableHighAccuracy: true }
  );

const AutomaticLocation = (props: Props) => {
  const {
    onManualLocation,
    onCancel,
    onComplete: onContinue,
    onBackClick,
    onFail,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<CompleteLocation | null>(null);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();
  const api = useApi();

  const submitLocation = async (
    reference: string,
    location: string,
    latitude: number,
    longitude: number
  ) =>
    api.post(`api/breakdowns/${reference}/location`, "1.0", {
      location,
      coordinates: {
        latitude,
        longitude,
      },
    });

  useEffect(() => {
    (async () => {
      if (!navigator.geolocation) {
        // if the device doesn't support geolocation, skip straight to manual entry
        onManualLocation(null);
      } else {
        getLocation(
          setLocation,
          () => onManualLocation(null),
          () => setError(true)
        );
      }
    })();
  }, [onManualLocation]);

  const handleYesClick = async () => {
    try {
      if (location === null) return;

      setLoading(true);
      const response = await submitLocation(
        props.reference,
        location.formattedAddress,
        location.coordinates.latitude,
        location.coordinates.longitude
      );
      setLoading(false);

      if (isNoContent(response))
        return onContinue(
          location.coordinates.latitude,
          location.coordinates.longitude,
          location.formattedAddress
        );
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  if (error) {
    return (
      <LocationFailed
        onRetry={() => {
          setError(false);
          getLocation(
            setLocation,
            () => onManualLocation(null),
            () => setError(true)
          );
        }}
        onManualLocation={() => onManualLocation(null)}
      />
    );
  }

  if (!location) return <GettingLocation />;

  const position = {
    lat: location.coordinates.latitude,
    lng: location.coordinates.longitude,
  };

  return (
    <S.Container>
      <p>{t("Is this location correct?")}</p>
      <GoogleMap
        mapContainerClassName="map"
        zoom={16}
        center={position}
        options={{
          fullscreenControl: false,
          clickableIcons: false,
          mapTypeControl: false,
          streetViewControl: false,
          draggable: false,
        }}
      >
        <InfoWindow position={position}>
          <div>
            <h1>{location.formattedAddress}</h1>
          </div>
        </InfoWindow>
      </GoogleMap>
      <S.Button onClick={handleYesClick}>{t("Yes")}</S.Button>
      <S.Button
        onClick={() => onManualLocation(location && location.formattedAddress)}
      >
        {t("No - enter location")}
      </S.Button>
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
      <S.Button onClick={onCancel}>{t("Save for later or cancel")}</S.Button>
    </S.Container>
  );
};

export default AutomaticLocation;
