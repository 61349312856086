import styled from "styled-components";
import colours from "../../utils/colours";

const Notifications = styled.div`
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NotificationHeader = styled.div`
  font-weight: bold;
`;

const NotificationList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 5px;
`;

const NotificationGroup = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px;
  background-color: ${colours.grey4};
`;

export default {
  Notifications,
  NotificationHeader,
  NotificationList,
  NotificationGroup
};
