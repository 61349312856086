import styled from "styled-components";
import _Button from "../../button/Button";
import _Input from "../../input/Input";

const ContactCaller = styled.div`
  & label,
  input,
  p,
  button {
    margin-bottom: 5px;
    display: block;
  }
`;

const Input = styled(_Input)`
  width: 16rem;
`;

const ContinueButton = styled(_Button)`
  width: 16rem;
`;

const WhoButton = styled(_Button)`
  display: inline-block;
  width: 7.9rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SomeoneElseContainer = styled.div`
  & div {
    margin-bottom: 5px;
  }
`;

export default {
  ContactCaller,
  ButtonContainer,
  WhoButton,
  ContinueButton,
  Input,
  SomeoneElseContainer,
};
