import styled from "styled-components";
import _Button from "../../button/Button";
import _Input from "../../input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colours from "../../../utils/colours";

const Vehicle = styled.div`
  & > * {
    display: block;
    margin-bottom: 5px;
  }
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  max-width: 16rem;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-right: 5px;
  color: ${colours.yellow};
`;

const Input = styled(_Input)`
  width: 16rem;
`;

export default { Vehicle, Input, Button, Warning, WarningIcon };
