import React from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";

type Props = {
  errors: string[];
};

const FieldErrors = (props: Props) => {
  const { t } = useTranslation();

  return (
    <S.FieldErrors>
      {props.errors.map((e, i) => (
        <S.Error key={i}>{t(e)}</S.Error>
      ))}
    </S.FieldErrors>
  );
};

export default FieldErrors;
