import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";
import VR from "../../../utils/ValidationResult";
import { useApi, isNoContent, isSuccess } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import { ContactCaller as CC } from "360";

type Props = {
  onComplete: (contactCaller: CC) => void;
  initialValue: CC | null;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
  reference: string;
};

const ContactCaller = (props: Props) => {
  const { onCancel, onComplete, onFail, onBackClick, initialValue } = props;
  const [name, setName] = useState<string>(initialValue?.name || "");
  const [telephone, setTelephone] = useState<string>(
    initialValue?.telephone || ""
  );
  const [depot, setDepot] = useState<string>(initialValue?.depot || "");
  const [userWithVehicle, setUserWithVehicle] = useState<boolean>(
    initialValue === null || !initialValue.driverName
  );
  const [theirName, setTheirName] = useState<string>(
    initialValue?.driverName || ""
  );
  const [theirTelephone, setTheirTelephone] = useState<string>(
    initialValue?.driverTelephone || ""
  );
  const [contactDepotRequired, setContactDepotRequired] = useState<
    boolean | null
  >(null);
  const [vr, setVr] = useState<VR>(VR.empty);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useApi();
  const { t } = useTranslation();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);
  const handleDepotChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDepot(e.target.value);
  const handleTelephoneChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTelephone(e.target.value);
  const handleTheirNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTheirName(e.target.value);
  const handleTheirTelephoneChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTheirTelephone(e.target.value);
  const handleYouClick = () => {
    setUserWithVehicle(true);
    setTheirName("");
    setTheirTelephone("");
  };
  const handleSomeoneElseClick = () => setUserWithVehicle(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<boolean>(
          `api/breakdowns/${props.reference}/contact-details/contact-depot-required`,
          "1.0"
        );
        setLoading(false);

        if (isSuccess(response)) {
          return setContactDepotRequired(response.data);
        }
      } catch {}

      onFail();
    })();
  }, [onFail, api, props.reference]);

  const validate = (): VR => {
    const vr = VR.empty;

    if (!name.trim()) {
      vr.add("name", t("Field is required."));
    }

    if (name && name.trim().length > 50) {
      vr.add("name", t("Maximum length is {{len}} characters.", { len: 50 }));
    }

    if (!telephone.trim()) {
      vr.add("telephone", t("Field is required."));
    }

    if (telephone && telephone.trim().length > 50) {
      vr.add(
        "telephone",
        t("Maximum length is {{len}} characters.", { len: 50 })
      );
    }

    if (depot && depot.trim().length > 50) {
      vr.add("depot", t("Maximum length is {{len}} characters.", { len: 50 }));
    }

    if (contactDepotRequired && !depot.trim()) {
      vr.add("depot", t("Your depot is required for this contract."));
    }

    if (!userWithVehicle) {
      if (!theirName.trim()) {
        vr.add("theirName", t("Field is required."));
      }

      if (theirName && theirName.trim().length > 50) {
        vr.add(
          "theirName",
          t("Maximum length is {{len}} characters.", { len: 50 })
        );
      }

      if (!theirTelephone.trim()) {
        vr.add("theirTelephone", t("Field is required."));
      }

      if (theirTelephone && theirTelephone.trim().length > 50) {
        vr.add(
          "theirTelephone",
          t("Maximum length is {{len}} characters.", { len: 50 })
        );
      }
    }

    return vr;
  };

  const submitContactCaller = async (
    reference: string,
    contactName: string,
    contactTelephone: string,
    contactDepot: string,
    driverName: string,
    driverTelephone: string
  ) =>
    api.post(`api/breakdowns/${reference}/contact-details`, "1.0", {
      contactName,
      contactTelephone,
      contactDepot,
      driverName,
      driverTelephone,
    });

  const handleContinue = async () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    try {
      setLoading(true);
      const response = await submitContactCaller(
        props.reference,
        name,
        telephone,
        depot,
        theirName,
        theirTelephone
      );
      setLoading(false);

      if (isNoContent(response))
        return onComplete({
          name,
          telephone,
          depot,
          driverName: theirName,
          driverTelephone: theirTelephone,
        });
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  return (
    <S.ContactCaller>
      <label htmlFor="name">{t("Your full name")}</label>
      <S.Input
        id="name"
        value={name}
        onChange={handleNameChange}
        errors={vr.getErrors("name")}
      />
      <label htmlFor="telephone">{t("Your telephone number")}</label>
      <S.Input
        id="telephone"
        type="tel"
        value={telephone}
        onChange={handleTelephoneChange}
        errors={vr.getErrors("telephone")}
      />
      <label htmlFor="depot">{t("Your depot")}</label>
      <S.Input
        id="depot"
        value={depot}
        onChange={handleDepotChange}
        errors={vr.getErrors("depot")}
      />
      <p>{t("Who is with the vehicle?")}</p>
      <S.ButtonContainer>
        <S.WhoButton
          onClick={handleYouClick}
          colour={userWithVehicle ? "ORANGE" : "GREY"}
        >
          {t("You")}
        </S.WhoButton>
        <S.WhoButton
          onClick={handleSomeoneElseClick}
          colour={!userWithVehicle ? "ORANGE" : "GREY"}
        >
          {t("Someone else")}
        </S.WhoButton>
      </S.ButtonContainer>
      {!userWithVehicle && (
        <S.SomeoneElseContainer>
          <label htmlFor="theirName">{t("Their full name")}</label>
          <S.Input
            id="theirName"
            value={theirName}
            onChange={handleTheirNameChange}
            errors={vr.getErrors("theirName")}
          />
          <label htmlFor="theirTelephone">{t("Their telephone number")}</label>
          <S.Input
            id="theirTelephone"
            type="tel"
            value={theirTelephone}
            onChange={handleTheirTelephoneChange}
            errors={vr.getErrors("theirTelephone")}
          />
        </S.SomeoneElseContainer>
      )}
      <S.ContinueButton onClick={handleContinue}>
        {t("Continue")}
      </S.ContinueButton>
      <S.ContinueButton onClick={onBackClick}>{t("Back")}</S.ContinueButton>
      <S.ContinueButton onClick={onCancel}>
        {t("Save for later or cancel")}
      </S.ContinueButton>
    </S.ContactCaller>
  );
};

export default ContactCaller;
