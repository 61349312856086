import React, { useState } from "react";
import S from "./styles";
import Button from "../../button/Button";
import { useTranslation } from "react-i18next";
import Gallery from "../../image-gallery/ImageGallery";
import JobSubCategory from "../../job-details/job-sub-category/JobSubCategory";
import { faImages } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WorkRequired } from "360";

type Props = {
  workRequired: WorkRequired;
  jobDetailsEditClicked?: () => void;
  reference: string;
  otpKey: string;
};

const TyreDetails = (props: Props) => {
  const { workRequired, jobDetailsEditClicked, reference, otpKey } = props;
  const [showImages, setShowImages] = useState<boolean>(false);
  const { t } = useTranslation();

  const images = workRequired.photoIds.map((id) => ({
    src: `api/breakdowns/${reference}/photos/${id}?v=1&key=${otpKey}`,
  }));

  return (
    <JobSubCategory onEditClicked={jobDetailsEditClicked}>
      <S.TyreDetails>
        <S.DetailContainer>
          <S.Detail>{t("Position")}</S.Detail>
          <S.DetailContent>{workRequired.position.description}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Size")}</S.Detail>
          <S.DetailContent>{workRequired.tyreSize}</S.DetailContent>
        </S.DetailContainer>
        {workRequired.removalReason != null && (
          <S.DetailContainer>
            <S.Detail>{t("Removal reason")}</S.Detail>
            <S.DetailContent>{t(workRequired.removalReason)}</S.DetailContent>
          </S.DetailContainer>
        )}
        {workRequired.husbandryRequested != null && (
          <S.DetailContainer>
            <S.Detail>{t("Husbandry requested")}</S.Detail>
            <S.DetailContent>
              {t(workRequired.husbandryRequested)}
            </S.DetailContent>
          </S.DetailContainer>
        )}
        {workRequired.photoIds !== undefined ? (
          workRequired.photoIds.length > 0 && (
            <Button
              onClick={() => {
                setShowImages(!showImages);
              }}
            >
              <FontAwesomeIcon icon={faImages} />
              {t(showImages ? "Hide images" : "Show images")}
            </Button>
          )
        ) : (
          <div></div>
        )}
        {showImages && images && <Gallery images={images} />}
      </S.TyreDetails>
    </JobSubCategory>
  );
};

export default TyreDetails;
