import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SingleFieldComponent from "../single-field-component/SingleFieldComponent";
import Spinner from "../../spinner/Spinner";
import S from "./styles";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useApi, isSuccess } from "../../../api/Api";

type Props = {
  onComplete: (mileage: number | null) => void;
  initialValue: number | null;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
  reference: string;
};

const ValidationWarning = () => {
  const { t } = useTranslation();
  return (
    <S.Warning>
      <S.WarningIcon icon={faExclamationTriangle} />
      <span>
        {t(
          "Mileage is required for this contract. You may continue if this is unavailable."
        )}
      </span>
    </S.Warning>
  );
};

const Mileage = (props: Props) => {
  const { initialValue, onCancel, onComplete, onFail, onBackClick } = props;
  const [mileage, setMileage] = useState<number | null>(initialValue);
  const [lastSubmittedMileage, setLastSubmittedMileage] = useState<
    number | null
  >(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useApi();
  const { t } = useTranslation();

  const submitMileage = async (reference: string, mileage: number | null) =>
    api.post(`api/breakdowns/${reference}/mileage`, "1.0", { mileage });

  const handleChange = (value: string) => {
    const digits = value.replace(/\D/, "");

    if (!digits) return setMileage(null);

    const mileage = parseInt(digits);

    if (!isNaN(mileage)) setMileage(mileage > 0 ? mileage : null);
  };

  const handleContinue = async () => {
    // if the value has not changed since last submit, continue to the next component
    if (submitted && mileage === lastSubmittedMileage)
      return onComplete(mileage);

    try {
      setLoading(true);
      const response = await submitMileage(props.reference, mileage);
      setLoading(false);

      if (isSuccess(response)) {
        setSubmitted(true);

        // if the save was successful but failed validation, set lastSubmittedMileage
        // so that the user is shown the warning.
        if (response.data) return setLastSubmittedMileage(mileage);

        return onComplete(mileage);
      }
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  return (
    <SingleFieldComponent
      header={t("Mileage")}
      type="NUMERIC-INPUT"
      value={mileage === null ? "" : mileage.toString()}
      onChange={handleChange}
      onContinue={handleContinue}
      onBackClick={onBackClick}
      onCancel={onCancel}
    >
      {submitted && <ValidationWarning />}
    </SingleFieldComponent>
  );
};

export default Mileage;
