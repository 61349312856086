import React, { useState, useEffect } from "react";
import {
  formatISO,
  add,
  differenceInDays,
  startOfDay,
  startOfToday,
} from "date-fns";
import S from "./styles";
import TimeSlotSelection from "./time-slot-selection/TimeSlotSelection";
import TimeSlotControl from "./time-slot/TimeSlot";
import { TimeSlot } from "360";
import { useTranslation } from "react-i18next";
import {
  Api,
  useApi,
  isSuccess,
  isNoContent,
  mapSuccess,
} from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import GeneralErrors from "../../general-errors/GeneralErrors";
import {
  allDaySlot,
  slotsEquivalentToAllDaySelected,
  slotTimesEqual,
  adjustSlotsToDate,
} from "./utils";

type Props = {
  initialValue: TimeSlot[] | null;
  reference: string;
  onComplete: (availability: TimeSlot[]) => void;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
};

const submitTimeslots = async (
  api: Api,
  reference: string,
  slots: TimeSlot[]
) => {
  const availability = slots.map((slot) => ({
    from: formatISO(slot.from),
    to: formatISO(slot.to),
  }));
  return api.post(`api/breakdowns/${reference}/availability`, "1.0", {
    availability,
  });
};

const VehicleAvailability = (props: Props) => {
  const {
    reference,
    onCancel,
    onBackClick,
    onComplete,
    onFail,
    initialValue,
  } = props;
  const [availableSlots, setAvailableSlots] = useState<TimeSlot[]>([]);
  const [allDaySelected, setAllDaySelected] = useState<boolean>(
    (initialValue && slotsEquivalentToAllDaySelected(initialValue)) || false
  );
  const [selectedSlots, setSelectedSlots] = useState<TimeSlot[]>(
    initialValue && !allDaySelected ? initialValue : []
  );
  const [date, setDate] = useState<Date>(
    initialValue && initialValue.length > 0
      ? startOfDay(initialValue[0].from)
      : startOfToday()
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = mapSuccess<any[], TimeSlot[]>(
          await api.get("api/time-slots", "1.0"),
          (slots) =>
            slots.map((slot) => ({
              from: new Date(slot.from),
              to: new Date(slot.to),
            }))
        );
        setLoading(false);

        if (isSuccess(response)) {
          return setAvailableSlots(response.data);
        }
      } catch {}

      onFail();
    })();
  }, [onFail, api]);

  const daysFromToday = differenceInDays(date, startOfToday());
  const canClickPreviousDay = daysFromToday > 0;
  const canClickNextDay = daysFromToday < 2;

  const handlePreviousDayClicked = () => {
    if (!canClickPreviousDay) {
      return;
    }
    setDate(add(date, { days: -1 }));
  };

  const handleNextDayClicked = () => {
    if (!canClickNextDay) {
      return;
    }

    setDate(add(date, { days: 1 }));
  };

  const handleConfirmClicked = async () => {
    if (!allDaySelected && selectedSlots.length === 0) {
      setErrors([t("Warning: You must select a time slot.")]);
      return;
    }

    const selected = allDaySelected ? [allDaySlot] : selectedSlots;
    const adjustedToDate = adjustSlotsToDate(date, selected);

    try {
      setLoading(true);
      const response = await submitTimeslots(api, reference, adjustedToDate);
      setLoading(false);

      if (isNoContent(response)) return onComplete(adjustedToDate);
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  const handleAllDayToggle = () => {
    setAllDaySelected(!allDaySelected);
    setSelectedSlots([]);
  };

  const handleSlotSelected = (slot: TimeSlot) => {
    setSelectedSlots([...selectedSlots, slot]);
    setAllDaySelected(false);
  };

  const handleSlotDeselected = (slot: TimeSlot) => {
    const filtered = selectedSlots.filter(
      (selected) => !slotTimesEqual(selected, slot)
    );
    setSelectedSlots(filtered);
    setAllDaySelected(false);
  };

  if (availableSlots.length > 0) {
    return (
      <S.Parent>
        <S.Title>{t("Select Availability")}</S.Title>
        <S.CurrentDayContainer>
          <S.CurrentDay>{t("AVAILABILITY_DATE", { date: date })}</S.CurrentDay>
        </S.CurrentDayContainer>
        <S.SubTitle>{t("Available All Day")} </S.SubTitle>
        <S.AllDayContainer>
          <TimeSlotControl
            onToggle={handleAllDayToggle}
            slot={allDaySlot}
            checked={allDaySelected}
          />
        </S.AllDayContainer>
        <S.SubTitle>{t("Pick Availability")}</S.SubTitle>
        <TimeSlotSelection
          availableSlots={availableSlots}
          selectedSlots={selectedSlots}
          onSlotSelect={handleSlotSelected}
          onSlotDeselect={handleSlotDeselected}
        />
        <S.BtnContainer>
          <S.Button
            disabled={!canClickPreviousDay}
            onClick={handlePreviousDayClicked}
          >
            {t("Previous Day")}
          </S.Button>
          <S.Button disabled={!canClickNextDay} onClick={handleNextDayClicked}>
            {t("Next Day")}
          </S.Button>
        </S.BtnContainer>
        <S.ContinueButton onClick={handleConfirmClicked}>
          {t("Confirm")}
        </S.ContinueButton>
        <S.ContinueButton onClick={onBackClick}>{t("Back")}</S.ContinueButton>
        <S.ContinueButton onClick={onCancel}>
          {t("Save for later or cancel")}
        </S.ContinueButton>
      </S.Parent>
    );
  }

  return <Spinner />;
};

export default VehicleAvailability;
