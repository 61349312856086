import React, { useState, useEffect } from "react";
import { faTire, faInfo, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import S from "./styles";
import ContactInformation from "../../job-details/contact-information/ContactInformation";
import LocationDetails from "../../job-details/location-details/LocationDetails";
import JobCategory from "../../job-details/job-category/JobCategory";
import TyreDetails from "../tyre-details/TyreDetails";
import JobOverview from "../../job-details/job-overview/JobOverview";
import JobSubCategory from "../../job-details/job-sub-category/JobSubCategory";
import Availability from "../../job-details/availability/Availability";
import Spinner from "../../spinner/Spinner";
import { useApi, Response, mapSuccess, isSuccess } from "../../../api/Api";
import { BreakdownSummary, SubmissionResult } from "360";
import { useUserContext } from "../../../contexts/UserContext";

type Props = {
  reference: string;
  onEditCallerDetails: () => void;
  onEditDriverDetails: () => void;
  onEditMileage: () => void;
  onEditLocationType: () => void;
  onEditCustomer: () => void;
  onEditVehicle: () => void;
  onEditWorkRequired: () => void;
  onEditAvailability: () => void;
  onEditLocation: () => void;
  onEditAdditionalInformation: () => void;
  onEditPurchaseOrderDefectNumber: () => void;
  onEditContacts: () => void;
  onComplete: (result: SubmissionResult) => void;
  onFail: () => void;
  onCancel: () => void;
};

type SummaryResponse = {
  key: string;
  summary: BreakdownSummary;
};

const Summary = (props: Props) => {
  const {
    onCancel,
    reference,
    onEditCallerDetails,
    onEditDriverDetails,
    onEditMileage,
    onEditLocation,
    onEditLocationType,
    onEditCustomer,
    onEditVehicle,
    onEditWorkRequired,
    onEditAvailability,
    onEditAdditionalInformation,
    onEditPurchaseOrderDefectNumber,
    onEditContacts,
    onComplete,
    onFail,
  } = props;
  const [summary, setSummary] = useState<BreakdownSummary | null>(null);
  const [key, setKey] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const api = useApi();
  const user = useUserContext();

  const submitBreakdown = async (
    reference: string
  ): Promise<Response<SubmissionResult>> =>
    api.post(`api/breakdowns/${reference}/submit`, "1.0", {});

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<any>(
          `api/breakdowns/${reference}`,
          "1.1"
        );
        const mapped = mapSuccess(
          response,
          (r): SummaryResponse => ({
            key: r.key,
            summary: {
              ...r.summary,
              availability: r.summary.availability.map(
                (slot: { from: string; to: string }) => ({
                  from: new Date(slot.from),
                  to: new Date(slot.to),
                })
              ),
            },
          })
        );
        setLoading(false);

        if (isSuccess(mapped)) {
          setSummary(mapped.data.summary);
          setKey(mapped.data.key);
          return;
        }
      } catch {}

      onFail();
    })();
  }, [onFail, reference, api]);

  const handleSubmitClick = async () => {
    try {
      setLoading(true);
      const response = await submitBreakdown(reference);
      setLoading(false);

      if (isSuccess(response)) return onComplete(response.data);
    } catch {}

    onFail();
  };

  if (summary == null || key == null || loading) return <Spinner />;

  const {
    contactCaller,
    mileage,
    locationType,
    vehicle: breakdownVehicle,
    location: breakdownLocation,
    customer,
    workRequired: workRequested,
    availability,
    additionalInformation,
    purchaseOrderDefectNumber,
    contacts,
  } = summary;

  if (
    contactCaller === null ||
    locationType === null ||
    breakdownVehicle === null ||
    breakdownLocation === null ||
    customer === null ||
    workRequested === null ||
    availability === null ||
    contacts === null
  ) {
    // if the breakdown is missing this information at this stage,
    // something has gone wrong and the user should be prevented
    // from submitting the breakdown.
    onFail();
    return null;
  }

  const { latitude, longitude, location } = breakdownLocation;
  const { name, telephone, depot, driverName, driverTelephone } = contactCaller;
  const { attachedVehicleRegistration, vehicle } = breakdownVehicle;
  const { registration } = vehicle;
  const enableContacts = user.countryId === 2;

  return (
    <S.Summary>
      <S.Title>{t("Breakdown summary")}</S.Title>
      <ContactInformation
        contactName={name}
        contactTelephone={telephone}
        contactDepot={depot}
        driverName={driverName}
        driverTelephone={driverTelephone}
        endUser={""}
        showEndUser={false}
        onCallerEdit={onEditCallerDetails}
        onDriverEdit={onEditDriverDetails}
      />
      <JobOverview
        showForSummary={true}
        mileage={mileage}
        locationType={locationType.description}
        vehicleReg={registration}
        attachedVehicleReg={attachedVehicleRegistration}
        poNumber={purchaseOrderDefectNumber}
        tsuNumber={0}
        customer={customer.name}
        mileageEditClicked={onEditMileage}
        locationTypeEditClick={onEditLocationType}
        customerEditClicked={onEditCustomer}
        vehicleEditClicked={onEditVehicle}
        podnEditClicked={onEditPurchaseOrderDefectNumber}
      />
      <JobCategory icon={faTire} title={t("Work requested")}>
        {workRequested.map((wr, i) => (
          <TyreDetails
            otpKey={key}
            workRequired={wr}
            jobDetailsEditClicked={onEditWorkRequired}
            reference={reference}
            key={i}
          />
        ))}
      </JobCategory>
      <Availability
        availability={availability}
        availabilityEditClicked={onEditAvailability}
      />
      <LocationDetails
        location={location}
        latitude={latitude}
        longitude={longitude}
        LocationEditClicked={onEditLocation}
      />
      <JobCategory icon={faInfo} title={t("Additional information")}>
        <JobSubCategory onEditClicked={onEditAdditionalInformation}>
          <S.DetailContainer>
            <S.DetailContent>{additionalInformation}</S.DetailContent>
          </S.DetailContainer>
        </JobSubCategory>
      </JobCategory>
      {enableContacts && (
        <JobCategory icon={faEnvelope} title={t("Email contacts")}>
          <JobSubCategory onEditClicked={onEditContacts}>
            <S.DetailContainer>
              <S.DetailContent>
                {t("{{count}} contact(s)", { count: contacts.length })}
              </S.DetailContent>
            </S.DetailContainer>
          </JobSubCategory>
        </JobCategory>
      )}
      <S.Button onClick={handleSubmitClick}>{t("Submit")}</S.Button>
      <S.Button onClick={onCancel}>{t("Save for later or cancel")}</S.Button>
    </S.Summary>
  );
};

export default Summary;
