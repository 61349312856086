const colors = {
  lightOrange: "#ffa500",
  darkOrange: "#cc7200",
  grey1: "#707571",
  grey2: "#969b96",
  grey3: "#cdd2cd",
  grey4: "#f0f5eb",
  lightBlue: "#00a5dc",
  darkBlue: "#004eaf",
  lightGreen: "#2db928",
  darkGreen: "#057855",
  lightRed: "#ff2d37",
  extraLightRed: "#fecacd",
  white: "#fff",
  black: "#000",
  darkRed: "#cc0004",
  pastelGreen: "#ccffcc",
  pastelRed: "#ffd3b9",
  yellow: "#ffd700",
  lightYellow: "#fff099"
};

export default colors;
