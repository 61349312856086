import React, { ReactNode } from "react";
import S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
  onEditClicked?: () => void;
};

const JobSubCategory = (props: Props) => {
  const { t } = useTranslation();
  return (
    <S.JobSubCategory>
      <S.Content>{props.children}</S.Content>
      {props.onEditClicked !== undefined && (
        <S.EditButton onClick={props.onEditClicked}>
          <FontAwesomeIcon icon={faPencilAlt} />
          <span>{t("Edit")}</span>
        </S.EditButton>
      )}
    </S.JobSubCategory>
  );
};

export default JobSubCategory;
