import styled from "styled-components";

const Parent = styled.div`
  margin: 3px;
  display: flex;
  cursor: pointer;
`;

const Timing = styled.span`
  line-height: 25px;
  height: 100%;
  text-align: center;
  padding-left: 5px;
`;

export default { Parent, Timing };
