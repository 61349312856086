import React from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";
import Button from "./button/Button";
import {
  faBook,
  faTire,
  faEdit,
  faClock,
  faSearch,
  faPhone,
  faUserSecret,
  faFileSignature,
} from "@fortawesome/pro-solid-svg-icons";
import userGuide from "./user-guide.pdf";
import termsOfUse from "./terms-of-use.pdf";
import privacyStatement from "./privacy-statement.pdf";

type Props = {
  onBreakdownClick: () => void;
  onBreakdownsInProgressClick: () => void;
  onTodaysJobsClick: () => void;
  onSearchClick: () => void;
  onPhoneUsClick: () => void;
  countryId: number;
};

const Home = (props: Props) => {
  const {
    onBreakdownClick,
    onBreakdownsInProgressClick,
    onTodaysJobsClick,
    onSearchClick,
    onPhoneUsClick,
    countryId,
  } = props;
  const { t } = useTranslation();
  const loggedInToUs = countryId === 2;

  return (
    <S.Home>
      <S.Grid>
        <Button
          icon={faTire}
          onClick={onBreakdownClick}
          label={t("Log breakdown")}
        />
        <Button
          icon={faEdit}
          onClick={onBreakdownsInProgressClick}
          label={t("Continue breakdown")}
        />
        <Button
          icon={faClock}
          onClick={onTodaysJobsClick}
          label={t("Recent jobs")}
        />
        <Button
          icon={faSearch}
          onClick={onSearchClick}
          label={t("Search jobs")}
        />
        <S.Link href={userGuide} target="_blank" rel="noopener noreferrer">
          <Button icon={faBook} onClick={() => {}} label={t("User guide")} />
        </S.Link>
        {loggedInToUs && (
          <S.Link href={termsOfUse} target="_blank" rel="noopener noreferrer">
            <Button
              icon={faFileSignature}
              onClick={() => {}}
              label={t("Terms of use")}
            />
          </S.Link>
        )}
        {loggedInToUs && (
          <S.Link
            href={privacyStatement}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              icon={faUserSecret}
              onClick={() => {}}
              label={t("Privacy statement")}
            />
          </S.Link>
        )}
        <Button icon={faPhone} onClick={onPhoneUsClick} label={t("Call us")} />
      </S.Grid>
    </S.Home>
  );
};

export default Home;
