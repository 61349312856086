import React from "react";
import S from "./styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  onClick: () => void;
  label: string;
  icon: IconProp;
};

const Button = (props: Props) => {
  const { onClick, label, icon } = props;

  return (
    <S.Button onClick={onClick}>
      <S.Icon icon={icon} />
      <S.Text>{label}</S.Text>
    </S.Button>
  );
};

export default Button;
