import styled from "styled-components";

const JobsContent = styled.div`
  max-width: 700px;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const JobResults = styled.div`
  width: 100%;
`;

export default { JobsContent, Title, JobResults };
