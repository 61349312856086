import styled from "styled-components";
import colours from "../../../../../utils/colours";

const Configuration = styled.div``;

const Axle = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const AxleType = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  & span {
    padding: 5px;
    border-radius: 5px;
    background-color: ${colours.lightOrange};
  }
`;

type PositionProps = {
  hasPosition: boolean;
};

const Position = styled.div<PositionProps>`
  display: flex;
  visibility: ${props => (props.hasPosition ? "visible" : "hidden")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colours.grey3};
  height: 40px;
  width: 30px;
  border-radius: 5px;
  margin: 2px;
`;

export default { Configuration, Axle, Position, AxleType };
