import React, { useState } from "react";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import S from "./styles";
import { useApi, isSuccess } from "../../../../api/Api";
import useInterval from "../../../../utils/Interval";

type Props = {
  lastViewedNotificationId: number | null;
  onClick: () => void;
};

const NotificationIcon = (props: Props) => {
  const { lastViewedNotificationId, onClick } = props;
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const api = useApi();

  const handleIconClick = () => {
    setNotificationCount(0);
    onClick();
  };

  useInterval(
    async () => {
      try {
        const response = await api.get<number>(
          "api/notifications/count",
          "1.0",
          { lastViewedNotificationId }
        );

        if (isSuccess(response)) setNotificationCount(response.data);
      } catch {
        // if we can't retrieve the notification count, just clear it out for the moment
        setNotificationCount(0);
      }
    },
    1e3 * 60,
    true
  );

  return (
    <S.NotificationIcon>
      <S.IconWrapper onClick={handleIconClick}>
        <S.Icon icon={faBell} />
        {notificationCount > 0 && <S.Count>{notificationCount}</S.Count>}
      </S.IconWrapper>
    </S.NotificationIcon>
  );
};

export default NotificationIcon;
