const resources = {
  en: {
    translation: {
      Username: "Username",
      Password: "Password",
      Country: "Country",
      "Sign in": "Sign in",
      Cancel: "Cancel",
      Search: "Search",
      "Having trouble signing in?": "Having trouble signing in?",
      "Please contact us for assistance.": "Please contact us for assistance.",
      "You must provide a username.": "You must provide a username.",
      "You must provide a password.": "You must provide a password.",
      "You must select a country.": "You must select a country.",
      "Log breakdown": "Log breakdown",
      "Recent jobs": "Recent jobs",
      "Search jobs": "Search jobs",
      "Job Search": "Job Search",
      "User guide": "User guide",
      "Call us": "Call us",
      "If you require assistance, please click the button to phone us.":
        "If you require assistance, please click the button to phone us.",
      "If you require assistance, please select your country to phone us.":
        "Please select your country to contact us.",
      "Purchase Order/Defect Number": "Purchase Order/Defect Number",
      "Additional Information": "Additional Information",
      "Maximum length is {{len}} characters.":
        "Maximum length is {{len}} characters.",
      "Minimum length is {{len}} characters.":
        "Minimum length is {{len}} characters.",
      "Your depot is required for this contract.":
        "Your depot is required for this contract.",
      Mileage: "Mileage",
      "Missing search query.": "Missing search query.",
      "TSU Case Number": "TSU Case Number",
      "{{length}} jobs found.": "{{length}} jobs found.",
      Notifications: "Notifications",
      "Your full name": "Your full name",
      "Your telephone number": "Your telephone number",
      "Who is with the vehicle?": "Who is with the vehicle?",
      You: "You",
      "Someone else": "Someone else",
      "Their full name": "Their full name",
      "Their telephone number": "Their telephone number",
      Continue: "Continue",
      Skip: "Skip",
      "Vehicle / unit number": "Vehicle / unit number",
      "This vehicle is suspended. By continuing, you accept any costs associated with this work.":
        "This vehicle is suspended. By continuing, you accept any costs associated with this work.",
      "Getting your current location...": "Getting your current location...",
      "Your location could not be automatically retrieved at this time.":
        "Your location could not be automatically retrieved at this time.",
      "Please ensure that location services are enabled and permissions have been granted.":
        "Please ensure that location services are enabled and permissions have been granted.",
      Retry: "Retry",
      "Enter location": "Enter location",
      "Is this location correct?": "Is this location correct?",
      Yes: "Yes",
      "No - enter location": "No - enter location",
      Location: "Location",
      "Caller Details": "Caller Details",
      "Caller Name": "Caller Name",
      "Caller No": "Caller No",
      "Contact No": "Contact No",
      "End User": "End User",
      "Job History": "Job History",
      "Time Allocated": "Time Allocated",
      "Chosen Time": "Chosen Time",
      "Attending SP": "Attending SP",
      "Attending depot": "Attending depot",
      ETA: "ETA",
      "On Scene Time": "On Scene Time",
      "Tire Details": "Tire Details",
      Size: "Size",
      "Wheel Position": "Wheel Position",
      "Husbandry Requests": "Husbandry Requests",
      Images: "Images",
      "has been logged and we will advise your ETA shortly.":
        "has been logged and we will advise your ETA shortly.",
      "Please check your notifications for updates.":
        "Please check your notifications for updates.",
      "Job Number": "Job Number",
      "Field is required.": "Field is required.",
      "Replacement tire": "Replacement tire",
      "Other work": "Other work",
      Damage: "Damage",
      Other: "Other",
      "Specify damage": "Specify damage",
      "Specify reason": "Specify reason",
      "Specify work": "Specify work",
      "Work required": "Work required",
      "Removal reason": "Removal reason",
      "Tire size": "Tire size",
      "Change configuration": "Change configuration",
      "There is existing work on this position.":
        "There is existing work on this position.",
      "Add work": "Add work",
      "Change existing work": "Change existing work",
      "Remove existing work": "Remove existing work",
      "Return to configuration": "Return to configuration",
      "Vehicle type": "Vehicle type",
      "Number of axles": "Number of axles",
      "Number of drive axles": "Number of drive axles",
      "Axles twinned": "Axles twinned",
      "Is the vehicle powered?": "Is the vehicle powered?",
      No: "No",
      DisplayDate: "{{date, P p}}",
      "Does the vehicle have a powered/driven axle?":
        "Does the vehicle have a powered/driven axle?",
      "If you are submitting multiple jobs at the same location, please note this above.":
        "If you are submitting multiple jobs at the same location, please note this above.",
      "Attached vehicle": "Attached vehicle",
      Customer: "Customer",
      "Please ensure you are in a safe location before you continue.":
        "Please ensure you are in a safe location before you continue.",
      Errors: "Errors",
      Error: "Error",
      "A serious error has been encountered whilst processing your breakdown. Please contact us for further assistance.":
        "A serious error has been encountered whilst processing your breakdown. Please contact us for further assistance.",
      "Mileage is required for this contract. You may continue if this is unavailable.":
        "Mileage is required for this contract. You may continue if this is unavailable.",
      "security-auth-incorrect-details":
        "You have provided an incorrect username, password or country.",
      "security-auth-account-disabled":
        "Your account has been disabled. Please contact us for assistance.",
      "security-auth-account-locked":
        "Your account has been temporarily locked. Please try again later.",
      "security-auth-account-not-360-smart-enabled":
        "Your account does not have access to 360\u{B0}Smart. Please contact us for assistance.",
      "breakdown-purchase-order-defect-number-failed-validation":
        "You have submitted an invalid purchase order/defect number. Please try again or contact us for assistance.",
      "Failed to load required data. Please reload 360\u{B0}Smart and try again.":
        "Failed to load required data. Please reload 360\u{B0}Smart and try again.",
      "Login failed. Please try again.": "Login failed. Please try again.",
      AVAILABILITY: "{{time, HH:mm}}",
      AVAILABILITY_WITH_DATE: "{{time, HH:mm (P)}}",
      AVAILABILITY_DATE: "{{date, P}}",
      "Breakdown summary": "Breakdown summary",
      "Work requested": "Work requested",
      "Additional information": "Additional information",
      Availability: "Availability",
      "Caller details": "Caller details",
      "Contact name": "Contact name",
      "Contact telephone": "Contact telephone",
      "Driver name": "Driver name",
      "Driver telephone": "Driver telephone",
      "Job overview": "Job overview",
      "TSU number": "TSU number",
      "Purchase order / defect number": "Purchase order / defect number",
      "Location type": "Location type",
      Edit: "Edit",
      Coordinates: "Coordinates",
      Position: "Position",
      Husbandry: "Husbandry",
      "Hide images": "Hide images",
      "Show images": "Show images",
      View: "View",
      "You have no recent jobs.": "You have no recent jobs.",
      "You must complete one field.": "You must complete one field.",
      "has been partially logged. We may be in contact shortly to confirm details.":
        "has been partially logged. We may be in contact shortly to confirm details.",
      Home: "Home",
      "You must add work to at least one position.":
        "You must add work to at least one position.",
      "Warning: You will lose existing work on positions if you change the configuration.":
        "Warning: You will lose existing work on positions if you change the configuration.",
      Roadside: "Roadside",
      "Servicing dealer": "Servicing dealer",
      "At site/yard": "At site/yard",
      NOTIFICATION_NOFORMAT: "Notification data could not be displayed.",
      NOTIFICATION_ALLOCATED_V1:
        "Your job has been allocated to {{serviceProvider}} and your ETA is {{eta, P p}}.",
      NOTIFICATION_REVISEDETA_V1:
        "Your service provider has been delayed and your new ETA is {{revisedEta, P p}}.",
      NOTIFICATION_ENROUTE_V1:
        "Your service provider reported that they were en route at {{enRoute, P p}}.",
      NOTIFICATION_ONSCENE_V1:
        "Your service provider reported that they were on scene at {{onScene, P p}}.",
      NOTIFICATION_COMPLETED_V1:
        "Your service provider reported that the work was completed at {{completed, P p}}.",
      NOTIFICATION_REALLOCATED_V1:
        "Your job has been re-allocated to {{serviceProvider}} and your ETA is {{eta, P p}}.",
      "You have no new notifications.": "You have no new notifications.",
      "Your depot": "Your depot",
      "Contact depot": "Contact depot",
      "You have selected position {{position}}.":
        "You have selected position {{position}}.",
      "The selected tire size does not match our history for this vehicle.":
        "The selected tire size does not match our history for this vehicle.",
      Back: "Back",
      "Search...": "Search...",
      "No configurations match your selected criteria. Please check your criteria and contact us if a matching configuration cannot be found.":
        "No configurations match your selected criteria. Please check your criteria and contact us if a matching configuration cannot be found.",
      "En Route": "En Route",
      Completed: "Completed",
      Documents: "Documents",
      DOCUMENT_EFFECTIVE: "Effective {{effective, P}}",
      DOCUMENT_EXPIRY: "Expiry {{expiry, P}}",
      "Customer documents": "Customer documents",
      "You are limited to only {{count}} photos.":
        "You are limited to only {{count}} photos.",
      "Image {{index}}": "Image {{index}}",
      "Date must not be in the future.": "Date must not be in the future.",
      "Return to job": "Return to job",
      "Husbandry requested": "Husbandry requested",
      "Tire(s) requested": "Tire(s) requested",
      "No husbandry requested": "No husbandry requested",
      "No tires requested": "No tires requested",
      "Privacy statement": "Privacy statement",
      "Terms of use": "Terms of use",
      "Please be aware that submitting high quality images may result in significant data usage. Methods for reducing image quality will vary by device so please check with your device manufacturer.":
        "Please be aware that submitting high quality images may result in significant data usage. Methods for reducing image quality will vary by device so please check with your device manufacturer.",
      "This customer requires that a photo be taken for each position that requires work.":
        "This customer requires that a photo be taken for each position that requires work.",
      "The following positions could not be matched to a photo. Please ensure that a photo has been provided before continuing.":
        "The following positions could not be matched to a photo. Please ensure that a photo has been provided before continuing.",
      "Return to photos": "Return to photos",
      "Coming soon.": "Coming soon.",
      "Your session has expired. Please log in to continue.":
        "Your session has expired. Please log in to continue.",
      "If you cancel the breakdown, all progress will be lost and the breakdown will not be received by our agents.":
        "If you cancel the breakdown, all progress will be lost and the breakdown will not be received by our agents.",
      "If you save the breakdown for later, you will have 24 hours to provide the remaining details of the breakdown and submit it to our agents.":
        "If you save the breakdown for later, you will have 24 hours to provide the remaining details of the breakdown and submit it to our agents.",
      "Cancel breakdown": "Cancel breakdown",
      "Save breakdown for later": "Save breakdown for later",
      "Return to breakdown": "Return to breakdown",
      "Continue breakdown": "Continue breakdown",
      "You have no breakdowns in progress.":
        "You have no breakdowns in progress.",
      "Breakdowns in progress": "Breakdowns in progress",
      "Please provide the following information for the {{position}} position.":
        "Please provide the following information for the {{position}} position.",
      "Fitted make": "Fitted make",
      "Fitted pattern": "Fitted pattern",
      "Removed make": "Removed make",
      "Removed pattern": "Removed pattern",
      "Removed tread depth": "Removed tread depth",
      "Save for later or cancel": "Save for later or cancel",
      "allocated-job-incorrect-state":
        "The job is no longer in the correct state for this action. Please try again.",
      "Blow out": "Blow out",
      "BO Blow Out": "BO Blow out",
      "Cut to cords": "Cut to cords",
      "CTC Cut To Cords": "CTC Cut To Cords",
      "Flat spot": "Flat spot",
      "FS Flat Spot": "FS Flat Spot",
      "Run flat": "Run flat",
      "RF Run Flat": "RF Run Flat",
      "Sidewall damage": "Sidewall damage",
      "SD Sidewall Damage": "SD Sidewall Damage",
      "Tread damage": "Tread damage",
      "TD Tread Damage": "TD Tread Damage",
      Puncture: "Puncture",
      "PC Puncture": "PC Puncture",
      "Reason for cancellation": "Reason for cancellation",
      "Broken down": "Broken down",
      "Stuck in traffic": "Stuck in traffic",
      "Stuck attending other job": "Stuck attending other job",
      "Incorrect tyre taken": "Incorrect tyre taken",
      "Too busy with other jobs": "Too busy with other jobs",
      "Tyre Size": "Tyre Size",
      "Your jobs only": "Your jobs only",
      Husbandries: "Husbandries",
      Expires: "Expires",
      Description: "Description",
      "Must be a valid email address.": "Must be a valid email address.",
      "Email contacts": "Email contacts",
      Add: "Add",
      "{{count}} contact(s)": "{{count}} contact(s)",
      "Please add any email addresses to which you would like job updates to be sent.":
        "Please add any email addresses to which you would like job updates to be sent.",
      "Get summary PDF": "Get summary PDF",
    },
  },
  fr: {
    translation: {
      Username: "Nom d'utilisateur",
      Password: "Mot de passe",
      Country: "Pays",
      "Sign in": "Connexion",
      Cancel: "Annuler",
      Search: "Recherche",
      "Having trouble signing in?": "Des problèmes de connexion?",
      "Please contact us for assistance.":
        "Veuillez communiquer avec nous pour obtenir de l'aide.",
      "You must provide a username.": "Vous devez entrer un nom d'utilisateur.",
      "You must provide a password.": "Vous devez entrer un mot de passe.",
      "You must select a country.": "Vous devez sélectionner un pays.",
      "Log breakdown": "Signaler une panne",
      "Recent jobs": "Emplois récentes",
      "Search jobs": "Rechercher une emplois",
      "Job Search": "Recherche d'une emplois",
      "User guide": "Guide de l'utilisateur",
      "Call us": "Appelez-nous",
      "If you require assistance, please click the button to phone us.":
        "Si vous avez besoin d'aide, veuillez cliquer sur le bouton pour nous téléphoner.",
      "If you require assistance, please select your country to phone us.":
        "Veuillez sélectionner votre pays pour nous contacter.",
      "Purchase Order/Defect Number": "Bon de commande / numéro de défaut",
      "Additional Information": "Information supplémentaire",
      "Maximum length is {{len}} characters.":
        "La longueur maximale est de {{len}} caractères",
      "Minimum length is {{len}} characters.":
        "La longueur minimale est de {{len}} caractères",
      "Your depot is required for this contract.":
        "Your depot is required for this contract.",
      Mileage: "Kilométrage",
      "Missing search query.": "Requête de recherche manquante.",
      "TSU Case Number": "Numéro de dossier TSU",
      "{{length}} jobs found.": "{{length}} emplois trouvés.",
      Notifications: "Notifications",
      "Your full name": "Votre nom complet",
      "Your telephone number": "Votre numéro de téléphone",
      "Who is with the vehicle?": "Qui est avec le véhicule?",
      You: "Vous",
      "Someone else": "Quelqu'un d'autre",
      "Their full name": "Son nom complet",
      "Their telephone number": "Son numéro de téléphone",
      Continue: "Continuer",
      Skip: "Sauter",
      "Vehicle / unit number": "Véhicule / numéro d'unité",
      "This vehicle is suspended. By continuing, you accept any costs associated with this work.":
        "Ce véhicule est suspendu. En continuant, vous acceptez les frais associés à cette emplois.",
      "Getting your current location...":
        "Obtention de votre position actuelle...",
      "Your location could not be automatically retrieved at this time.":
        "À ce moment-ci, votre emplacement actuel ne peut pas être déterminé.",
      "Please ensure that location services are enabled and permissions have been granted.":
        "Veuillez vous assurer que le service de localisation est activé et que vous avez accordé les permissions nécessaires.",
      Retry: "Réessayez",
      "Enter location": "Entrez votre emplacement",
      "Is this location correct?": "Cet emplacement est-il correct?",
      Yes: "Oui",
      "No - enter location": "Non - entrez l'emplacement",
      Location: "Emplacement",
      "Caller Details": "Détails de l'appelant",
      "Caller Name": "Nom de l'appelant",
      "Caller No": "Numero de l'appelant",
      "Contact No": "Numéro de contact",
      "End User": "Utilisateur final",
      "Job History": "Historique des emplois",
      "Time Allocated": "Temps alloué",
      "Chosen Time": "Heure choisie",
      "Attending SP": "Participer au SP",
      "Attending depot": "Emplacement du détaillant d'entretien",
      ETA: "Heure d'arrivée estimée",
      "On Scene Time": "heure d'arrivée",
      "Tire Details": "Détails des pneus",
      Size: "Dimensions",
      "Wheel Position": "Position de la roue",
      "Husbandry Requests": "Informations sur la réparation des pneus",
      Images: "Photos",
      "has been logged and we will advise your ETA shortly.":
        "a été enregistré et nous vous informerons sous peu de votre heure d'arrivée estimée",
      "Please check your notifications for updates.":
        "Veuillez surveiller vos notifications pour obtenir des mises à jour.",
      "Job Number": "Numéro de emplois",
      "Field is required.": "Champs requis",
      "Replacement tire": "Pneu de remplacement",
      "Other work": "Autre travail",
      Damage: "Dommage",
      Other: "Autre",
      "Specify damage": "Précisez les dommages",
      "Specify reason": "Précisez la raison",
      "Specify work": "Spécifiez le travail à faire",
      "Work required": "Travaux requis",
      "Removal reason": "Raison du retrait des pneus",
      "Tire size": "Dimension du pneu",
      "Tyre Size": "Dimensions",
      "Change configuration": "Modifier la configuration",
      "There is existing work on this position.":
        "Des réparations ont été effectuées à cette position.",
      "Add work": "Ajouter du travail",
      "Change existing work": "Modifier le travail existant",
      "Remove existing work": "Supprimer le travail existant",
      "Return to configuration": "Retourner à la configuration",
      "Vehicle type": "Type de véhicule",
      "Number of axles": "Nombre d'essieux du véhicule",
      "Number of drive axles": "Nombre d'essieux moteurs",
      "Axles twinned": "Essieux jumelés",
      "Is the vehicle powered?":
        "Le véhicule peut-il se déplacer de lui-même (véhicule à moteur)?",
      No: "Non",
      DisplayDate: "{{date, P p}}",
      "Does the vehicle have a powered/driven axle?":
        "Le véhicule a-t-il un essieu moteur ?",
      "If you are submitting multiple jobs at the same location, please note this above.":
        "Si vous soumettez plusieurs emplois pour le même endroit, veuillez le noter ci-dessus.",
      "Attached vehicle": "Véhicule lié au contrat",
      Customer: "Client",
      "Please ensure you are in a safe location before you continue.":
        "Veuillez vous assurer que vous êtes dans un endroit sûr avant de continuer.",
      Errors: "Erreurs",
      Error: "Erreur",
      "A serious error has been encountered whilst processing your breakdown. Please contact us for further assistance.":
        "Une erreur grave s'est produite lors du traitement de votre panne. Veuillez nous contacter pour obtenir de l'aide.",
      "Mileage is required for this contract. You may continue if this is unavailable.":
        "Le kilométrage est requis pour ce contrat. Vous pouvez continuer si cela n'est pas disponible.",
      "security-auth-incorrect-details":
        "Le nom d'utilisateur, le mot de passe ou le pays est incorrect.",
      "security-auth-account-disabled":
        "Votre compte a été désactivé. Veuillez nous contacter pour obtenir de l'aide.",
      "security-auth-account-locked":
        "Votre compte a été temporairement verrouillé. Veuillez réessayer plus tard.",
      "security-auth-account-not-360-smart-enabled":
        "Votre compte n'a pas accès à 360\u{B0}Smart. Veuillez nous contacter pour obtenir de l'aide.",
      "breakdown-purchase-order-defect-number-failed-validation":
        "Vous avez soumis un bon de commande /numéro de défaut non valide. Veuillez réessayer ou nous contacter pour obtenir de l'aide.",
      "Failed to load required data. Please reload 360\u{B0}Smart and try again.":
        "Échec du chargement des données requises. Veuillez recharger 360\u{B0}Smart et réessayer.",
      "Login failed. Please try again.":
        "Échec de la connexion. Veuillez réessayer.",
      AVAILABILITY: "{{time, HH:mm}}",
      AVAILABILITY_WITH_DATE: "{{time, HH:mm (P)}}",
      AVAILABILITY_DATE: "{{date, P}}",
      "Breakdown summary": "Re’sume’ de la panne",
      "Work requested": "Travail demandé",
      "Additional information": "Information supplémentaire",
      Availability: "Disponibilité",
      "Caller details": "Détails de l'appelant",
      "Contact name": "Nom du contact",
      "Contact telephone": "Téléphone  du contact",
      "Driver name": "Nom du conducteur",
      "Driver telephone": "Téléphone du conducteur",
      "Job overview": "Aperçu de la emplois",
      "TSU number": "Numéro TSU",
      "Purchase order / defect number": "Bon de commande / numéro de défaut",
      "Location type": "Type d'emplacement",
      Edit: "Éditer",
      Coordinates: "Coordonnées",
      Position: "Position",
      Husbandry: "Réparation des pneus",
      "Hide images": "Masquer les images",
      "Show images": "Afficher les images",
      View: "Vue",
      "You have no recent jobs.":
        "Vous n'avez aucune emplois récente à votre dossier.",
      "You must complete one field.": "Vous devez remplir une case",
      "has been partially logged. We may be in contact shortly to confirm details.":
        "a été entrée partiellement. Nous pourrions bientôt vous contacter pour obtenir plus de détails.",
      Home: "Accueil",
      "You must add work to at least one position.":
        "Vous devez ajouter une tâcje à au moins une position.",
      "Warning:  You will lose existing work on positions if you change the configuration.":
        "Avertissement : si vous modifiez la configuration, vous perdrez les emplois existantes sur les positions.",
      Roadside: "Bord de la route",
      "Servicing dealer": "Détaillant de service",
      "At site/yard": "Sur place/dans la cour",
      NOTIFICATION_NOFORMAT:
        "Les données de notification n'ont pas pu être affichées.",
      NOTIFICATION_ALLOCATED_V1:
        "Votre emplois a été attribuée à {{serviceProvider}}, qui devrait arriver à {{eta, P p}}.",
      NOTIFICATION_REVISEDETA_V1:
        "Votre fournisseur de services a été retardé. Il devrait maintenant arriver à {{revisedEta, P p}}",
      NOTIFICATION_ENROUTE_V1:
        "Votre fournisseur de services a signalé qu'il est en route vers {{enRoute, P p}}.",
      NOTIFICATION_ONSCENE_V1:
        "Votre fournisseur de services a signalé qu'il est sur les lieux à {{onScene, P p}}.",
      NOTIFICATION_COMPLETED_V1:
        "Votre fournisseur de services a signalé qu'il a terminé la emplois à {{completed, P p}}.",
      NOTIFICATION_REALLOCATED_V1:
        "Votre emplois a été réaffectée à {{serviceProvider}}, qui devrait arriver à {{eta, P p}}.",
      "You have no new notifications.":
        "Vous n'avez aucune nouvelle notification.",
      "Your depot": "Votre dépôt",
      "Contact depot": "Contactez le dépôt",
      "You have selected position {{position}}.":
        "Vous avez sélectionné la position {{position}}.",
      "The selected tire size does not match our history for this vehicle.":
        "La dimension de pneu sélectionnée ne correspond pas à notre historique pour ce véhicule.",
      Back: "Retour",
      "Search...": "Chercher…",
      "No configurations match your selected criteria. Please check your criteria and contact us if a matching configuration cannot be found.":
        "La dimension de pneu sélectionnée ne correspond pas à notre historique pour ce véhicule. Si vous ne pouvez pas trouver une configuration appropriée, veuillez nous contacter.",
      "En Route": "En Route",
      Completed: "Terminé",
      Documents: "Documents",
      DOCUMENT_EFFECTIVE: "En vigueur {{effective, P}}",
      DOCUMENT_EXPIRY: "Expiration {{expiry, P}}",
      "Customer documents": "Documents clients",
      "You are limited to only {{count}} photos.":
        "Vous ne pouvez pas envoyer plus de  {{count}} photos.",
      "Image {{index}}": "Image {{index}}",
      "Date must not be in the future.":
        "La date ne doit pas être dans le futur.",
      "Return to job": "Retour à la emplois",
      "Husbandry requested": "Réparation de pneu demandée",
      "Tire(s) requested": "Pneu (s) demandé (s)",
      "No husbandry requested": "Aucune réparation demandée",
      "No tires requested": "Aucun pneu demandé",
      "Privacy statement": "Déclaration de confidentialité",
      "Terms of use": "Conditions d'utilisation",
      "Please be aware that submitting high quality images may result in significant data usage. Methods for reducing image quality will vary by device so please check with your device manufacturer.":
        "Veuillez noter que la soumission d'images de haute qualité peut entraîner une utilisation importante des données. Les méthodes de réduction de la qualité d'image varient selon l'appareil, veuillez donc vérifier auprès du fabricant de votre appareil.",
      "This customer requires that a photo be taken for each position that requires work.":
        "Ce client exige qu'une photo soit prise pour chaque position nécessitant des travaux",
      "The following positions could not be matched to a photo. Please ensure that a photo has been provided before continuing.":
        "Il n'y a pas de photo associée aux positions suivantes. Veuillez vous assurer qu'une photo a été fournie avant de continuer.",
      "Return to photos": "Retour aux photos",
      "Coming soon.": "Bientôt disponible.",
      "Your session has expired. Please log in to continue.":
        "Votre session a expiré. Veuillez vous connecter pour continuer.",
      "If you cancel the breakdown, all progress will be lost and the breakdown will not be received by our agents.":
        "Si vous annulez la demande, l'information entrée sera perdue et nos agents ne recevront pas le signalement.",
      "If you save the breakdown for later, you will have 24 hours to provide the remaining details of the breakdown and submit it to our agents.":
        "Si vous enregistrez le signalement pour plus tard, vous avez 24 heures pour fournir le reste des détails de la panne et soumettre le signalement à nos agents.",
      "Cancel breakdown": "Annuler la panne",
      "Save breakdown for later": "Enregistrer le signalement pour plus tard",
      "Return to breakdown": "Retour au signalement",
      "Continue breakdown": "Poursuivre le signalement",
      "You have no breakdowns in progress.":
        "Vous n'avez aucun signalement en cours.",
      "Breakdowns in progress": "Signalements en cours",
      "Please provide the following information for the {{position}} position.":
        "Veuillez fournir les informations suivantes pour la position {{position}}.",
      "Fitted make": "Marque du pneu posé",
      "Fitted pattern": "Motif de bande de roulement du pneu posé",
      "Removed make": "Marque du pneu retiré",
      "Removed pattern": "Motif de bande de roulement du pneu retiré",
      "Removed tread depth":
        "Épaisseur de la bande de roulement du pneu retiré",
      "Save for later or cancel": "Sauvegarder pour plus tard ou annuler",
      "Allocated jobs": "Emplois alloués",
      "All Depots": "Tous les dépôts",
      "All Customers": "Tous les clients",
      "Job Details": "Détails de l'emploi",
      "Job Management": "Gestion des emplois",
      Vehicle: "Véhicule",
      Client: "Client",
      Photos: "Photos",
      "Job Cancelled": "Emploi annulé",
      "Return to jobs": "Retour aux emplois",
      "Vehicle Registration": "Enregistrement du véhicule",
      "Job Date": "Date de l'emploi",
      "Job Summary": "Résumé du travail",
      "Contact Information": "Coordonnées",
      "Driver Name": "Nom du conducteur",
      "Driver Number": "Numéro du conducteur",
      "Tire Size": "Dimensions des pneus",
      Make: "Fabrication",
      Pattern: "Dessin",
      "Removal Reason": "Motif du retrait",
      Timing: "Heure",
      Arrived: "Arrivé",
      "Work Complete": "Travail terminé",
      "Returned Home": "Retour à la maison",
      "Work Instructions": "Instructions de travail",
      "Attending Depot": "Prestataire de service",
      STEER: "Direction",
      DRIVE: "Entraînement",
      TRAILER: "Roulement libre",
      "Blow out": "Éclatement",
      "BO Blow Out": "Éclatement",
      "Cut to cords": "Cordes visibles",
      "CTC Cut To Cords": "Cordes visibles",
      "Flat spot": "Usure par aplatissement",
      "FS Flat Spot": "Usure par aplatissement",
      "Run flat": "Roulage à plat",
      "RF Run Flat": "Roulage à plat",
      "Sidewall damage": "Dégradation des flancs",
      "SD Sidewall Damage": "Dégradation des flancs",
      "Tread damage": "Dégradation de la bande de roulement",
      "TD Tread Damage": "Dégradation de la bande de roulement",
      Puncture: "Crevaison",
      "PC Puncture": "Crevaison",
      "Upload Photos": "Télécharger des photos",
      Confirm: "Confirmer",
      ASAP: "Dès que possible",
      Submit: "Soumettre",
      "Select Availability": "Sélectionner la disponibilité",
      "Available All Day": "Disponible toute la journée",
      "Pick Availability": "Choisir la disponibilité",
      "Next Day": "Jour suivant",
      "Previous Day": "Jour précédent",
      Repair: "Réparation",
      "Pressure check": "Contrôle de la pression",
      Retorque: "Resserrer",
      "Turn on rim": "Tourner sur la jante",
      Rebalance: "Rééquilibrage",
      Regroove: "Recanneler",
      "Wheel alignment": "Parallélisme des roues",
      Mismatch: "Asymétrie",
      "Uneven wear": "Usure irrégulière",
      Worn: "Usé",
      "Wrong speed/load rating": "Mauvaise gamme de vitesse/de charge",
      "Attached Vehicle": "Véhicule attaché",
      Timings: "Le Minutage",
      "Close Out Instructions": "Instructions de clôture",
      "Return to Menu": "Retourner au menu",
      "No Photos Uploaded": "Aucune photo téléchargée",
      "Add Photo": "Ajouter une photo",
      "En Route Time": "Temps en route",
      "Time Arrived": "Heure d'arrivée",
      "Failure description": "Description de l'échec",
      "Wheel Security": "Sécurité des roues",
      "Wheel(s) Removed?": "Roue(s) retirée(s)?",
      "Please Select ...": "Veuillez sélectionner ...",
      "Caller Number": "Numéro de l’appelant",
      "Torque Setting": "Réglage du couple",
      "Wrench Number": "Numéro de la clé",
      "Retorque Carried Out?": "Resserrage effectué?",
      "Info Left With Customer": "Renseignements donnés au client",
      "Torque Tag": "Étiquette de couple",
      "Written on jobsheet": "Écrit sur la fiche de travail",
      "Van / Car": "Camionnette / Voiture",
      "Tag Number": "Numéro d’étiquette",
      LIFT: "Essieu relevable",
      "DOT Not Visible": "DOT non visible",
      "Out Of Date": "Date dépassée",
      Twinning: "Jumelage",
      "Reason for cancellation": "Raison pour l'annulation",
      "Broken down": "En panne",
      "Stuck in traffic": "Coincé dans les embouteillages",
      "Stuck attending other job": "Coincé à un autre travail",
      "Incorrect tyre taken": "Mauvais pneu pris",
      "Too busy with other jobs": "Trop occupé avec d'autres pannes",
      "Your jobs only": "Vos emplois uniquement",
      Husbandries: "Réparation",
      "Get summary PDF": "Get summary PDF",
    },
  },
  es: {
    translation: {
      Username: "Nombre de usuario",
      Password: "Contraseña",
      Country: "País",
      "Sign in": "Iniciar sesión",
      Cancel: "Cancelar",
      Search: "Buscar",
      "Having trouble signing in?": "¿Tiene problemas para iniciar sesión?",
      "Please contact us for assistance.":
        "Póngase en contacto con nosotros para obtener ayuda.",
      "You must provide a username.": "Debes proporcionar un nombre de usuario",
      "You must provide a password.": "Debes proporcionar una contraseña",
      "You must select a country.": "Debe seleccionar un país.",
      "Log breakdown": "Iniciando una nueva solicitud de servicio",
      "Recent jobs": "Trabajos recientes",
      "Search jobs": "Buscar trabajos",
      "Job Search": "Búsqueda de trabajos",
      "User guide": "Guía de usuario",
      "Call us": "Llámanos",
      "If you require assistance, please click the button to phone us.":
        "Si necesita ayuda, haga clic en el botón para llamarnos por teléfono.",
      "If you require assistance, please select your country to phone us.":
        "Seleccione su país para ponerse en contacto con nosotros.",
      "Purchase Order/Defect Number": "Número de orden de compra/falla",
      "Additional Information": "Información adicional",
      "Maximum length is {{len}} characters.":
        "La longitud máxima es de {{len}} caracteres.",
      "Minimum length is {{len}} characters.":
        "La longitud mínima es de {{len}} caracteres.",
      "Your depot is required for this contract.":
        "Your depot is required for this contract.",
      Mileage: "Kilometraje",
      "Missing search query.": "Falta la consulta de búsqueda.",
      "TSU Case Number":
        "Número de caso de la unidad de asistencia de neumáticos",
      "{{length}} jobs found.": "Se han encontrado {{length}} trabajos.",
      Notifications: "Notificaciones",
      "Your full name": "Su nombre completo",
      "Your telephone number": "Su número de teléfono",
      "Who is with the vehicle?": "¿Quién se encuentra con el vehículo?",
      You: "Usted",
      "Someone else": "Otra persona",
      "Their full name": "Nombre completo de la persona",
      "Their telephone number": "Número de teléfono de la persona",
      Continue: "Continuar",
      Skip: "Omitir",
      "Vehicle / unit number": "Numero de vehículo/unidad",
      "This vehicle is suspended. By continuing, you accept any costs associated with this work.":
        "Este vehículo está suspendido. Si decide continuar, acepta todos los costos asociados con este trabajo.",
      "Getting your current location...": "Obteniendo su ubicación actual...",
      "Your location could not be automatically retrieved at this time.":
        "No se pudo recuperar automáticamente su ubicación en este momento.",
      "Please ensure that location services are enabled and permissions have been granted.":
        "Asegúrese de que los servicios de ubicación estén habilitados y de que se hayan otorgado los permisos.",
      Retry: "Volver a intentar",
      "Enter location": "Introducir ubicación",
      "Is this location correct?": "¿Es correcta esta ubicación?",
      Yes: "Sí",
      "No - enter location": "No; introducir ubicación",
      Location: "Ubicación",
      "Caller Details": "Datos de la persona que llama",
      "Caller Name": "Nombre de la persona que llama",
      "Caller No": "N.º de la persona que llama",
      "Contact No": "N.º de contacto",
      "End User": "Usuario final",
      "Job History": "Historial de trabajos",
      "Time Allocated": "Tiempo asignado",
      "Chosen Time": "Hora elegida",
      "Attending SP": "Concesionario de reparación",
      ETA: "Hora de llegada estimada",
      "On Scene Time": "Hora de llegada al lugar",
      "Tire Details": "Detalles de los neumáticos",
      Size: "Tamaño",
      "Wheel Position": "Posición de las ruedas",
      "Husbandry Requests": "Solicitudes de reparación",
      Images: "Imágenes",
      "has been logged and we will advise your ETA shortly.":
        "Su caso se ha registrado y le informaremos la hora de llegada estimada en breve",
      "Please check your notifications for updates.":
        "Revise las notificaciones para ver si hay actualizaciones",
      "Job Number": "Número de Trabajo",
      "Field is required.": "El campo es obligatorio",
      "Replacement tire": "Neumático de repuesto",
      "Other work": "Otro trabajo",
      Damage: "Daño",
      Other: "Otro",
      "Specify damage": "Especifique el daño",
      "Specify reason": "Especificar el motivo",
      "Specify work": "Especificar el trabajo",
      "Work required": "Trabajo necesario",
      "Removal reason": "Motivo del desmontaje",
      "Tire size": "Tamaño de los neumáticos",
      "Tyre Size": "Tamaño de los neumáticos",
      "Change configuration": "Cambiar la configuración",
      "There is existing work on this position.":
        "Ya hay trabajo en esta posición.",
      "Add work": "Agregar trabajo",
      "Change existing work": "Cambiar el trabajo existente",
      "Remove existing work": "Eliminar el trabajo existente",
      "Return to configuration": "Volver a la configuración",
      "Vehicle type": "Tipo de vehículo",
      "Number of axles": "Cantidad de ejes",
      "Number of drive axles": "Cantidad de ejes motrices",
      "Axles twinned": "Ejes gemelos",
      "Is the vehicle powered?": "¿Está el vehículo encendido?",
      No: "No",
      DisplayDate: "{{date, P p}}",
      "Does the vehicle have a powered/driven axle?":
        "¿Tiene el vehículo un eje accionado/conducido?",
      "If you are submitting multiple jobs at the same location, please note this above.":
        "Si presentará varios trabajos en el mismo lugar, indíquelo arriba.",
      "Attached vehicle": "Vehículo acoplado",
      Customer: "Cliente",
      "Please ensure you are in a safe location before you continue.":
        "Asegúrese de que se encuentra en un lugar seguro antes de continuar.",
      Errors: "Errores",
      Error: "Error",
      "A serious error has been encountered whilst processing your breakdown. Please contact us for further assistance.":
        "Se ha producido un error grave al procesar su avería. Póngase en contacto con nosotros para obtener más ayuda.",
      "Mileage is required for this contract. You may continue if this is unavailable.":
        "Es necesario conocer el kilometraje para este contrato. Puede continuar si esto no está disponible.",
      "security-auth-incorrect-details":
        "Ha proporcionado un nombre de usuario, una contraseña o un país incorrectos.",
      "security-auth-account-disabled":
        "Su cuenta se ha desactivado. Póngase en contacto con nosotros para obtener ayuda.",
      "security-auth-account-locked":
        "Su cuenta se ha bloqueado temporalmente. Vuelva a intentarlo más tarde.",
      "security-auth-account-not-360-smart-enabled":
        "Su cuenta no tiene acceso a 360\u{B0}Smart. Póngase en contacto con nosotros para obtener ayuda.",
      "breakdown-purchase-order-defect-number-failed-validation":
        "Ha presentado un número de orden de compra/falla no válido. Vuelva a intentarlo o póngase en contacto con nosotros para obtener ayuda.",
      "Failed to load required data. Please reload 360\u{B0}Smart and try again.":
        "No se han podido cargar los datos necesarios. Vuelva a cargar 360\u{B0}Smart e inténtelo nuevamente.",
      "Login failed. Please try again.":
        "Error de inicio de sesión. Vuelva a intentarlo.",
      AVAILABILITY: "{{time, HH:mm}}",
      AVAILABILITY_WITH_DATE: "{{time, HH:mm (P)}}",
      AVAILABILITY_DATE: "{{date, P}}",
      "Breakdown summary": "Resumen de la avería",
      "Work requested": "Trabajo solicitado",
      "Additional information": "Información adicional",
      Availability: "Disponibilidad",
      "Caller details": "Datos de la persona que llama",
      "Contact name": "Nombre del contacto",
      "Contact telephone": "Teléfono de contacto",
      "Driver name": "Nombre del conductor",
      "Driver telephone": "Teléfono del conductor",
      "Job overview": "Descripción general del trabajo",
      "TSU number": "Número de la unidad de asistencia de neumáticos",
      "Purchase order / defect number": "Número de orden de compra/falla",
      "Location type": "Tipo de locación",
      Edit: "Editar",
      Coordinates: "Coordenadas",
      Position: "Posición",
      Husbandry: "Gestión",
      "Hide images": "Ocultar imágenes",
      "Show images": "Mostrar imágenes",
      View: "Ver",
      "You have no recent jobs.": "No tienes trabajos recientes",
      "You must complete one field.": "Debe completar un campo.",
      "has been partially logged. We may be in contact shortly to confirm details.":
        "Su caso se ha registrado parcialmente. Es posible que nos pongamos en contacto con usted en breve para confirmar los detalles.",
      Home: "Inicio",
      "You must add work to at least one position.":
        "Debe agregar trabajo al menos a una posición.",
      "Warning:  You will lose existing work on positions if you change the configuration.":
        "Advertencia: Perderá el trabajo existente en las posiciones si cambia la configuración.",
      Roadside: "En carretera",
      "Servicing dealer": "Concesionario de reparación",
      "At site/yard": "En el sitio/depósito",
      NOTIFICATION_NOFORMAT:
        "No se han podido mostrar los datos de la notificación.",
      NOTIFICATION_ALLOCATED_V1:
        "Su trabajo se ha asignado a {{serviceProvider}} y la hora de llegada estimada es {{eta, P p}}.",
      NOTIFICATION_REVISEDETA_V1:
        "Su proveedor de servicios se ha demorado y la nueva hora de llegada estimada es {{revisedEta, P p}}.",
      NOTIFICATION_ENROUTE_V1:
        "Su proveedor de servicios informó que estaban en camino en {{enRoute, P p}}.",
      NOTIFICATION_ONSCENE_V1:
        "Su proveedor de servicios informó que estaban en el lugar en {{onScene, P p}}.",
      NOTIFICATION_COMPLETED_V1:
        "Su proveedor de servicios informó que el trabajo se completó en {{completed, P p}}.",
      NOTIFICATION_REALLOCATED_V1:
        "Su trabajo se ha reasignado a {{serviceProvider}} y la hora de llegada estimada es {{eta, P p}}.",
      "You have no new notifications.": "No tienes nueva notificaciones.",
      "Your depot": "Su depósito",
      "Contact depot": "Póngase en contacto con el depósito",
      "You have selected position {{position}}.":
        "Ha seleccionado la posición {{position}}.",
      "The selected tire size does not match our history for this vehicle.":
        "El tamaño de neumático seleccionado no coincide con nuestro historial de este vehículo.",
      Back: "Volver",
      "Search...": "Buscar...",
      "No configurations match your selected criteria. Please check your criteria and contact us if a matching configuration cannot be found.":
        "No hay configuraciones que coincidan con los criterios seleccionados. Revise los criterios y póngase en contacto con nosotros si no encuentra una configuración que coincida.",
      "En Route": "En camino",
      Completed: "Completado",
      Documents: "Documentos",
      DOCUMENT_EFFECTIVE: "En vigencia {{effective, P}}",
      DOCUMENT_EXPIRY: "Caducidad {{expiry, P}}",
      "Customer documents": "Documentos del cliente",
      "You are limited to only {{count}} photos.":
        "Solo puede cargar hasta {{count}} fotos.",
      "Image {{index}}": "Imagen {{index}}",
      "Date must not be in the future.":
        "La fecha no debe ser una fecha futura.",
      "Return to job": "Volver al trabajo",
      "Husbandry requested": "Reparación solicitada",
      "Tire(s) requested": "Llanta(s) solicitadas",
      "No husbandry requested": "No se ha solicitado una reparación",
      "No tires requested": "No se han solicitado neumáticos",
      "Privacy statement": "Declaración de privacidad",
      "Terms of use": "Condiciones de uso",
      "Please be aware that submitting high quality images may result in significant data usage. Methods for reducing image quality will vary by device so please check with your device manufacturer.":
        "Tenga en cuenta que el envío de imágenes de alta calidad puede demandar un uso considerable de datos. Los métodos para reducir la calidad de las imágenes varían según el dispositivo, por lo que le recomendamos que consulte al fabricante de su dispositivo.",
      "This customer requires that a photo be taken for each position that requires work.":
        "Este cliente requiere que se tome una foto de cada posición en la que se requiera trabajo.",
      "The following positions could not be matched to a photo. Please ensure that a photo has been provided before continuing.":
        "Las siguientes posiciones no coincidieron con ninguna foto. Asegúrese de que se haya proporcionado una foto antes de continuar.",
      "Return to photos": "Volver a las fotos",
      "Coming soon.": "Próximamente",
      "Your session has expired. Please log in to continue.":
        "Su sesión ha caducado. Inicie sesión para continuar.",
      "If you cancel the breakdown, all progress will be lost and the breakdown will not be received by our agents.":
        "Si cancela la avería, se perderá todo el progreso, y nuestros agentes no recibirán la avería.",
      "If you save the breakdown for later, you will have 24 hours to provide the remaining details of the breakdown and submit it to our agents.":
        "Si guarda la avería para más tarde, tendrá 24 horas para proporcionar el resto de los detalles de la avería y enviarla a nuestros agentes.",
      "Cancel breakdown": "Cancelar avería",
      "Save breakdown for later": "Guardar avería para más tarde",
      "Return to breakdown": "Volver a la avería",
      "Continue breakdown": "Continuar con la avería",
      "You have no breakdowns in progress.": "No tiene averías en curso.",
      "Breakdowns in progress": "Averías en curso",
      "Please provide the following information for the {{position}} position.":
        "Proporcione la siguiente información para la posición {{position}}.",
      "Fitted make": "Marca ajustada",
      "Fitted pattern": "Dibujo ajustado",
      "Removed make": "Marca eliminada",
      "Removed pattern": "Dibujo eliminado",
      "Removed tread depth": "Profundidad de la banda de rodadura eliminada",
      "Save for later or cancel": "Guardar para más tarde o cancelar",
      STEER: "Dirigir",
      DRIVE: "Conducir",
      TRAILER: "Rodadura libre",
      "Blow out": "Explosión del neumático",
      "BO Blow Out": "Explosión del neumático",
      "Cut to cords": "Cables cortados",
      "CTC Cut To Cords": "Cables cortados",
      "Flat spot": "Superficie plana",
      "FS Flat Spot": "Superficie plana",
      "Run flat": "Run flat",
      "Sidewall damage": "Daños en los flancos",
      "SD Sidewall Damage": "Daños en los flancos",
      "Tread damage": "Daños en las bandas de rodadura",
      "TD Tread Damage": "Daños en las bandas de rodadura",
      Puncture: "Pinchadura",
      "PC Puncture": "Pinchadura",
      "Upload Photos": "Cargar fotos",
      Confirm: "Confirmar",
      ASAP: "Lo antes posible",
      Submit: "Enviar",
      "Select Availability": "Seleccionar disponibilidad",
      "Available All Day": "Disponible todo el día",
      "Pick Availability": "Escoger disponibilidad",
      "Next Day": "Día siguiente",
      "Previous Day": "Día anterior",
      Repair: "Reparación",
      "Pressure check": "Control de presión",
      Retorque: "Reajuste",
      "Turn on rim": "Rotación de llantas",
      Rebalance: "Rebalanceo",
      Regroove: "Reesculturado",
      "Wheel alignment": "Alineación de ruedas",
      Mismatch: "Desajuste",
      "Uneven wear": "Desgaste irregular",
      Worn: "Desgastado",
      "Allocated jobs": "Trabajos asignados",
      "Wrong speed/load rating": "Rango de velocidad/carga incorrecto",
      "All Depots": "Todos los depósitos",
      "All Customers": "Todos los clientes",
      "Your jobs only": "Solo sus trabajos",
      Vehicle: "Vehículo",
      "Attached Vehicle": "Vehículo acoplado",
      "Location Type": "Tipo de ubicación",
      "Job Management": "Gestión de trabajos",
      "Job Details": "Detalles del trabajo",
      Photos: "Fotos",
      "Job Cancelled": "Trabajo cancelado",
      "Return to jobs": "Volver a los trabajos",
      "Vehicle Registration": "Matrícula del vehículo",
      "Job Date": "Fecha del trabajo",
      "Contact Information": "Información de contacto",
      "Driver Name": "Nombre del conductor",
      "Driver Number": "Número del conductor",
      "Tire Size": "Tamaño de los neumáticos",
      Make: "Marca",
      Pattern: "Dibujo",
      "Removal Reason": "Motivo del desmontaje",
      Timings: "Sincronización",
      Arrived: "Llegada",
      "Work Complete": "Trabajo completado",
      "Returned Home": "Regreso a casa",
      "Work Instructions": "Instrucciones de trabajo",
      "Close Out Instructions": "Instrucciones de cierre",
      "Return to Menu": "Volver al menú",
      "No Photos Uploaded": "No hay fotos cargadas",
      "Add Photo": "Agregar foto",
      "En Route Time": "Tiempo en camino",
      "Time Arrived": "Hora de llegada",
      "Failure description": "Descripción de la falla",
      "Attending depot": "Ubicación del concesionario de reparación",
      "Wheel Security": "Seguridad del Aro",
      "Wheel(s) Removed?": "¿Desinstaló los aros del eje?",
      "Please Select ...": "Seleccione una opción",
      "Job Summary": "Resumen de la Orden de Trabajo",
      "Caller Number": "Número de Teléfono del Cliente",
      "Torque Setting": "Configuración de la llave de toque",
      "Wrench Number": "Número de llave de torque",
      "Retorque Carried Out?":
        "¿Se hizo el segundo torque en base a la recomendación del fabricante?",
      "Info Left With Customer": "Información entregada al cliente",
      "Torque Tag": "Etiqueta del Torque",
      "Written on jobsheet": "Escrito en la Orden de Trabajo",
      "Van / Car": "Van / Automóbil",
      "Tag Number": "Número de Etiqueta",
      LIFT: "Eje de Elevación",
      "DOT Not Visible": "DOT no visible",
      "Out Of Date": "DOT Expirado",
      Twinning: "Emparejamiento",
      Husbandries: "Reparacióne",
      Expires: "Caduca",
      Description: "Descripción",
      "Get summary PDF": "Get summary PDF",
    },
  },
};

export default resources;
