import React, { useState } from "react";
import WorkType from "./work-type/WorkType";
import Tyre from "./tyre/Tyre";
import Husbandry from "./husbandry/Husbandry";
import Prompt from "./prompt/Prompt";
import { WorkType as WT } from "360";
import { useApi, isNoContent } from "../../../../../api/Api";
import Spinner from "../../../../spinner/Spinner";
import { Position } from "360";

type Props = {
  position: Position;
  reference: string;
  onComplete: () => void;
  onCancel: () => void;
  onFail: () => void;
  onRemove: () => void;
  hasExistingWork: boolean;
};

const PositionWorkRequired = (props: Props) => {
  const { position, reference, onCancel, onComplete, onRemove, onFail } = props;
  const [workType, setWorkType] = useState<WT | null>(null);
  const [hasExistingWork, setHasExistingWork] = useState<boolean>(
    props.hasExistingWork
  );
  const [prompted, setPrompted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useApi();

  const handleRemoveWork = async () => {
    try {
      setLoading(true);
      const response = await api.delete(
        `api/breakdowns/${reference}/work-required/${position.id}`,
        "1.0"
      );
      setLoading(false);

      if (isNoContent(response)) return onRemove();
    } catch {}

    onFail();
  };

  const handleChangeWork = () => {
    setHasExistingWork(false);
    setPrompted(true);
  };

  const handleWorkTypeBackClick = () => {
    setHasExistingWork(props.hasExistingWork);
    setPrompted(false);
  };

  if (loading) return <Spinner />;

  if (!prompted) {
    return (
      <Prompt
        position={position.description}
        onAddWork={() => setPrompted(true)}
        onChangeWork={handleChangeWork}
        onRemoveWork={handleRemoveWork}
        onCancel={onCancel}
        hasExistingWork={hasExistingWork}
      />
    );
  }

  if (workType === "TYRE")
    return (
      <Tyre
        positionId={position.id}
        reference={reference}
        onComplete={onComplete}
        onFail={onFail}
        onBackClick={() => setWorkType(null)}
      />
    );

  if (workType === "HUSBANDRY")
    return (
      <Husbandry
        positionId={position.id}
        reference={reference}
        onComplete={onComplete}
        onFail={onFail}
        onBackClick={() => setWorkType(null)}
      />
    );

  return (
    <WorkType onSelect={setWorkType} onBackClick={handleWorkTypeBackClick} />
  );
};

export default PositionWorkRequired;
