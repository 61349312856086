import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";
import Button from "./button/Button";
import {
  faClock,
  faSearch,
  faBook,
  faPhone,
  faClipboardList,
  faFile,
} from "@fortawesome/pro-solid-svg-icons";
import SupplierGuideComingSoon from "./SupplierGuideComingSoon";
import HealthSafetyWarning from "../health-safety-warning/HealthSafetyWarning";

type Props = {
  onAllocatedJobsClick: () => void;
  onPhoneUsClick: () => void;
  onSupplierDocumentsClick: () => void;
  onTodaysJobsClick: () => void;
  onSearchClick: () => void;
};

const SupplierHome = (props: Props) => {
  const {
    onAllocatedJobsClick,
    onPhoneUsClick,
    onSupplierDocumentsClick,
    onTodaysJobsClick,
    onSearchClick,
  } = props;
  const { t } = useTranslation();
  const [
    showSupplierGuideComingSoon,
    setShowSupplierGuideComingSoon,
  ] = useState<boolean>(false);

  if (showSupplierGuideComingSoon)
    return (
      <SupplierGuideComingSoon
        onBackClick={() => setShowSupplierGuideComingSoon(false)}
      />
    );

  return (
    <S.Home>
      <HealthSafetyWarning showContinue={false} />
      <S.Grid>
        <Button
          icon={faClipboardList}
          onClick={onAllocatedJobsClick}
          label={t("Allocated jobs")}
        />
        <Button
          icon={faClock}
          onClick={onTodaysJobsClick}
          label={t("Recent jobs")}
        />
        <Button
          icon={faSearch}
          onClick={onSearchClick}
          label={t("Search jobs")}
        />
        <Button
          icon={faFile}
          onClick={onSupplierDocumentsClick}
          label={t("Documents")}
        />
        <Button
          icon={faBook}
          onClick={() => setShowSupplierGuideComingSoon(true)}
          label={t("User guide")}
        />
        <Button icon={faPhone} onClick={onPhoneUsClick} label={t("Call us")} />
      </S.Grid>
    </S.Home>
  );
};

export default SupplierHome;
