import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../../../utils/colours";
import _button from "../../../button/Button";

const ContactTSU = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  align-items: center;
`;

const Header = styled.div`
  font-weight: bold;
  margin-top: 5px;
`;

const Detail = styled.div`
  margin-top: 3px;
  padding: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.lightGreen};
`;

const PhoneIcon = styled(FontAwesomeIcon)`
  margin-right: 3px;
`;

const Button = styled(_button)`
  margin: 3px;
  width: 16rem;
`;

export default { ContactTSU, Header, Icon, PhoneIcon, Detail, Button };
