class ValidationResult {
  private errors: { [key: string]: string[] | undefined };

  private constructor() {
    this.errors = {};
  }

  get ok(): boolean {
    for (const field in this.errors) {
      const val = this.errors[field];

      if (val !== undefined && val.length > 0) {
        return false;
      }
    }

    return true;
  }

  getErrors(field: string): string[] | undefined {
    if (!field) {
      throw new Error("field is compulsory");
    }

    return this.errors[field];
  }

  add(field: string, error: string): void {
    if (!field) {
      throw new Error("field is compulsory");
    }

    if (!error) {
      throw new Error("error is compulsory");
    }

    let v = this.getErrors(field);

    if (v === undefined) {
      v = [];
      this.errors[field] = v;
    }

    v.push(error);
  }

  static get empty(): ValidationResult {
    return new ValidationResult();
  }
}

export default ValidationResult;
