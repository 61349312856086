import React, { useState, useEffect } from "react";
import S from "./styles";
import JobCategory from "../../job-details/job-category/JobCategory";
import {
  faClipboardList,
  faPhone,
  faTire,
  faTireFlat,
  faClock,
  faMapMarkedAlt,
} from "@fortawesome/pro-solid-svg-icons";
import JobSubCategory from "../../job-details/job-sub-category/JobSubCategory";
import JobDetail from "../job-details/JobDetail";
import { useApi, isSuccess, Response, mapSuccess } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import Spinner from "../../spinner/Spinner";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Thumbnail from "../../image-gallery/thumbnail/Thumbnail";
import FullImageViewer from "../../modal/image-viewer/ImageViewer";
import WorkInstruction from "./WorkInstruction";

type Props = {
  onReturn: () => void;
  jobNumber: number;
};

type MapProps = {
  latitude: number;
  longitude: number;
};

const Map = (props: MapProps) => {
  const { latitude, longitude } = props;
  const position = { lat: latitude, lng: longitude };

  return (
    <S.MapContainer>
      <GoogleMap
        mapContainerClassName="map"
        zoom={16}
        center={position}
        options={{
          fullscreenControl: false,
          clickableIcons: false,
          mapTypeControl: false,
          streetViewControl: false,
          draggable: false,
        }}
      >
        <Marker position={position} />
      </GoogleMap>
    </S.MapContainer>
  );
};

const markup = (html: string) => {
  return { __html: html };
};

const JobDetails = (props: Props) => {
  const [job, setJob] = useState<JobDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [fullImageSource, setFullImageSource] = useState<string | null>(null);
  const [workInstructionComponents, setWorkInstructionComponents] = useState<
    JSX.Element[]
  >();

  const api = useApi();

  const getWorkInstructionComponents = () => {
    if (
      workInstructionComponents === undefined &&
      job?.workInstructions !== undefined
    )
      return buildWorkInstructionComponents(job?.workInstructions);

    return workInstructionComponents;
  };

  const buildTextBlock = (text: string, idKey: number) => {
    return (
      <S.WorkInstruction
        key={idKey}
        dangerouslySetInnerHTML={markup(text)}
      ></S.WorkInstruction>
    );
  };

  const buildWorkInstruction = (html: string) => {
    var components: JSX.Element[] = [];
    var images: string[] = [];
    var textBlock: string = "";
    var blockId: number = 0;

    var openTag = html.search(/<img/i);
    while (openTag !== -1) {
      var closeTag = html.search("/>") + 2;
      images.push(html.substring(openTag, closeTag));

      textBlock =
        textBlock +
        html.substring(0, openTag) +
        " [Image " +
        images.length +
        "]";
      html = html.substring(closeTag);
      openTag = html.search(/<img/i);
    }
    textBlock = textBlock + html;

    components.push(buildTextBlock(textBlock, blockId++));
    images.forEach((element, index) => {
      components.push(buildImageBlock(element, index + 1));
    });
    return components;
  };

  const buildImageBlock = (html: string, index: number) => {
    var srcTag = html.search(/src/i);
    var srcEnd = html.search(/.png/i);

    var source = html.substring(srcTag + 5, srcEnd + 4);

    return (
      <S.WorkImage key={index}>
        <Thumbnail
          src={source}
          onClick={() => {
            handleWorkImageClicked(source);
          }}
        />
        <div>{t("Image {{index}}", { index: index })}</div>
      </S.WorkImage>
    );
  };

  const buildWorkInstructionComponents = (wi: WorkInstruction[]) => {
    var components = wi.map((wi) => {
      return (
        <JobSubCategory key={wi.id}>
          <S.DetailTitle>{wi.title}</S.DetailTitle>
          {buildWorkInstruction(wi.htmlMarkUp)}
        </JobSubCategory>
      );
    });
    setWorkInstructionComponents(components);
    return components;
  };

  const handleWorkImageClicked = (source: string | null) => {
    setFullImageSource(source);
  };

  useEffect(() => {
    (async () => {
      const getJobDetails = async (
        jobId: number
      ): Promise<Response<JobDetail>> => {
        var response = await api.get<any>(`api/allocated-jobs/${jobId}`, "1.0");
        return mapSuccess(response, (data) => ({
          ...data,
          jobDate: new Date(data.jobDate),
          estimatedTimeOfArrival:
            data.estimatedTimeOfArrival !== null
              ? new Date(data.estimatedTimeOfArrival)
              : null,
          enRoute: data.enRoute !== null ? new Date(data.enRoute) : null,
          onScene: data.onScene !== null ? new Date(data.onScene) : null,
          completed: data.completed !== null ? new Date(data.completed) : null,
          returnedHome:
            data.returnedHome !== null ? new Date(data.returnedHome) : null,
        }));
      };

      try {
        setLoading(true);
        const response = await getJobDetails(props.jobNumber);
        setLoading(false);

        if (isSuccess(response)) {
          setJob(response.data);
          return;
        }
      } catch {}
    })();
  }, [props.jobNumber, api]);

  if (job === null || loading) return <Spinner></Spinner>;

  return (
    <React.Fragment>
      {fullImageSource !== null && (
        <FullImageViewer
          src={fullImageSource}
          onClose={() => {
            setFullImageSource(null);
          }}
        ></FullImageViewer>
      )}
      <S.JobDetails>
        <S.Title>{t("Job Details")}</S.Title>
        <JobCategory title={t("Job Summary")} icon={faClipboardList}>
          <JobSubCategory>
            <S.DetailOverview>
              <S.DetailContainer>
                <S.Detail>{t("TSU number")}</S.Detail>
                <S.DetailContent>{job.jobId}</S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Vehicle Registration")}</S.Detail>
                <S.DetailContent>{job.vehicleRegistration}</S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Job Date")}</S.Detail>
                <S.DetailContent>
                  {t("DisplayDate", { date: new Date(job.jobDate) })}
                </S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Additional Information")}</S.Detail>
              </S.DetailContainer>
              <S.AdditionalInfo>
                <S.DetailContent>{job.additionalInformation}</S.DetailContent>
              </S.AdditionalInfo>
            </S.DetailOverview>
          </JobSubCategory>
        </JobCategory>

        <JobCategory title={t("Contact Information")} icon={faPhone}>
          <JobSubCategory>
            <S.DetailOverview>
              <S.DetailContainer>
                <S.Detail>{t("Caller Name")}</S.Detail>
                <S.DetailContent>{job.callerName}</S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Caller Number")}</S.Detail>
                <S.DetailContent>{job.contactNumber}</S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Driver Name")}</S.Detail>
                <S.DetailContent>{job.driverName}</S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Driver Number")}</S.Detail>
                <S.DetailContent>{job.driverNumber}</S.DetailContent>
              </S.DetailContainer>
            </S.DetailOverview>
          </JobSubCategory>
        </JobCategory>

        <JobCategory title={t("Location")} icon={faMapMarkedAlt}>
          <JobSubCategory>
            <S.DetailOverview>
              <S.DetailContainer>
                {job.location !== null && <S.Detail>{t("Location")}</S.Detail>}
                {job.location !== null && (
                  <S.DetailContent>{job.location}</S.DetailContent>
                )}
              </S.DetailContainer>
              <S.DetailContainer>
                {job.latitude !== null && job.longitude !== null && (
                  <S.Detail>{t("Coordinates")}</S.Detail>
                )}
              </S.DetailContainer>
              <S.DetailContainer>
                {job.latitude !== null && job.longitude !== null && (
                  <Map latitude={job.latitude} longitude={job.longitude} />
                )}
              </S.DetailContainer>
            </S.DetailOverview>
          </JobSubCategory>
        </JobCategory>

        <JobCategory title={t("Tire(s) requested")} icon={faTire}>
          {job.allocatedTyres.length < 1 ? (
            <S.NoItems>{t("No tires requested")}</S.NoItems>
          ) : (
            job.allocatedTyres.map((tyre, index) => {
              return (
                <JobSubCategory key={index}>
                  <S.DetailOverview>
                    <S.DetailContainer>
                      <S.Detail>{t("Position")}</S.Detail>
                      <S.DetailContent>{tyre.position}</S.DetailContent>
                    </S.DetailContainer>
                    <S.DetailContainer>
                      <S.Detail>{t("Tyre Size")}</S.Detail>
                      <S.DetailContent>{tyre.tyreSize}</S.DetailContent>
                    </S.DetailContainer>
                    <S.DetailContainer>
                      <S.Detail>{t("Make")}</S.Detail>
                      <S.DetailContent>{tyre.make}</S.DetailContent>
                    </S.DetailContainer>
                    <S.DetailContainer>
                      <S.Detail>{t("Pattern")}</S.Detail>
                      <S.DetailContent>{tyre.pattern}</S.DetailContent>
                    </S.DetailContainer>
                    <S.DetailContainer>
                      <S.Detail>{t("Removal Reason")}</S.Detail>
                      <S.DetailContent>{t(tyre.removalReason)}</S.DetailContent>
                    </S.DetailContainer>
                  </S.DetailOverview>
                </JobSubCategory>
              );
            })
          )}
        </JobCategory>

        <JobCategory title={t("Husbandry requested")} icon={faTireFlat}>
          {job.allocatedHusbandries.length < 1 ? (
            <S.NoItems>{t("No husbandry requested")}</S.NoItems>
          ) : (
            job.allocatedHusbandries.map((tyre, index) => {
              return (
                <JobSubCategory key={index}>
                  <S.DetailOverview>
                    <S.DetailContainer>
                      <S.Detail>{t("Position")}</S.Detail>
                      <S.DetailContent>{tyre.position}</S.DetailContent>
                    </S.DetailContainer>
                    <S.DetailContainer>
                      <S.Detail>{t("Tyre Size")}</S.Detail>
                      <S.DetailContent>{tyre.tyreSize}</S.DetailContent>
                    </S.DetailContainer>
                    <S.DetailContainer>
                      <S.Detail>{t("Husbandry")}</S.Detail>
                      <S.DetailContent>
                        {t(tyre.requestedHusbandry)}
                      </S.DetailContent>
                    </S.DetailContainer>
                  </S.DetailOverview>
                </JobSubCategory>
              );
            })
          )}
        </JobCategory>

        <JobCategory title={t("Timings")} icon={faClock}>
          <JobSubCategory>
            <S.DetailOverview>
              <S.DetailContainer>
                <S.Detail>{t("ETA")}</S.Detail>
                <S.DetailContent>
                  {job.estimatedTimeOfArrival ? (
                    t("DisplayDate", {
                      date: new Date(job.estimatedTimeOfArrival),
                    })
                  ) : (
                    <span></span>
                  )}
                </S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("En Route")}</S.Detail>
                <S.DetailContent>
                  {job.enRoute ? (
                    t("DisplayDate", { date: new Date(job.enRoute) })
                  ) : (
                    <span></span>
                  )}
                </S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Arrived")}</S.Detail>
                <S.DetailContent>
                  {job.onScene ? (
                    t("DisplayDate", { date: new Date(job.onScene) })
                  ) : (
                    <span></span>
                  )}
                </S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Work Complete")}</S.Detail>
                <S.DetailContent>
                  {job.completed ? (
                    t("DisplayDate", { date: new Date(job.completed) })
                  ) : (
                    <span></span>
                  )}
                </S.DetailContent>
              </S.DetailContainer>
              <S.DetailContainer>
                <S.Detail>{t("Returned Home")}</S.Detail>
                <S.DetailContent>
                  {job.returnedHome ? (
                    t("DisplayDate", { date: new Date(job.returnedHome) })
                  ) : (
                    <span></span>
                  )}
                </S.DetailContent>
              </S.DetailContainer>
            </S.DetailOverview>
          </JobSubCategory>
        </JobCategory>

        <JobCategory title={t("Work Instructions")} icon={faClipboardList}>
          {getWorkInstructionComponents()}
        </JobCategory>
        <S.Footer>
          <S.Button onClick={props.onReturn}>{t("Return to job")}</S.Button>
        </S.Footer>
      </S.JobDetails>
    </React.Fragment>
  );
};

export default JobDetails;
