import React from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";

type SingleFieldComponentProps = {
  header: string;
  children?: React.ReactNode;
  type: "NUMERIC-INPUT" | "INPUT" | "TEXTAREA";
  value: string;
  errors?: string[];
  onChange: (value: string) => void;
  onContinue: () => void;
  onBackClick: () => void;
  onCancel: () => void;
};

const SingleFieldComponent = (props: SingleFieldComponentProps) => {
  const {
    children,
    header,
    type,
    value,
    errors,
    onChange,
    onContinue,
    onBackClick,
    onCancel,
  } = props;
  const { t } = useTranslation();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => onChange(e.target.value);

  const inputComponent =
    type === "INPUT" ? (
      <S.Input value={value} errors={errors} onChange={handleChange} />
    ) : type === "NUMERIC-INPUT" ? (
      <S.Input
        type="number"
        value={value}
        errors={errors}
        onChange={handleChange}
      />
    ) : (
      <S.TextArea value={value} errors={errors} onChange={handleChange} />
    );

  return (
    <S.SingleFieldComponent>
      <S.Header>{header}</S.Header>
      {inputComponent}
      {children}
      <S.Button onClick={onContinue}>
        {t(value && value.trim() ? "Continue" : "Skip")}
      </S.Button>
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
      <S.Button onClick={onCancel}>{t("Save for later or cancel")}</S.Button>
    </S.SingleFieldComponent>
  );
};

export default SingleFieldComponent;
