import styled from "styled-components";
import _Button from "../../../../../button/Button";

const ListComponent = styled.div``;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const Heading = styled.p`
  margin-bottom: 5px;
`;

export default { ListComponent, Heading, ListItem, Button };
