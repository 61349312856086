import React from "react";
import S from "../styles";
import BubbleContent from "../bubbleContent/BubbleContent";
import { Notification } from "360";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  notification: Notification;
};

const WarningBubble = (props: Props) => {
  return (
    <S.WarningBubble>
      <S.IconWrapper>
        <S.YellowIcon icon={faExclamationTriangle} />
      </S.IconWrapper>
      <BubbleContent notification={props.notification} />
    </S.WarningBubble>
  );
};

export default WarningBubble;
