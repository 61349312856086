import { TimeSlot } from "360";
import { add } from "date-fns";

export const adjustSlotsToDate = (date: Date, slots: TimeSlot[]): TimeSlot[] =>
  slots.map((slot) => {
    const from = new Date(slot.from);
    from.setFullYear(date.getFullYear());
    from.setMonth(date.getMonth());
    from.setDate(date.getDate());
    const to = new Date(slot.to);
    to.setFullYear(date.getFullYear());
    to.setMonth(date.getMonth());
    to.setDate(date.getDate());

    return {
      from,
      to: from > to ? add(to, { days: 1 }) : to,
    };
  });

const isSameHour = (x: Date, y: Date) => x.getHours() === y.getHours();
const isSameMinute = (x: Date, y: Date) => x.getMinutes() === y.getMinutes();
const isSameSecond = (x: Date, y: Date) => x.getSeconds() === y.getSeconds();
const isSameMillisecond = (x: Date, y: Date) =>
  x.getMilliseconds() === y.getMilliseconds();
const isSameTime = (x: Date, y: Date) =>
  isSameHour(x, y) &&
  isSameMinute(x, y) &&
  isSameSecond(x, y) &&
  isSameMillisecond(x, y);

export const slotTimesEqual = (x: TimeSlot, y: TimeSlot) =>
  isSameTime(x.from, y.from) && isSameTime(x.to, y.to);

export const slotsEquivalentToAllDaySelected = (slots: TimeSlot[]) =>
  slots.length === 1 && slotTimesEqual(slots[0], allDaySlot);

export const allDaySlot = (() => {
  const from = new Date();
  const to = new Date();
  from.setHours(8, 0, 0, 0);
  to.setHours(17, 0, 0, 0);
  return { from, to };
})();
