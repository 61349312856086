import styled from "styled-components";
import colours from "../../utils/colours";

type InputProps = { invalid: boolean };

const Input = styled.input<InputProps>`
  background-color: ${({ invalid }: InputProps) =>
    invalid ? colours.extraLightRed : colours.white};
  border: 1px solid
    ${({ invalid }: InputProps) => (invalid ? colours.lightRed : colours.black)};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2px 5px;
`;

export default { Input };
