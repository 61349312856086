import styled from "styled-components";
import colours from "../../utils/colours";

type SelectProps = { invalid: boolean };

const Select = styled.select<SelectProps>`
  background-color: ${({ invalid }: SelectProps) =>
    invalid ? colours.extraLightRed : colours.white};
  border: 1px solid
    ${({ invalid }: SelectProps) =>
      invalid ? colours.lightRed : colours.black};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2px 0;
`;

const Wrapper = styled.div`
  & > ul {
    margin-top: 5px;
  }
`;

export default { Select, Wrapper };
