import React, { useState, useEffect } from "react";
import {
  Api,
  Response,
  mapSuccess,
  useApi,
  isSuccess,
  isNoContent,
} from "../../api/Api";
import Spinner from "../spinner/Spinner";
import GeneralErrors from "../general-errors/GeneralErrors";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { BreakdownInProgress } from "360";

const getBreakdownsInProgress = async (
  api: Api
): Promise<Response<BreakdownInProgress[]>> => {
  const response = await api.get<any[]>(`api/breakdowns/in-progress`, "1.0");

  return mapSuccess(response, (breakdowns) =>
    breakdowns.map((breakdown) => ({
      ...breakdown,
      created: new Date(breakdown.created),
      expires: new Date(breakdown.expires),
    }))
  );
};

const cancelBreakdown = async (
  api: Api,
  reference: string
): Promise<Response<void>> => api.delete(`api/breakdowns/${reference}`, "1.0");

interface BreakdownProps {
  breakdown: BreakdownInProgress;
  onContinue: (reference: string) => void;
  onCancel: (reference: string) => void;
}

const Breakdown = (props: BreakdownProps) => {
  const { breakdown, onContinue, onCancel } = props;
  const { t } = useTranslation();

  return (
    <S.Breakdown>
      <S.BreakdownContent>
        <p>{t("Vehicle / unit number")}</p>
        <p>{breakdown.vehicle}</p>
        <p>{t("Customer")}</p>
        <p>{breakdown.customer}</p>
        <p>{t("Expires")}</p>
        <p>{t("DisplayDate", { date: breakdown.expires })}</p>
      </S.BreakdownContent>
      <S.BreakdownButtons>
        <S.BreakdownButton onClick={() => onContinue(breakdown.reference)}>
          {t("Continue")}
        </S.BreakdownButton>
        <S.BreakdownButton onClick={() => onCancel(breakdown.reference)}>
          {t("Cancel")}
        </S.BreakdownButton>
      </S.BreakdownButtons>
    </S.Breakdown>
  );
};

interface BreakdownListProps {
  breakdowns: BreakdownInProgress[];
  onContinue: (reference: string) => void;
  onCancel: (reference: string) => void;
}

const BreakdownList = (props: BreakdownListProps) => {
  const { breakdowns, onContinue, onCancel } = props;

  return (
    <ul>
      {breakdowns.map((breakdown) => (
        <Breakdown
          key={breakdown.reference}
          breakdown={breakdown}
          onContinue={onContinue}
          onCancel={onCancel}
        />
      ))}
    </ul>
  );
};

interface Props {
  onContinue: (reference: string) => void;
}

const BreakdownsInProgress = (props: Props) => {
  const { onContinue } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [breakdowns, setBreakdowns] = useState<BreakdownInProgress[]>([]);
  const [error, setError] = useState<string | null>(null);
  const api = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const response = await getBreakdownsInProgress(api);
        setLoading(false);

        if (isSuccess(response)) {
          return setBreakdowns(response.data);
        }
      } catch {}

      setError(
        t(
          "Failed to load required data. Please reload 360\u{B0}Smart and try again."
        )
      );
    })();
  }, [api, t]);

  const handleCancel = async (reference: string) => {
    try {
      setLoading(true);
      const response = await cancelBreakdown(api, reference);
      setLoading(false);

      if (isNoContent(response)) {
        return setBreakdowns(
          breakdowns.filter((breakdown) => breakdown.reference !== reference)
        );
      }
    } catch {}

    setError(
      t(
        "Failed to load required data. Please reload 360\u{B0}Smart and try again."
      )
    );
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <GeneralErrors errors={[error]} onClose={() => setError(null)} />;
  }

  if (breakdowns.length === 0) {
    return <div>{t("You have no breakdowns in progress.")}</div>;
  }

  return (
    <S.BreakdownsInProgress>
      <S.Heading>{t("Breakdowns in progress")}</S.Heading>
      <BreakdownList
        breakdowns={breakdowns}
        onContinue={onContinue}
        onCancel={handleCancel}
      />
    </S.BreakdownsInProgress>
  );
};

export default BreakdownsInProgress;
