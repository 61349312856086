import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colours from "../../../utils/colours";

const Warning = styled.div`
  display: flex;
  align-items: center;
  max-width: 16rem;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-right: 5px;
  color: ${colours.yellow};
`;

export default { Warning, WarningIcon };
