import React from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  positions: string[];
  onContinue: () => void;
  onReturnToPhotos: () => void;
};

const PositionsMissingPhotos = (props: Props) => {
  const { positions, onContinue, onReturnToPhotos } = props;
  const { t } = useTranslation();

  return (
    <S.MissingPhotosWarning>
      <p>
        {t(
          "This customer requires that a photo be taken for each position that requires work."
        )}
      </p>
      <p>
        {t(
          "The following positions could not be matched to a photo. Please ensure that a photo has been provided before continuing."
        )}
      </p>
      <ul>
        {positions.map(x => (
          <li>{x}</li>
        ))}
      </ul>
      <S.Button onClick={onReturnToPhotos}> {t("Return to photos")}</S.Button>
      <S.Button onClick={onContinue}> {t("Continue")}</S.Button>
    </S.MissingPhotosWarning>
  );
};

export default PositionsMissingPhotos;
