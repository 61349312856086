import React from "react";
import S from "./styles";
import JobCategory from "../job-category/JobCategory";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import JobSubCategory from "../job-sub-category/JobSubCategory";

type Props = {
  contactName: string;
  contactTelephone: string;
  contactDepot: string | null;
  driverName: string | null;
  driverTelephone: string | null;
  endUser?: string;
  showEndUser: boolean;
  onCallerEdit?: () => void;
  onDriverEdit?: () => void;
};

const ContactInformation = (props: Props) => {
  const { onCallerEdit, onDriverEdit } = props;
  const { t } = useTranslation();

  return (
    <JobCategory icon={faUser} title={t("Caller details")}>
      <JobSubCategory onEditClicked={onCallerEdit}>
        <S.DetailContainer>
          <S.Detail>{t("Contact name")}</S.Detail>
          <S.DetailContent>{props.contactName}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Contact telephone")}</S.Detail>
          <S.DetailContent>{props.contactTelephone}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Contact depot")}</S.Detail>
          <S.DetailContent>{props.contactDepot}</S.DetailContent>
        </S.DetailContainer>
      </JobSubCategory>
      <JobSubCategory onEditClicked={onDriverEdit}>
        <S.DetailContainer>
          <S.Detail>{t("Driver name")}</S.Detail>
          <S.DetailContent>{props.driverName}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Driver telephone")}</S.Detail>
          <S.DetailContent>{props.driverTelephone}</S.DetailContent>
        </S.DetailContainer>
      </JobSubCategory>

      {props.showEndUser ? (
        <JobSubCategory onEditClicked={onDriverEdit}>
          <S.DetailContainer>
            <S.Detail>{t("End User")}</S.Detail>
            {props.endUser !== undefined ? (
              <S.DetailContent>{props.endUser}</S.DetailContent>
            ) : (
              <div></div>
            )}
          </S.DetailContainer>
        </JobSubCategory>
      ) : (
        <div></div>
      )}
    </JobCategory>
  );
};

export default ContactInformation;
