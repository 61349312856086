import styled from "styled-components";
import colours from "../../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _Button from "../../button/Button";

const Documents = styled.div`
  display: flex;
  flex-direction: column;
`;

const Document = styled.div`
  width: 16rem;
  padding: 5px 0px;
  flex-grow: 1;
  border-bottom: 1px solid ${colours.grey3};

  &:last-child {
    border-bottom: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FileDetails = styled.div`
  flex-grow: 1;

  & > * {
    padding: 2px 0px;
  }
`;

const FileDate = styled.p`
  font-size: 0.75;
  padding: 2px 0px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 36px;
  color: ${colours.grey2};
  margin-left: 2px;

  &:hover {
    color: ${colours.grey1};
  }
`;

const Button = styled(_Button)`
  width: 16rem;
  margin-bottom: 10px;
`;

const NoDocumentsFound = styled.div`
  text-align: center;
  font-style: italic;
  width: 100%;
  margin-bottom: 5px;
`;

export default {
  Documents,
  Document,
  Header,
  FileDetails,
  FileDate,
  Icon,
  Button,
  NoDocumentsFound
};
