import React, { useState, useEffect } from "react";
import S from "./styles";
import { Configuration, Axle, VehicleType } from "360";
import ConfigurationPreview from "./configuration-preview/ConfigurationPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useApi, isSuccess } from "../../../../api/Api";
import Spinner from "../../../spinner/Spinner";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  onChoose: (configuration: Configuration) => void;
  onFail: (errors?: string[]) => void;
};

// any axle with 4 positions can be considered twinned.
const axleIsTwinned = (a: Axle): boolean =>
  Object.keys(a.positions).length === 4;

const getTwinnedFilter = (twinned: boolean) => (c: Configuration) =>
  Object.values(c.axles).filter(axleIsTwinned).length > 0 === twinned; // eslint-disable-line no-mixed-operators

const axleIsDrive = (a: Axle) => a.axleType.id === 2 || a.axleType.id === 9;

const getDriveAxleCountFilter =
  (driveAxleCount: number) => (c: Configuration) =>
    Object.values(c.axles).filter(axleIsDrive).length === driveAxleCount;

const getAxleCountFilter = (axleCount: number) => (c: Configuration) =>
  Object.keys(c.axles).length === axleCount;

const filterConfigurations = (
  configurations: Configuration[],
  axleCount: number,
  driveAxleCount: number,
  twinned: boolean | null
) => {
  var axleCountFilter = getAxleCountFilter(axleCount);
  var driveAxleCountFilter = getDriveAxleCountFilter(driveAxleCount);
  var twinnedFilter = twinned !== null ? getTwinnedFilter(twinned) : () => true;

  return configurations
    .filter(axleCountFilter)
    .filter(driveAxleCountFilter)
    .filter(twinnedFilter);
};

const ConfigurationChooser = (props: Props) => {
  const { onChoose, onFail } = props;

  const [vehicleType, setVehicleType] = useState<VehicleType | null>("POWERED");
  const [axleCount, setAxleCount] = useState<number | null>(null);
  const [driveAxleCount, setDriveAxleCount] = useState<number | null>(null);
  const [axlesTwinned, setAxlesTwinned] = useState<boolean | null>(null);
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [configurationIndex, setConfigurationIndex] = useState<number>(0);

  const api = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<Configuration[]>(
          "api/configurations",
          "1.1"
        );
        setLoading(false);

        if (isSuccess(response)) return setConfigurations(response.data);
      } catch {}

      onFail();
    })();
  }, [api, onFail]);

  const enoughDataForConfigurationSelection =
    vehicleType !== null &&
    axleCount !== null &&
    ((vehicleType === "POWERED" && driveAxleCount !== null) ||
      (vehicleType === "OTHER" && axlesTwinned !== null));

  const filteredConfigurations = enoughDataForConfigurationSelection
    ? filterConfigurations(
        configurations,
        axleCount!,
        driveAxleCount !== null ? driveAxleCount : 0,
        axlesTwinned
      )
    : [];

  const handleContinueClick = () => {
    if (filteredConfigurations.length === 0) return;

    onChoose(filteredConfigurations[configurationIndex]);
  };

  const handlePreviousConfigurationClick = () => {
    if (configurationIndex <= 0) return;

    setConfigurationIndex(configurationIndex - 1);
  };

  const handleNextConfigurationClick = () => {
    if (configurationIndex >= filteredConfigurations.length - 1) return;

    setConfigurationIndex(configurationIndex + 1);
  };

  if (loading) return <Spinner />;

  return (
    <S.ConfigurationChooser>
      <S.Heading>{t("Does the vehicle have a powered/driven axle?")}</S.Heading>
      <S.ButtonRow>
        <S.LittleButton
          colour={vehicleType === "POWERED" ? "ORANGE" : "GREY"}
          onClick={() => {
            if (axleCount && axleCount < 2) setAxleCount(null);

            setAxlesTwinned(null);
            setVehicleType("POWERED");
            setConfigurationIndex(0);
          }}
        >
          {t("Yes")}
        </S.LittleButton>
        <S.LittleButton
          colour={vehicleType === "OTHER" ? "ORANGE" : "GREY"}
          onClick={() => {
            setDriveAxleCount(null);
            setVehicleType("OTHER");
            setConfigurationIndex(0);
          }}
        >
          {t("No")}
        </S.LittleButton>
      </S.ButtonRow>
      {vehicleType !== null && <S.Heading>{t("Number of axles")}</S.Heading>}
      {vehicleType !== null && (
        <S.ButtonRow>
          {vehicleType === "OTHER" && (
            <S.LittleButton
              colour={axleCount === 1 ? "ORANGE" : "GREY"}
              onClick={() => {
                setAxleCount(1);
                setConfigurationIndex(0);
              }}
            >
              1
            </S.LittleButton>
          )}
          <S.LittleButton
            colour={axleCount === 2 ? "ORANGE" : "GREY"}
            onClick={() => {
              setAxleCount(2);
              setConfigurationIndex(0);
            }}
          >
            2
          </S.LittleButton>
          <S.LittleButton
            colour={axleCount === 3 ? "ORANGE" : "GREY"}
            onClick={() => {
              setAxleCount(3);
              setConfigurationIndex(0);
            }}
          >
            3
          </S.LittleButton>
          <S.LittleButton
            colour={axleCount === 4 ? "ORANGE" : "GREY"}
            onClick={() => {
              setAxleCount(4);
              setConfigurationIndex(0);
            }}
          >
            4
          </S.LittleButton>
          <S.LittleButton
            colour={axleCount === 5 ? "ORANGE" : "GREY"}
            onClick={() => {
              setAxleCount(5);
              setConfigurationIndex(0);
            }}
          >
            5
          </S.LittleButton>
        </S.ButtonRow>
      )}
      {vehicleType === "POWERED" && (
        <S.Heading>{t("Number of drive axles")}</S.Heading>
      )}
      {vehicleType === "POWERED" && (
        <S.ButtonRow>
          <S.LittleButton
            colour={driveAxleCount === 1 ? "ORANGE" : "GREY"}
            onClick={() => {
              setDriveAxleCount(1);
              setConfigurationIndex(0);
            }}
          >
            1
          </S.LittleButton>
          <S.LittleButton
            colour={driveAxleCount === 2 ? "ORANGE" : "GREY"}
            onClick={() => {
              setDriveAxleCount(2);
              setConfigurationIndex(0);
            }}
          >
            2
          </S.LittleButton>
        </S.ButtonRow>
      )}
      {vehicleType === "OTHER" && <S.Heading>{t("Axles twinned")}</S.Heading>}
      {vehicleType === "OTHER" && (
        <S.ButtonRow>
          <S.LittleButton
            colour={axlesTwinned === true ? "ORANGE" : "GREY"}
            onClick={() => {
              setAxlesTwinned(true);
              setConfigurationIndex(0);
            }}
          >
            {t("Yes")}
          </S.LittleButton>
          <S.LittleButton
            colour={axlesTwinned === false ? "ORANGE" : "GREY"}
            onClick={() => {
              setAxlesTwinned(false);
              setConfigurationIndex(0);
            }}
          >
            {t("No")}
          </S.LittleButton>
        </S.ButtonRow>
      )}
      {enoughDataForConfigurationSelection &&
        filteredConfigurations.length > 0 && (
          <S.PreviewHolder>
            <S.ArrowButton
              onClick={handlePreviousConfigurationClick}
              colour={configurationIndex > 0 ? "ORANGE" : "GREY"}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </S.ArrowButton>
            <ConfigurationPreview
              configuration={filteredConfigurations[configurationIndex]}
            />
            <S.ArrowButton
              onClick={handleNextConfigurationClick}
              colour={
                configurationIndex < filteredConfigurations.length - 1
                  ? "ORANGE"
                  : "GREY"
              }
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </S.ArrowButton>
          </S.PreviewHolder>
        )}
      {enoughDataForConfigurationSelection &&
        filteredConfigurations.length === 0 && <NoConfigurationsFound />}
      {enoughDataForConfigurationSelection &&
        filteredConfigurations.length > 0 && (
          <S.BigButton onClick={handleContinueClick}>
            {t("Continue")}
          </S.BigButton>
        )}
    </S.ConfigurationChooser>
  );
};

const NoConfigurationsFound = () => {
  const { t } = useTranslation();

  return (
    <S.Warning>
      <S.WarningIcon icon={faExclamationTriangle} />
      <span>
        {t(
          "No configurations match your selected criteria. Please check your criteria and contact us if a matching configuration cannot be found."
        )}
      </span>
    </S.Warning>
  );
};

export default ConfigurationChooser;
