import React, { useState } from "react";
import S from "./styles";
import { useApi, isNoContent, isFailure } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import { useTranslation } from "react-i18next";
import DateTimeInput from "../../date-time-input/DateTimeInput";
import VR from "../../../utils/ValidationResult";
import formatISO from "date-fns/formatISO";

type Props = {
  onContinue: (enRouteTime: Date) => void;
  onReturn: () => void;
  onFail: (errors?: string[]) => void;
  jobNumber: number;
};

const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

const EnRoute = (props: Props) => {
  const { onContinue, onFail } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [enRouteTime, setEnRouteTime] = useState<Date>(new Date());
  const { t } = useTranslation();
  const [vr, setVr] = useState<VR>(VR.empty);

  const api = useApi();
  const submitEnRouteTime = (jobNumber: number, time: Date) =>
    api.post(`api/allocated-jobs/${jobNumber}/en-route`, "1.0", {
      time: formatISO(time),
    });
  const validate = (): VR => {
    const result = VR.empty;

    if (!isValidDate(enRouteTime))
      result.add("enRouteDate", t("You must enter a date and a time"));

    if (enRouteTime > new Date())
      result.add("enRouteDate", t("Date must not be in the future."));

    return result;
  };

  const handleDateChange = (value: Date) => {
    setEnRouteTime(value);
  };

  const handleContinue = async () => {
    const vr = validate();
    setVr(vr);
    if (!vr.ok) return;

    setLoading(true);
    try {
      const response = await submitEnRouteTime(props.jobNumber, enRouteTime);
      setLoading(false);

      if (isFailure(response)) {
        return onFail(response.errors);
      }

      if (isNoContent(response)) {
        return onContinue(enRouteTime);
      }
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  return (
    <S.EnRoute>
      <S.Title>{t("En Route Time")}</S.Title>

      <DateTimeInput
        onChange={handleDateChange}
        value={enRouteTime}
        errors={vr.getErrors("enRouteDate")}
      ></DateTimeInput>

      <S.Button onClick={handleContinue}>{t("Continue")}</S.Button>
      <S.Button onClick={props.onReturn}>{t("Return to job")}</S.Button>
    </S.EnRoute>
  );
};

export default EnRoute;
