import {
  Response,
  NoContent,
  Unauthorised,
  Error as ResponseError,
  Success,
  Failure,
} from "./Responses";

export const parseResponse = async <T>(
  response: globalThis.Response,
): Promise<Response<T>> => {
  if (response.status === 200) {
    const result = await response.json();

    return result.ok ? new Success<T>(result.data) : new Failure(result.errors);
  }

  if (response.status === 204) {
    return new NoContent();
  }

  if (response.status === 401) {
    return new Unauthorised();
  }

  return new ResponseError(response);
};

export const parseBlob = async (
  response: globalThis.Response,
): Promise<Response<Blob>> => {
  if (response.status === 200) {
    const blob = await response.blob();
    return new Success<Blob>(blob);
  }

  if (response.status === 204) {
    return new NoContent();
  }

  if (response.status === 401) {
    return new Unauthorised();
  }

  return new ResponseError(response);
};

export const getHeaders = (version: string) => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  "Api-Version": version,
});

export const addAuthorizationHeader = (
  opts: RequestInit,
  token: string,
): RequestInit => ({
  ...opts,
  headers: { ...opts.headers, Authorization: `Bearer ${token}` },
});

const options = {
  maxRetries: 3,
};

export const fetchWithRetry = async (url: string, opts: RequestInit) => {
  for (let i = 1; i <= options.maxRetries; i++) {
    try {
      return await fetch(url, opts);
    } catch (e) {
      if (i === options.maxRetries) {
        throw e;
      }
    }
  }

  throw new Error(`request to ${url} failed`);
};
