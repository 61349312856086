import React from "react";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";
import S from "./styles";

type Props = {
  number: string;
};

const Button = (props: Props) => {
  const { number } = props;
  return (
    <S.Button href={`tel:${number}`}>
      <S.Icon icon={faPhone} />
      <p>{number}</p>
    </S.Button>
  );
};

export default Button;
