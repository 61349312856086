import styled from "styled-components";
import _Button from "../../button/Button";
import _Input from "../../input/Input";

const JobTakeover = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(_Button)`
  width: 16rem;
  margin-bottom: 3px;
`;

const TextContent = styled.span`
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
`;

const TextWarning = styled(TextContent)`
  font-weight: bold;
`;

const Input = styled(_Input)`
  width: 16rem;
  margin-bottom: 5px;
`;

const Date = styled(_Input)`
  float: left;
  width: 10rem;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
`;

const Time = styled(_Input)`
  float: right;
  width: 6rem;
  border-radius: 0px 5px 5px 0px;
  border-text-align: center;
  border-left-width: 0px;
  text-align: center;
`;

const DateTime = styled.div`
  width: 16rem;
  margin-bottom: 5px;
`;

export default {
  JobTakeover,
  Button,
  Input,
  TextContent,
  TextWarning,
  DateTime,
  Date,
  Time
};
