import React from "react";
import S from "./styles";
import JobCategory from "../job-category/JobCategory";
import { faWrench } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import JobSubCategory from "../job-sub-category/JobSubCategory";

type Props = {
  wheelsRemoved: boolean | null;
  torqueSetting: string | null;
  wrenchNumber: string | null;
  retorqued: boolean | null;
  reasonNotRetorqued: string | null;
  tagNumber: string | null;
};

function notNullOrEmpty(s: string | null) {
  return s !== null && s.length > 0;
}

const ContactInformation = (props: Props) => {
  const {
    wheelsRemoved,
    torqueSetting,
    wrenchNumber,
    retorqued,
    reasonNotRetorqued,
    tagNumber,
  } = props;
  const { t } = useTranslation();

  return (
    <JobCategory icon={faWrench} title={t("Wheel Security")}>
      <JobSubCategory>
        {wheelsRemoved !== null && (
          <S.DetailContainer>
            <S.Detail>{t("Wheel(s) Removed?")}</S.Detail>
            <S.DetailContent>{t(wheelsRemoved ? "Yes" : "No")}</S.DetailContent>
          </S.DetailContainer>
        )}
        {notNullOrEmpty(torqueSetting) && (
          <S.DetailContainer>
            <S.Detail>{t("Torque Setting")}</S.Detail>
            <S.DetailContent>{torqueSetting}</S.DetailContent>
          </S.DetailContainer>
        )}
        {notNullOrEmpty(wrenchNumber) && (
          <S.DetailContainer>
            <S.Detail>{t("Wrench Number")}</S.Detail>
            <S.DetailContent>{wrenchNumber}</S.DetailContent>
          </S.DetailContainer>
        )}
        {retorqued !== null && (
          <S.DetailContainer>
            <S.Detail>{t("Retorque Carried Out?")}</S.Detail>
            <S.DetailContent>{t(retorqued ? "Yes" : "No")}</S.DetailContent>
          </S.DetailContainer>
        )}
        {notNullOrEmpty(reasonNotRetorqued) && (
          <S.DetailContainer>
            <S.Detail>{t("Info Left With Customer")}</S.Detail>
            <S.DetailContent>{reasonNotRetorqued}</S.DetailContent>
          </S.DetailContainer>
        )}
        {notNullOrEmpty(tagNumber) && (
          <S.DetailContainer>
            <S.Detail>{t("Tag Number")}</S.Detail>
            <S.DetailContent>{tagNumber}</S.DetailContent>
          </S.DetailContainer>
        )}
      </JobSubCategory>
    </JobCategory>
  );
};

export default ContactInformation;
