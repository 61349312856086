import React from "react";
import S from "./styles";
import { faWrench, faTire } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { WorkType as WT } from "360";

type Props = {
  onSelect: (wt: WT) => void;
  onBackClick: () => void;
};

const WorkType = (props: Props) => {
  const { onSelect, onBackClick } = props;
  const { t } = useTranslation();

  return (
    <S.WorkType>
      <S.Heading>{t("Work required")}</S.Heading>
      <S.Button onClick={() => onSelect("TYRE")}>
        <S.IconContainer>
          <S.Icon icon={faTire} />
        </S.IconContainer>
        <S.ButtonText>{t("Replacement tire")}</S.ButtonText>
      </S.Button>
      <S.Button onClick={() => onSelect("HUSBANDRY")}>
        <S.IconContainer>
          <S.Icon icon={faWrench} />
        </S.IconContainer>
        <S.ButtonText>{t("Other work")}</S.ButtonText>
      </S.Button>
      <S.BackButton onClick={onBackClick}>{t("Back")}</S.BackButton>
    </S.WorkType>
  );
};

export default WorkType;
