import React, { useState, useEffect } from "react";
import Configuration from "./configuration/Configuration";
import ConfigurationChooser from "./configuration-chooser/ConfigurationChooser";
import { Configuration as Config } from "360";
import Spinner from "../../spinner/Spinner";
import { useApi, isSuccess, isNoContent } from "../../../api/Api";

type Props = {
  initialValue: number[] | null;
  onComplete: (positionsWithWork: number[]) => void;
  onBackClick: () => void;
  onCancel: () => void;
  onFail: () => void;
  reference: string;
};

const WorkRequired = (props: Props) => {
  const {
    onCancel,
    onComplete,
    onBackClick,
    onFail,
    reference,
    initialValue,
  } = props;
  const [configuration, setConfiguration] = useState<Config | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const api = useApi();

  const workAlreadySubmitted = initialValue !== null && initialValue.length > 0;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await (!workAlreadySubmitted
          ? api.get<Config | null>(
              `api/breakdowns/${reference}/configuration/default`,
              "1.1"
            )
          : api.get<Config>(
              `api/breakdowns/${reference}/configuration`,
              "1.1"
            ));
        setLoading(false);

        if (!isSuccess(response)) {
          // if we were just attempting to load a default configuration, we can continue
          // and allow the user to select a configuration.
          if (!workAlreadySubmitted) return;

          // if we were attempting to load the breakdown configuration, we cannot recover
          // from this.
          return onFail();
        }

        if (!workAlreadySubmitted && response.data !== null) {
          // we have successfully retrieved a default configuration for the breakdown.
          // we need to submit this as our breakdown's configuration.
          setLoading(true);
          const submitResponse = await api.post(
            `api/breakdowns/${reference}/configuration`,
            "1.0",
            { id: response.data.id }
          );
          setLoading(false);

          if (isNoContent(submitResponse))
            return setConfiguration(response.data);

          // if the submission failed, we can continue and allow the user to select
          // a configuration.
          return setConfiguration(null);
        }

        return setConfiguration(response.data);
      } catch {
        return onFail();
      }
    })();
  }, [reference, workAlreadySubmitted, onFail, api]);

  const handleChooseConfiguration = async (config: Config) => {
    try {
      setLoading(true);
      const response = await api.post(
        `api/breakdowns/${reference}/configuration`,
        "1.0",
        { id: config.id }
      );
      setLoading(false);

      if (isNoContent(response)) return setConfiguration(config);
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  if (configuration === null)
    return (
      <ConfigurationChooser
        onChoose={handleChooseConfiguration}
        onFail={onFail}
      />
    );

  return (
    <Configuration
      initialValue={initialValue}
      configuration={configuration}
      reference={reference}
      onComplete={onComplete}
      onBackClick={onBackClick}
      onCancel={onCancel}
      onFail={onFail}
      onChangeConfiguration={() => setConfiguration(null)}
    />
  );
};

export default WorkRequired;
