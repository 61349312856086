import styled from "styled-components";
import _Button from "../../button/Button";

const Summary = styled.div`
  height: 100%;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 5px;
  }
`;

const Title = styled.h3`
  font-weight: bold;
  margin: 10px 0px;
`;

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const Detail = styled.span`
  font-weight: bold;
`;

const DetailContent = styled.span``;

const Button = styled(_Button)`
  width: 16rem;
`;

export default {
  Summary,
  Title,
  DetailContainer,
  Detail,
  DetailContent,
  Button,
};
