import styled from "styled-components";

const IconContainer = styled.div`
  width: 30px !important;
  text-align: center;
`;

const JobListing = styled.li`
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px;
  display: flex;
  align-items: center;

  & > button,
  & > :nth-child(2) {
    margin-left: 5px;
  }

  @media screen and (min-width: 600px) {
    min-width: 500px;
  }
`;

const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

const DetailContainer = styled.div`
  display: flex;
`;

const JobDate = styled.div``;

const JobFlagContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export default {
  JobListing,
  JobDetails,
  IconContainer,
  DetailContainer,
  JobDate,
  JobFlagContainer
};
