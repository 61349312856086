import React from "react";
import { TimeSlot as TS } from "360";
import CheckBox from "../../../check-box/CheckBox";
import S from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  slot: TS;
  onToggle: () => void;
  checked: boolean;
  prefix?: string;
};

const TimeSlot = (props: Props) => {
  const { onToggle, slot, checked, prefix } = props;
  const { t } = useTranslation();

  return (
    <S.Parent onClick={onToggle}>
      <CheckBox isChecked={checked} />
      <span>{prefix}</span>
      <S.Timing>
        {t("AVAILABILITY", { time: slot.from })}
        {" - "}
        {t("AVAILABILITY", { time: slot.to })}
      </S.Timing>
    </S.Parent>
  );
};

export default TimeSlot;
