import React from "react";
import Button from "../button/Button";
import S from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  number: string;
};

const DefaultNumber = (props: Props) => {
  const { number } = props;
  const { t } = useTranslation();

  return (
    <S.DefaultNumber>
      <S.HelpMessage>
        {t("If you require assistance, please click the button to phone us.")}
      </S.HelpMessage>
      <Button number={number} />
    </S.DefaultNumber>
  );
};

export default DefaultNumber;
