import React from "react";
import S from "./styles";
import Icon from "../icon/Icon";
import NotificationIcon from "./notification-icon/NotificationIcon";
import { faPhone, faHome, faSignOut } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  onPhoneClick: () => void;
  onHomeClick: () => void;
  onNotificationClick: () => void;
  onLogoutClick: () => void;
  lastViewedNotificationId: number | null;
  userTypeId: number;
};

const LoggedInSubMenu = (props: Props) => {
  const {
    onPhoneClick,
    onHomeClick,
    onNotificationClick,
    onLogoutClick,
    lastViewedNotificationId
  } = props;

  return (
    <S.SubMenu>
      <Icon icon={faPhone} onClick={onPhoneClick} />
      <Icon icon={faHome} onClick={onHomeClick} />
      {props.userTypeId !== 2 && (
        <NotificationIcon
          onClick={onNotificationClick}
          lastViewedNotificationId={lastViewedNotificationId}
        />
      )}
      <Icon icon={faSignOut} onClick={onLogoutClick} />
    </S.SubMenu>
  );
};

export default LoggedInSubMenu;
