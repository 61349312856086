import React from "react";
import S from "./styles";

const Spinner = () => {
  return (
    <S.Spinner>
      <S.First />
      <S.Second />
      <S.Third />
      <S.Fourth />
      <S.Fifth />
    </S.Spinner>
  );
};

export default Spinner;
