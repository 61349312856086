import styled from "styled-components";

const Parent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 250px;
  width: 100%;
  align-content: space-around;
`;

export default { Parent };
