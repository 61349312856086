import React, { ReactNode, useEffect } from "react";
import S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  children: ReactNode;
  onClose: () => void;
};

const Model = (props: Props) => {
  useEffect(() => {
    document.addEventListener("keydown", onKeyPressed, false);

    return () => {
      document.removeEventListener("keydown", onKeyPressed, false);
    };
  });

  const onKeyPressed = (event: KeyboardEvent) => {
    if (event.keyCode === 27) props.onClose();
  };

  return (
    <S.Parent onClick={props.onClose}>
      <S.IconWrapper onClick={props.onClose}>
        <FontAwesomeIcon icon={faTimes} size="2x"></FontAwesomeIcon>
      </S.IconWrapper>
      {props.children}
    </S.Parent>
  );
};

export default Model;
