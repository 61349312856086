import React from "react";
import S from "./styles";
import JobCategory from "../job-category/JobCategory";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import JobSubCategory from "../job-sub-category/JobSubCategory";

type Props = {
  allocated: Date | null;
  serviceProvider: string | null;
  depot: string | null;
  eta: Date | null;
  onScene: Date | null;
  enRoute: Date | null;
  completed: Date | null;
};

const JobHistory = (props: Props) => {
  const {
    serviceProvider,
    depot,
    allocated,
    eta,
    enRoute,
    onScene,
    completed,
  } = props;

  const { t } = useTranslation();

  const displayDate = (date: Date | null) => {
    if (date !== null) return t("DisplayDate", { date: date });

    return "N/A";
  };

  return (
    <JobCategory icon={faClock} title={t("Job History")}>
      <JobSubCategory>
        <S.DetailContainer>
          <S.Detail>{t("Time Allocated")}</S.Detail>
          <S.DetailContent>{displayDate(allocated)}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Attending SP")}</S.Detail>
          <S.DetailContent>{serviceProvider}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Attending depot")}</S.Detail>
          <S.DetailContent>{depot}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("ETA")}</S.Detail>
          <S.DetailContent>{displayDate(eta)}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("En Route")}</S.Detail>
          <S.DetailContent>{displayDate(enRoute)}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("On Scene Time")}</S.Detail>
          <S.DetailContent>{displayDate(onScene)}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Completed")}</S.Detail>
          <S.DetailContent>{displayDate(completed)}</S.DetailContent>
        </S.DetailContainer>
      </JobSubCategory>
    </JobCategory>
  );
};

export default JobHistory;
