import { createContext, useContext } from "react";
import { NetworkError } from "../api/Api";

interface ApiContext {
  onNetworkError: (error: NetworkError) => void;
  onUnauthenticated?: () => void;
}

export const ApiContext = createContext<ApiContext | null>(null);

export const useApiContext = () => {
  const ctx = useContext(ApiContext);

  if (!ctx)
    throw new Error(
      "attempted to use api context inside provider with no value."
    );

  return ctx;
};
