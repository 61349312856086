import React, { useState } from "react";
import ValidationResult from "../../../utils/ValidationResult";
import { useTranslation } from "react-i18next";
import S from "./styles";

type Props = {
  onComplete: (failureDescription: string) => void;
};

const FailureDescription = (props: Props) => {
  const { onComplete } = props;
  const [failureDescription, setFailureDescription] = useState<string | null>(
    null
  );
  const [validationResult, setValidationResult] = useState<ValidationResult>(
    ValidationResult.empty
  );
  const { t } = useTranslation();

  const handleFailureDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFailureDescription(e.target.value || null);
  };

  const handleContinueClick = () => {
    const vr = validate();
    setValidationResult(vr);
    if (!vr.ok) {
      return;
    }

    if (!failureDescription) {
      throw new Error("attempted to submit null failure description");
    }

    onComplete(failureDescription.trim());
  };

  const validate = () => {
    const vr = ValidationResult.empty;

    if (!failureDescription || !failureDescription.trim()) {
      vr.add("failureDescription", "Field is required.");
    }

    if (failureDescription && failureDescription.length > 500) {
      vr.add(
        "failureDescription",
        t("Maximum length is {{len}} characters.", { len: 500 })
      );
    }

    return vr;
  };

  return (
    <S.WorkComplete>
      <label>{t("Failure description")}</label>
      <S.TextArea
        value={failureDescription || ""}
        onChange={handleFailureDescriptionChange}
        errors={validationResult.getErrors("failureDescription")}
      />
      <S.Button onClick={handleContinueClick}>{t("Continue")}</S.Button>
    </S.WorkComplete>
  );
};

export default FailureDescription;
