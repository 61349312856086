export class Success<T> {
  kind = "SUCCESS";
  data: T;

  constructor(data: T) {
    this.data = data;
  }
}

export class Failure {
  kind = "FAILURE";
  errors: string[];

  constructor(errors: string[]) {
    this.errors = errors;
  }
}

export class NoContent {
  kind = "NO-CONTENT";
}

export class Error {
  kind = "ERROR";
  response: globalThis.Response;

  constructor(response: globalThis.Response) {
    this.response = response;
  }
}

export class Unauthorised {
  kind = "UNAUTHORISED";
}

export class NetworkError {
  kind = "NETWORK-ERROR";
  error: Error;

  constructor(error: Error) {
    this.error = error;
  }
}

export type Response<T> =
  | Success<T>
  | Failure
  | NoContent
  | Unauthorised
  | Error
  | NetworkError;

export const isSuccess = <T>(response: Response<T>): response is Success<T> =>
  response.kind === "SUCCESS";
export const isFailure = <T>(response: Response<T>): response is Failure =>
  response.kind === "FAILURE";
export const isNoContent = <T>(response: Response<T>): response is NoContent =>
  response.kind === "NO-CONTENT";
export const isUnauthorised = <T>(
  response: Response<T>
): response is Unauthorised => response.kind === "UNAUTHORISED";
export const isError = <T>(response: Response<T>): response is Error =>
  response.kind === "ERROR";
export const isNetworkError = <T>(
  response: Response<T>
): response is NetworkError => response.kind === "NETWORK-ERROR";
export const mapSuccess = <T1, T2>(
  response: Response<T1>,
  f: (data: T1) => T2
): Response<T2> => {
  if (!isSuccess(response)) return response;

  return new Success(f(response.data));
};
