import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import VR from "../../../utils/ValidationResult";
import SingleFieldComponent from "../single-field-component/SingleFieldComponent";
import Spinner from "../../spinner/Spinner";
import GeneralErrors from "../../general-errors/GeneralErrors";
import { useApi, isNoContent, isFailure } from "../../../api/Api";

type Props = {
  onComplete: (purchaseOrderDefectNumber: string) => void;
  initialValue: string | null;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
  reference: string;
};

const PurchaseOrderDefectNumber = (props: Props) => {
  const {
    initialValue,
    onCancel,
    reference,
    onComplete,
    onFail,
    onBackClick,
  } = props;
  const [podn, setPodn] = useState<string>(initialValue || "");
  const [vr, setVr] = useState<VR>(VR.empty);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const api = useApi();
  const { t } = useTranslation();

  const submitPodn = async (
    reference: string,
    purchaseOrderDefectNumber: string
  ) =>
    api.post(
      `api/breakdowns/${reference}/purchase-order-defect-number`,
      "1.0",
      {
        purchaseOrderDefectNumber,
      }
    );

  const validate = (): VR => {
    const vr = VR.empty;

    if (podn.trim().length > 20) {
      vr.add("podn", t("Maximum length is {{len}} characters.", { len: 20 }));
    }

    return vr;
  };

  const handleContinue = async () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    try {
      setLoading(true);
      const response = await submitPodn(reference, podn);
      setLoading(false);

      if (isFailure(response))
        return setErrors(response.errors.map((e) => t(e)));

      if (isNoContent(response)) return onComplete(podn);
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  return (
    <SingleFieldComponent
      header={t("Purchase Order/Defect Number")}
      type="INPUT"
      value={podn}
      errors={vr.getErrors("podn")}
      onChange={setPodn}
      onContinue={handleContinue}
      onBackClick={onBackClick}
      onCancel={onCancel}
    />
  );
};

export default PurchaseOrderDefectNumber;
