import React, { useState, useEffect } from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";
import { useApi, isNoContent, isSuccess } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import {
  faRoad,
  faWarehouse,
  faWrench,
  faQuestion,
} from "@fortawesome/pro-solid-svg-icons";
import { LocationType as LT } from "360";

type Props = {
  reference: string;
  onComplete: (locationType: LT) => void;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
};

type IconProps = { id: number };

const Icon = ({ id }: IconProps) => {
  const icon =
    id === 1
      ? faRoad
      : id === 2
      ? faWrench
      : id === 3
      ? faWarehouse
      : faQuestion;

  return (
    <S.IconWrapper>
      <S.Icon icon={icon} />
    </S.IconWrapper>
  );
};

type ButtonProps = {
  lt: LT;
  onClick: () => void;
};

const Button = ({ lt, onClick }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <S.Button key={lt.id} onClick={onClick}>
      <Icon id={lt.id} />
      <span>{t(lt.description)}</span>
    </S.Button>
  );
};

const LocationType = (props: Props) => {
  const { reference, onComplete, onCancel, onFail, onBackClick } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [locationTypes, setLocationTypes] = useState<LT[]>([]);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<LT[]>("api/location-types", "1.0");
        setLoading(false);

        if (isSuccess(response)) return setLocationTypes(response.data);
      } catch {}

      onFail();
    })();
  }, [onFail, api]);

  const selectLocationType = async (lt: LT) => {
    try {
      setLoading(true);
      const response = await api.post<void>(
        `api/breakdowns/${reference}/location-type`,
        "1.0",
        { id: lt.id }
      );
      setLoading(false);

      if (isNoContent(response)) {
        return onComplete(lt);
      }
    } catch {}

    onFail();
  };

  const orderedLocationTypes = locationTypes.sort((a, b) => a.id - b.id);

  if (loading) return <Spinner />;

  return (
    <S.LocationType>
      <S.Title>{t("Location type")}</S.Title>
      {orderedLocationTypes.map((lt) => (
        <Button lt={lt} onClick={() => selectLocationType(lt)} key={lt.id} />
      ))}
      <S.BackButton onClick={onBackClick}>{t("Back")}</S.BackButton>
      <S.BackButton onClick={onCancel}>
        {t("Save for later or cancel")}
      </S.BackButton>
    </S.LocationType>
  );
};

export default LocationType;
