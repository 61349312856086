import styled from "styled-components";

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-grow: 1;
`;

const SubWrapper = styled.div`
  margin: auto;
`;

export default { Content, Wrapper, SubWrapper };
