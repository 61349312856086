import styled from "styled-components";

const SearchContent = styled.div`
  height: 100%;
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const FieldContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SearchFields = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 16rem;

  @media screen and (min-width: 600px) {
    max-width: none;
    flex-direction: row;

    div {
      margin-right: 5px;
    }
  }

  & input {
    width: 16rem;
  }
`;

const Search = styled.div`
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;

  & button {
    width: 100%;

    @media screen and (min-width: 600px) {
      margin-top: 13px;
    }
  }
`;

const SearchResults = styled.ul`
  & > li {
    margin: 5px;
  }
`;

const SearchLabel = styled.div`
  margin-bottom: 3px;
  margin-top: 2px;
`;

export default {
  SearchContent,
  SearchResults,
  FieldContainer,
  Search,
  SearchFields,
  Title,
  SearchLabel
};
