import React, { useState } from "react";
import Async from "react-select/async";
import VR from "../../../../utils/ValidationResult";
import { useTranslation } from "react-i18next";
import S from "./styles";
import debounce from "debounce-promise";
import { useApi, isNoContent } from "../../../../api/Api";
import Spinner from "../../../spinner/Spinner";

type LocationOption = {
  location: string;
};

const getOptionLabel = (option: LocationOption) => option.location;
const getOptionValue = (option: LocationOption) => option.location;

const isLocationOption = (
  o: LocationOption | readonly LocationOption[] | undefined | null
): o is LocationOption => {
  return o !== undefined && o !== null && !Array.isArray(o);
};

type Props = {
  reference: string;
  onComplete: (location: string) => void;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
  initialLocation: string | null;
};

const ManualLocation = (props: Props) => {
  const { onCancel, onBackClick, onComplete, onFail } = props;
  const [location, setLocation] = useState<string>(props.initialLocation || "");
  const [service] = useState<google.maps.places.AutocompleteService>(
    new google.maps.places.AutocompleteService()
  );
  const [sessionToken] = useState<google.maps.places.AutocompleteSessionToken>(
    new google.maps.places.AutocompleteSessionToken()
  );
  const [vr, setVr] = useState<VR>(VR.empty);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const api = useApi();

  const submitLocation = async (reference: string, location: string) =>
    api.post(`api/breakdowns/${reference}/location`, "1.0", {
      location,
      coordinates: null,
    });

  const getLocations = async (input: string): Promise<LocationOption[]> => {
    return new Promise((resolve, _) => {
      service.getPlacePredictions(
        {
          input,
          sessionToken,
        },
        (predictions: google.maps.places.AutocompletePrediction[] | null) => {
          resolve(
            (predictions &&
              predictions.map((p) => ({
                location: p.description,
              }))) ||
              []
          );
        }
      );
    });
  };

  const loadOptions = async (
    inputValue: string,
    callback: (locations: LocationOption[]) => void
  ) => {
    if (inputValue.length < 3) return callback([]);

    return callback(await getLocations(inputValue));
  };

  const debounced = debounce(loadOptions, 2.5e2);

  const handleChange = (
    option: LocationOption | readonly LocationOption[] | undefined | null
  ) => {
    if (isLocationOption(option)) {
      setLocation(option.location);
    }
  };

  const handleContinue = async () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    try {
      setLoading(true);
      const response = await submitLocation(props.reference, location);
      setLoading(false);

      if (isNoContent(response)) return onComplete(location);
    } catch {}

    onFail();
  };

  const validate = () => {
    const vr = VR.empty;

    if (!location || !location.trim()) {
      vr.add("location", t("Field is required."));
    }

    if (location && location.length > 400) {
      vr.add(
        "location",
        t("Maximum length is {{len}} characters.", { len: 400 })
      );
    }

    return vr;
  };

  var errors = vr.getErrors("location") || [];

  if (loading) return <Spinner />;

  const handleLocationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setLocation(e.target.value);

  return (
    <S.Container>
      <label>{t("Location")}</label>
      <Async
        cacheOptions
        loadOptions={debounced}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={handleChange}
        value={null}
        placeholder={t("Search...")}
        styles={{
          control: (provided, _) => ({
            ...provided,
            width: "16rem",
          }),
          valueContainer: (provided, _) => ({
            ...provided,
            padding: "2px 5px",
          }),
          indicatorSeparator: (provided, _) => ({
            ...provided,
            display: "none",
          }),
          indicatorsContainer: (provided, _) => ({
            ...provided,
            display: "none",
          }),
        }}
      />
      <S.TextArea
        errors={errors}
        value={location}
        onChange={handleLocationChange}
      />
      <S.Button onClick={handleContinue}>{t("Continue")}</S.Button>
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
      <S.Button onClick={onCancel}>{t("Save for later or cancel")}</S.Button>
    </S.Container>
  );
};

export default ManualLocation;
