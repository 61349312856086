import React from "react";
import S from "./styles";
import { faThumbsUp, faPhone } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  onContinue: () => void;
  phoneNumber: string;
};

const ContactTSU = (props: Props) => {
  return (
    <S.ContactTSU>
      <S.Icon icon={faThumbsUp} size={"4x"}></S.Icon>
      <S.Header>Job Completed Successfully</S.Header>
      <S.Detail>
        Please contact TSU to provide the final details of the job.
      </S.Detail>
      <S.Button>
        <S.PhoneIcon href={`tel:${props.phoneNumber}`} icon={faPhone} />
        {props.phoneNumber}
      </S.Button>
      <S.Button onClick={props.onContinue}>Return To Menu</S.Button>
    </S.ContactTSU>
  );
};

export default ContactTSU;
