import React from "react";
import S from "./styles";
import Modal from "../Modal";

type Props = {
  src: string;
  position?: string;
  onClose: () => void;
};

const ImageViewer = (props: Props) => {
  const { src, position, onClose } = props;

  return (
    <Modal onClose={onClose}>
      <S.Parent>
        {position && <S.Position>{position}</S.Position>}
        <S.Image src={src} />
      </S.Parent>
    </Modal>
  );
};

export default ImageViewer;
