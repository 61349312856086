import { useEffect, useRef } from "react";

const useInterval = (
  callback: () => void,
  delay: number,
  frontload: boolean
) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }

    if (frontload) tick();

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, frontload]);
};

export default useInterval;
