import styled from "styled-components";
import colours from "../../../utils/colours";

const SubMenu = styled.div`
  background-color: ${colours.grey3};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
`;

export default { SubMenu };
