import styled from "styled-components";
import _Select from "../../select/Select";
import _Button from "../../button/Button";

const AllocatedJob = styled.div`
  width: 100%;
  height: 100%;
  max-width: 700px;
`;

const Title = styled.div`
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  margin-bottom: 5px;
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const Select = styled(_Select)`
  min-width: 16rem;
  margin-top: 5px;
`;

const Filters = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;

const UserJobsCheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  & span {
    margin-left: 3px;
  }
}
`;

export default {
  AllocatedJob,
  Button,
  Title,
  Select,
  Filters,
  UserJobsCheckBox
};
