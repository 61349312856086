import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 30px;
  padding: 2px;
`;

export default { Icon };
