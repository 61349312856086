import styles from "styled-components";
import _Button from "../../button/Button";

const Home = styles.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Details = styles.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const Title = styles.div`
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

const Wrapper = styles.div`
  display: flex;
  width: 100%;
`;

const Button = styles(_Button)`
  width: 16rem;
  margin-top: 3px;
`;

export default { Home, Details, Title, Wrapper, Button };
