import React, { useState } from "react";
import { useApi, isNoContent, isFailure } from "../../../api/Api";
import S from "./styles";
import Spinner from "../../spinner/Spinner";
import { useTranslation } from "react-i18next";
import DateTimeInput from "../../date-time-input/DateTimeInput";
import VR from "../../../utils/ValidationResult";
import formatISO from "date-fns/formatISO";

type Props = {
  onContinue: (onSceneTime: Date) => void;
  jobNumber: number;
  onReturn: () => void;
  onFail: (errors?: string[]) => void;
};

const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

const OnScene = (props: Props) => {
  const [arrivedTime, setArrivedTime] = useState<Date>(new Date());
  const { onContinue, onFail } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [vr, setVr] = useState<VR>(VR.empty);
  const api = useApi();

  const submitOnSceneTime = (jobNumber: number, time: Date) =>
    api.post(`api/allocated-jobs/${jobNumber}/on-scene`, "1.0", {
      time: formatISO(time),
    });

  const handleChange = (value: Date) => {
    setArrivedTime(value); //change this
  };

  const validate = (): VR => {
    const result = VR.empty;

    if (!isValidDate(arrivedTime))
      result.add("arrivedTime", t("You must enter a date and time"));

    if (arrivedTime > new Date())
      result.add("arrivedTime", t("Date must not be in the future."));

    return result;
  };

  const handleContinue = async () => {
    const vr = validate();
    setVr(vr);
    if (!vr.ok) return;

    try {
      setLoading(true);
      const response = await submitOnSceneTime(
        props.jobNumber,
        new Date(arrivedTime)
      );
      setLoading(false);

      if (isFailure(response)) {
        return onFail(response.errors);
      }

      if (isNoContent(response)) {
        return onContinue(new Date(arrivedTime));
      }
    } catch {}

    onFail();
  };

  if (loading) return <Spinner></Spinner>;

  return (
    <S.OnScene>
      <S.Title>{t("Time Arrived")}</S.Title>
      <DateTimeInput
        onChange={handleChange}
        value={arrivedTime}
        errors={vr.getErrors("arrivedTime")}
      ></DateTimeInput>
      <S.Button onClick={handleContinue}>{t("Continue")}</S.Button>
      <S.Button onClick={props.onReturn}>{t("Return to job")}</S.Button>
    </S.OnScene>
  );
};

export default OnScene;
