import React, { useState } from "react";
import ReasonItem from "./ReasonItem";
import S from "./styles";
import VR from "../../utils/ValidationResult";
import { useTranslation } from "react-i18next";
import DateTimeInput from "../date-time-input/DateTimeInput";

type Props = {
  onContinue: (selectedId: number, time: Date) => void;
  reasons: ReasonItem[];
  title: string;
};

const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

const TimeReason = (props: Props) => {
  const { onContinue, reasons, title } = props;
  const [reasonId, setReasonId] = useState<number | null>(null);

  const [date, setDate] = useState<Date>(new Date());
  const [vr, setVr] = useState<VR>(VR.empty);
  const handleReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(e.target.value);
    setReasonId(id);
  };
  const handleTimeChange = (value: Date) => {
    setDate(value);
  };
  const handleContinueClick = () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    onContinue(reasonId!, date);
  };
  const { t } = useTranslation();

  const validate = (): VR => {
    const vr = VR.empty;

    if (!reasonId) vr.add("reason", t("Field is required."));
    if (!isValidDate(date)) vr.add("date", t("You must enter a date and time"));
    if (date > new Date()) vr.add("date", t("Date must not be in the future."));

    return vr;
  };

  const orderedReasons = reasons.sort((a, b) =>
    a.description.localeCompare(b.description)
  );

  return (
    <S.TimeReason>
      <label htmlFor="reason">{t(`Reason for ${title}`)}</label>
      <S.Select
        id="customer"
        value={reasonId || ""}
        onChange={handleReasonChange}
        errors={vr.getErrors("reason")}
      >
        <option value="" disabled hidden></option>
        {orderedReasons
          .sort((a, b) => a.id - b.id)
          .map(c => (
            <option key={c.id} value={c.id}>
              {c.description}
            </option>
          ))}
      </S.Select>
      <DateTimeInput
        onChange={handleTimeChange}
        value={date}
        errors={vr.getErrors("date")}
      ></DateTimeInput>
      <S.Button onClick={handleContinueClick}>{t("Continue")}</S.Button>
    </S.TimeReason>
  );
};

export default TimeReason;
