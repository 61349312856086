import styled from "styled-components";
import _Select from "../select/Select";
import _Button from "../button/Button";
import _Input from "../input/Input";

const Select = styled(_Select)`
  width: 16rem;
`;

const Button = styled(_Button)`
  margin-top: 3px;
  width: 16rem;
`;

const Input = styled(_Input)`
  width: 16rem;
`;

const TimeReason = styled.div`
  & > * {
    display: block;
    margin-bottom: 5px;
  }

  max-width: 16rem;
`;

export default { TimeReason, Select, Button, Input };
