import _Button from "../../../button/Button";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button,
  & > label,
  & > p {
    margin-bottom: 5px;
  }

  & > p {
    width: 100%;
    text-align: center;
  }

  & .map {
    min-height: 16rem;
    min-width: 16rem;
    border-radius: 5px;
    margin-bottom: 5px;
    height: 55vh;
    width: 56vw;
  }
`;
const Button = styled(_Button)`
  width: 16rem;
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 16rem;
  width: 16rem;
  border-radius: 5px;
  margin-bottom: 5px;
`;

export default { Container, Button, LoadingContainer };
