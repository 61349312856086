import React, { useState } from "react";
import ManualLocation from "./manual-location/ManualLocation";
import AutomaticLocation from "./automatic-location/AutomaticLocation";
import { Location as LC } from "360";

type Props = {
  onComplete: (location: LC) => void;
  initialValue: LC | null;
  onFail: () => void;
  reference: string;
  useAutomaticLocation: boolean;
  onBackClick: () => void;
  onCancel: () => void;
};

const Location = (props: Props) => {
  const { onCancel, onComplete, onFail, onBackClick, initialValue } = props;
  const [enteringManualLocation, setEnteringManualLocation] = useState<boolean>(
    !props.useAutomaticLocation
  );
  const [manualLocation, setManualLocation] = useState<string | null>(
    initialValue && initialValue.location
  );

  const handleManualLocationComplete = (location: string) =>
    onComplete({ latitude: null, longitude: null, location });
  const handleAutomaticLocationComplete = (
    latitude: number,
    longitude: number,
    location: string
  ) => onComplete({ latitude, longitude, location });

  return enteringManualLocation ? (
    <ManualLocation
      onComplete={handleManualLocationComplete}
      onFail={onFail}
      reference={props.reference}
      initialLocation={manualLocation}
      onBackClick={onBackClick}
      onCancel={onCancel}
    />
  ) : (
    <AutomaticLocation
      onComplete={handleAutomaticLocationComplete}
      onManualLocation={(initialLocation) => {
        setManualLocation(initialLocation);
        setEnteringManualLocation(true);
      }}
      reference={props.reference}
      onFail={onFail}
      onBackClick={onBackClick}
      onCancel={onCancel}
    />
  );
};

export default Location;
