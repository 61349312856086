import styled from "styled-components";
import _Button from "../../../button/Button";
import colours from "../../../../utils/colours";

const Configuration = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    display: block;
    margin-bottom: 5px;
  }
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const Grid = styled.div`
  border-radius: 5px;
  display: grid;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 4em);
  grid-gap: 2px;

  & .pos1 {
    grid-column: 1;
  }

  & .pos2 {
    grid-column: 2;
  }

  & .pos3 {
    grid-column: 3;
  }

  & .pos4 {
    grid-column: 4;
  }

  & .pos5 {
    grid-column: 5;
  }

  & .axle1 {
    grid-row: 1;
  }

  & .axle2 {
    grid-row: 2;
  }

  & .axle3 {
    grid-row: 3;
  }

  & .axle4 {
    grid-row: 4;
  }

  & .axle5 {
    grid-row: 5;
  }
`;

type PositionProps = {
  hasWork: boolean;
};

const Position = styled.div<PositionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.hasWork ? colours.lightOrange : colours.grey3};
  height: 4em;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.hasWork ? colours.darkOrange : colours.grey2};
  }
`;

export default { Grid, Position, Configuration, Button };
