import styled from "styled-components";
import colours from "../../../utils/colours";

const Parent = styled.div`
  position: relative;
  text-align: center;
`;

const Image = styled.img`
  max-height: 90vh;
  max-width: 90vw;
`;

const Position = styled.span`
  font-size: 1.5em;
  padding: 0.4em;
  position: absolute;
  top: 5px;
  left: 5px;
  background: rgb(0, 0, 0, 0.75);
  color: ${colours.white};
  border-radius: 5px;
`;

export default { Parent, Image, Position };
