import styled from "styled-components";
import colours from "../../../utils/colours";

const MainMenu = styled.div`
  background-color: ${colours.lightOrange};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
`;

const Image = styled.img`
  max-width: 36px;
  max-height: 36px;
  margin-right: 5px;
`;

export default { MainMenu, Image };
