import React, { useState, useEffect } from "react";
import S from "./styles";
import AsyncCreatable from "react-select/async-creatable";
import { ValueType, OptionsType } from "react-select";
import { useTranslation } from "react-i18next";
import VR from "../../../../../../utils/ValidationResult";
import colours from "../../../../../../utils/colours";
import debounce from "debounce-promise";
import Spinner from "../../../../../spinner/Spinner";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useApi, isSuccess } from "../../../../../../api/Api";

type Props = {
  reference: string;
  positionId: number;
  onSelect: (tyreSize: string) => void;
  isSkippable: boolean;
  onSkip: () => void;
  onBackClick: () => void;
};

type TyreSize = {
  tyreSize: string;
};

const getNewOptionData = (
  inputValue: string,
  _: React.ReactNode
): TyreSize => ({ tyreSize: inputValue });

const getOptionLabel = (option: TyreSize) => option.tyreSize;
const getOptionValue = (option: TyreSize) => option.tyreSize;

const isValidNewOption = (
  inputValue: string,
  _: ValueType<TyreSize>,
  selectOptions: OptionsType<TyreSize>
) =>
  selectOptions
    .map((o) => o.tyreSize.toLowerCase())
    .indexOf(inputValue.toLowerCase()) === -1;

const isTyreSize = (
  o: TyreSize | readonly TyreSize[] | undefined | null
): o is TyreSize => {
  return o !== undefined && o !== null && !Array.isArray(o);
};

const TyreSize = (props: Props) => {
  const {
    onBackClick,
    onSelect,
    isSkippable,
    onSkip,
    reference,
    positionId,
  } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [defaultTyreSize, setDefaultTyreSize] = useState<string | null>(null);
  const [tyreSize, setTyreSize] = useState<TyreSize | null>(null);
  const [vr, setVr] = useState<VR>(VR.empty);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<string | null>(
          `api/breakdowns/${reference}/default-tyre-sizes`,
          "1.0",
          { positionId }
        );
        setLoading(false);

        if (isSuccess(response)) {
          setDefaultTyreSize(response.data);

          if (response.data) setTyreSize({ tyreSize: response.data });
        }
      } catch {
        // if we couldn't get a default tyre size, just allow the user to select one.
      }
    })();
  }, [api, positionId, reference]);

  const loadOptions = async (
    inputValue: string,
    callback: (sizes: TyreSize[]) => void
  ) => {
    if (inputValue.length < 3) return callback([]);

    try {
      const response = await api.get<string[]>("api/tyre-sizes/search", "1.0", {
        tyreSize: inputValue,
      });

      if (isSuccess(response)) {
        const results: TyreSize[] = [];
        response.data.forEach((size) => results.push({ tyreSize: size }));
        return callback(results);
      }
    } catch {
      // if we couldn't search for search for tyre sizes, just allow the user to manually
      // enter one.
    }
  };

  const handleChange = (
    option: TyreSize | readonly TyreSize[] | undefined | null
  ) => {
    if (isTyreSize(option) || option === null) {
      setTyreSize(option);
    }
  };

  const debounced = debounce(loadOptions, 2.5e2);

  const handleCreate = (tyreSize: string) => {
    setTyreSize({ tyreSize });
  };

  const handleContinue = () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) {
      return;
    }

    return tyreSize !== null ? onSelect(tyreSize.tyreSize.trim()) : onSkip();
  };

  const validate = () => {
    const vr = VR.empty;

    const tyreSizeIsSelected = tyreSize !== null && tyreSize.tyreSize.trim();

    if (!isSkippable && !tyreSizeIsSelected) {
      vr.add("tyreSize", t("Field is required."));
    }

    if (tyreSizeIsSelected && tyreSize!.tyreSize.length > 20) {
      vr.add(
        "tyreSize",
        t("Maximum length is {{len}} characters.", { len: 20 })
      );
    }

    return vr;
  };

  const doesNotMatchHistory =
    defaultTyreSize !== null &&
    tyreSize &&
    defaultTyreSize !== tyreSize.tyreSize;

  var errors = vr.getErrors("tyreSize") || [];

  if (loading) return <Spinner />;

  return (
    <S.TyreSize>
      <label>{t("Tire size")}</label>
      <AsyncCreatable
        cacheOptions
        loadOptions={debounced}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        placeholder={t("Search...")}
        onChange={handleChange}
        onCreateOption={handleCreate}
        getNewOptionData={getNewOptionData}
        isClearable={true}
        value={tyreSize}
        isValidNewOption={isValidNewOption}
        styles={{
          control: (provided, _) => ({
            ...provided,
            width: "16rem",
            borderColor: errors.length > 0 ? colours.lightRed : colours.black,
            background:
              errors.length > 0 ? colours.extraLightRed : colours.white,
          }),
          valueContainer: (provided, _) => ({
            ...provided,
            padding: "2px 5px",
          }),
        }}
      />
      {!vr.ok && <S.FieldErrors errors={errors} />}
      {doesNotMatchHistory && <DoesNotMatchHistoryWarning />}
      <S.Button onClick={handleContinue}>
        {t(tyreSize === null && isSkippable ? "Skip" : "Continue")}
      </S.Button>
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
    </S.TyreSize>
  );
};

const DoesNotMatchHistoryWarning = () => {
  const { t } = useTranslation();

  return (
    <S.Warning>
      <S.WarningIcon icon={faExclamationTriangle} />
      <span>
        {t(
          "The selected tire size does not match our history for this vehicle."
        )}
      </span>
    </S.Warning>
  );
};

export default TyreSize;
