import React from "react";
import S from "./styles";
import Icon from "../icon/Icon";
import { faPhone, faHome } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  onPhoneClick: () => void;
  onHomeClick: () => void;
};

const SubMenu = (props: Props) => {
  const { onPhoneClick, onHomeClick } = props;

  return (
    <S.SubMenu>
      <Icon icon={faPhone} onClick={onPhoneClick} />
      <Icon icon={faHome} onClick={onHomeClick} />
    </S.SubMenu>
  );
};

export default SubMenu;
