import React from "react";
import S from "./styles";
import contiLogo from "./6410_Conti_360_Logo_RGB_Black.png";
import trukfixLogo from "./trukfix-logo-data.png";

const MainMenu = () => {
  return (
    <S.MainMenu>
      <S.Image src={contiLogo} alt="360° logo" />
      <S.Image src={trukfixLogo} alt="Trukfix logo" />
    </S.MainMenu>
  );
};

export default MainMenu;
