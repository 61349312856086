import styled from "styled-components";
import colours from "../../../../../utils/colours";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    display: block;
    margin-bottom: 5px;
  }

  & > button,
  & > label,
  & > p {
    width: 16rem;
  }
`;

const LoadingText = styled.p`
  padding: 5px;
  color: ${colours.grey2};
  text-align: center;
`;

export default { Container, LoadingText };
