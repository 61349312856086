import React from "react";
import S from "./styles";
import JobCategory from "../job-category/JobCategory";
import { faClipboardList } from "@fortawesome/pro-solid-svg-icons";
import JobSubCategory from "../job-sub-category/JobSubCategory";
import { useTranslation } from "react-i18next";

type Props = {
  showForSummary: boolean;
  tsuNumber: number;
  jobDate?: Date;
  poNumber: string | null;
  customer?: string;
  mileage: number | null;

  locationType: string;
  vehicleReg: string;
  attachedVehicleReg: string | null;

  mileageEditClicked?: () => void;
  locationTypeEditClick?: () => void;
  customerEditClicked?: () => void;
  vehicleEditClicked?: () => void;
  podnEditClicked?: () => void;
};

const JobOverview = (props: Props) => {
  const { t } = useTranslation();

  return (
    <JobCategory icon={faClipboardList} title={t("Job overview")}>
      {!props.showForSummary && (
        <div>
          <JobSubCategory onEditClicked={props.mileageEditClicked}>
            <S.JobOverview>
              <S.DetailContainer>
                <S.Detail>{t("TSU number")}</S.Detail>
                <S.DetailContent>{props.tsuNumber}</S.DetailContent>
              </S.DetailContainer>
            </S.JobOverview>
          </JobSubCategory>

          {props.jobDate !== undefined ? (
            <JobSubCategory onEditClicked={props.mileageEditClicked}>
              <S.JobOverview>
                <S.DetailContainer>
                  <S.Detail>{t("Job Date")}</S.Detail>
                  <S.DetailContent>
                    {t("DisplayDate", { date: props.jobDate })}
                  </S.DetailContent>
                </S.DetailContainer>
              </S.JobOverview>
            </JobSubCategory>
          ) : (
            <div></div>
          )}
        </div>
      )}

      {props.showForSummary && (
        <React.Fragment>
          <JobSubCategory onEditClicked={props.podnEditClicked}>
            <S.JobOverview>
              <S.DetailContainer>
                <S.Detail>{t("Purchase order / defect number")}</S.Detail>
                <S.DetailContent>{props.poNumber}</S.DetailContent>
              </S.DetailContainer>
            </S.JobOverview>
          </JobSubCategory>

          <JobSubCategory onEditClicked={props.mileageEditClicked}>
            <S.JobOverview>
              <S.DetailContainer>
                <S.Detail>{t("Mileage")}</S.Detail>
                <S.DetailContent>{props.mileage}</S.DetailContent>
              </S.DetailContainer>
            </S.JobOverview>
          </JobSubCategory>
        </React.Fragment>
      )}

      <JobSubCategory onEditClicked={props.locationTypeEditClick}>
        <S.JobOverview>
          <S.DetailContainer>
            <S.Detail>{t("Location type")}</S.Detail>
            <S.DetailContent>{t(props.locationType)}</S.DetailContent>
          </S.DetailContainer>
        </S.JobOverview>
      </JobSubCategory>

      {props.customer !== undefined ? (
        <JobSubCategory onEditClicked={props.customerEditClicked}>
          <S.DetailContainer>
            <S.Detail>{t("Customer")}</S.Detail>
            <S.DetailContent>{props.customer}</S.DetailContent>
          </S.DetailContainer>
        </JobSubCategory>
      ) : (
        <div></div>
      )}

      <JobSubCategory onEditClicked={props.vehicleEditClicked}>
        <S.DetailContainer>
          <S.Detail>{t("Vehicle / unit number")}</S.Detail>
          <S.DetailContent>{props.vehicleReg}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Attached vehicle")}</S.Detail>
          <S.DetailContent>{props.attachedVehicleReg}</S.DetailContent>
        </S.DetailContainer>
      </JobSubCategory>
    </JobCategory>
  );
};

export default JobOverview;
