import styled from "styled-components";
import _Button from "../button/Button";

const Home = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

const Link = styled.a`
  color: black;
  text-decoration: none;

  &:visited {
    color: black;
  }
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const Text = styled.p`
  margin-bottom: 5px;
`;

export default { Home, Grid, Link, Button, Text };
