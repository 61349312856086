import styled from "styled-components";
import Button from "../../button/Button";
import colours from "../../../utils/colours";

const JobSubCategory = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colours.grey3 + "44"};
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;

const EditButton = styled(Button)`
  align-self: flex-end;
  margin-top: 5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 4rem;

  background-color: ${colours.grey3};

  &:hover {
    background: ${colours.grey2};
  }
`;

export default {
  JobSubCategory,
  EditButton,
  Content
};
