import React from "react";
import MainMenu from "./main-menu/MainMenu";
import SubMenu from "./sub-menu/SubMenu";
import S from "./styles";

type Props = {
  onPhoneClick: () => void;
  onHomeClick: () => void;
};

const Menu = (props: Props) => {
  const { onPhoneClick, onHomeClick } = props;
  return (
    <S.MenuContainer>
      <MainMenu />
      <SubMenu onHomeClick={onHomeClick} onPhoneClick={onPhoneClick} />
    </S.MenuContainer>
  );
};

export default Menu;
