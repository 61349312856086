import React, { useState, useEffect } from "react";
import { Configuration as Config } from "360";
import { useTranslation } from "react-i18next";
import S from "./styles";
import GeneralErrors from "../../general-errors/GeneralErrors";
import Spinner from "../../spinner/Spinner";
import Job from "../../job-details/Job";
import Gallery from "../gallery/Gallery";
import { useApi, isSuccess } from "../../../api/Api";
import Photo from "../../job-details/Photo";
import { useUserContext } from "../../../contexts/UserContext";

type Props = {
  configuration: Config;
  job: Job;
  onContinue: () => void;
  onChangeConfig: () => void;
  onFail: (errors?: string[]) => void;
};

type JobResponse = {
  key: string;
  job: Job;
};

const Configuration = (props: Props) => {
  const { onFail, onContinue, onChangeConfig, job, configuration } = props;
  const [editingPositionId, setEditingPositionId] = useState<number | null>(
    null
  );
  const [
    editingPositionDescription,
    setEditingPositionDescription,
  ] = useState<string>("");
  const [positionWithWork, setPositionsWithWork] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentJob, setCurrentJob] = useState<Job>(props.job);
  const { t } = useTranslation();
  const api = useApi();
  const user = useUserContext();

  const finishedAddingPhotos = (photosAddedCount: number) => {
    var photos: Photo[] = [];

    for (let index = 0; index < photosAddedCount; index++) {
      var newPhoto = {
        id: 0,
        position: editingPositionDescription,
        addedById: user.id,
      };
      photos.push(newPhoto);
    }

    var updatedJob = currentJob;
    updatedJob.photos = updatedJob.photos.concat(photos);
    var positions = updatedJob.photos.map((p) => p.position);

    setCurrentJob(updatedJob);
    setEditingPositionId(null);
    setEditingPositionDescription("");
    setPositionsWithWork(positions);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<JobResponse>(
          `api/jobs/${job.id}`,
          "1.0"
        );
        setLoading(false);

        if (isSuccess(response)) {
          var photos = response.data.job.photos;
          var positions = photos.map((p) => p.position);
          var updatedJob = currentJob;
          updatedJob.photos = photos;
          setCurrentJob(updatedJob);
          setEditingPositionId(null);
          setEditingPositionDescription("");
          return setPositionsWithWork(positions);
        }
      } catch {}

      onFail();
    })();
  }, [api, job.id, currentJob, onFail]);

  const photoSlotsLeft = (job: Job, position: string): number => {
    var p = job.photos
      .filter((p) => p.addedById === user.id)
      .filter((p) => p.position === position).length;
    return p;
  };

  const positions = [];

  for (const axleNumber in configuration.axles) {
    const axle = configuration.axles[axleNumber];

    for (const positionNumber in axle.positions) {
      const position = axle.positions[positionNumber];

      positions.push(
        <S.Position
          className={`axle${axleNumber} pos${positionNumber}`}
          key={position.id}
          hasWork={positionWithWork.indexOf(position.description) > -1}
          onClick={() => {
            setEditingPositionId(position.id);
            setEditingPositionDescription(position.description);
          }}
        >
          {position.description}
        </S.Position>
      );
    }
  }

  if (loading) return <Spinner />;
  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  return editingPositionId === null ? (
    <S.Configuration>
      <S.Grid>{positions}</S.Grid>
      {currentJob.configId === null && (
        <S.Button onClick={onChangeConfig}>
          {t("Change configuration")}
        </S.Button>
      )}

      <S.Button onClick={onContinue}>{t("Return to Menu")}</S.Button>
    </S.Configuration>
  ) : (
    <Gallery
      jobId={job.id}
      positionId={editingPositionId}
      onContinue={finishedAddingPhotos}
      userPhotoCount={photoSlotsLeft(currentJob, editingPositionDescription)}
      onReturn={() => {
        setEditingPositionId(null);
        setEditingPositionDescription("");
      }}
      onFail={onFail}
    ></Gallery>
  );
};

export default Configuration;
