import styles from "styled-components";
import _Select from "../../select/Select";
import _Button from "../../button/Button";

const JobCancelled = styles.div`
display: flex;
flex-direction: column;
`;

const Title = styles.label`
margin-bottom: 5px;
`;

const Select = styles(_Select)`
  width: 16rem;
  margin-bottom: 3px;
`;

const Button = styles(_Button)`
width: 16rem;
margin-bottom: 3px;
`;

export default { JobCancelled, Select, Button, Title };
