import React, { useState } from "react";
import { Api, useApi, isSuccess, Response } from "../../../api/Api";
import { ValueType, OptionsType } from "react-select";
import Creatable from "react-select/creatable";
import ValidationResult from "../../../utils/ValidationResult";
import { useTranslation } from "react-i18next";
import colours from "../../../utils/colours";
import FieldErrors from "../../field-errors/FieldErrors";
import S from "./styles";

const getPatterns = (
  api: Api,
  size: string,
  make: string
): Promise<Response<string[]>> =>
  api.get("api/tyre-patterns", "1.0", { size, make });

type Option = { value: string };

const isOption = (
  o: Option | readonly Option[] | undefined | null
): o is Option => o !== undefined && o !== null && !Array.isArray(o);

const isValidNewOption = (
  inputValue: string,
  _: ValueType<Option>,
  selectOptions: OptionsType<Option>
) =>
  selectOptions
    .map((o) => o.value.toLowerCase())
    .indexOf(inputValue.toLowerCase()) === -1;

type Props = {
  size: string;
  makes: string[];
  onComplete: (make: string, pattern: string) => void;
};

const FittedDetails = (props: Props) => {
  const { size, makes, onComplete } = props;
  const [make, setMake] = useState<Option | null>(null);
  const [pattern, setPattern] = useState<Option | null>(null);
  const [patterns, setPatterns] = useState<string[]>([]);
  const [validationResult, setValidationResult] = useState<ValidationResult>(
    ValidationResult.empty
  );
  const api = useApi();
  const { t } = useTranslation();

  const handleContinueClick = () => {
    const vr = validate();
    setValidationResult(vr);
    if (!vr.ok) {
      return;
    }

    if (!make || !pattern) {
      throw new Error("attempted to submit null make or pattern");
    }

    onComplete(make.value, pattern.value);
  };

  const handleMakeChange = async (
    option: Option | readonly Option[] | undefined | null
  ) => {
    if (isOption(option)) {
      setMake(option);
      setPattern(null);
      setPatterns([]);

      try {
        const response = await getPatterns(api, size, option.value.trim());
        if (isSuccess(response)) {
          setPatterns(response.data);
        }
      } catch {
        // if we fail to get a list of patterns, allow the user to enter one manually
        // rather than failing
      }
    }
  };

  const handleMakeCreate = (make: string) => {
    const trimmed = make.trim();
    if (trimmed) {
      setMake({ value: trimmed });
      setPattern(null);
    }
  };

  const handlePatternChange = (
    option: Option | readonly Option[] | undefined | null
  ) => {
    if (isOption(option)) {
      setPattern(option);
    }
  };

  const handlePatternCreate = (pattern: string) => {
    const trimmed = pattern.trim();
    if (trimmed) {
      setPattern({ value: trimmed });
    }
  };

  const validate = () => {
    const vr = ValidationResult.empty;

    if (make === null || !make.value) {
      vr.add("make", "Field is required.");
    }

    if (pattern === null || !pattern.value) {
      vr.add("pattern", "Field is required.");
    }

    return vr;
  };

  const makeErrors = validationResult.getErrors("make") || [];
  const patternErrors = validationResult.getErrors("pattern") || [];

  return (
    <S.WorkComplete>
      <label>{t("Fitted make")}</label>
      <Creatable
        options={makes.map((x) => ({
          value: x,
        }))}
        getOptionLabel={(x) => x.value}
        placeholder={t("Search...")}
        onChange={handleMakeChange}
        onCreateOption={handleMakeCreate}
        value={make}
        isValidNewOption={isValidNewOption}
        styles={{
          control: (provided, _) => ({
            ...provided,
            width: "16rem",
            borderColor:
              makeErrors.length > 0 ? colours.lightRed : colours.black,
            background:
              makeErrors.length > 0 ? colours.extraLightRed : colours.white,
          }),
          valueContainer: (provided, _) => ({
            ...provided,
            padding: "2px 5px",
          }),
        }}
      />
      {!validationResult.ok && <FieldErrors errors={makeErrors} />}
      <label>{t("Fitted pattern")}</label>
      <Creatable
        options={patterns.map((x) => ({
          value: x,
        }))}
        getOptionLabel={(x) => x.value}
        placeholder={t("Search...")}
        onChange={handlePatternChange}
        onCreateOption={handlePatternCreate}
        value={pattern}
        isValidNewOption={isValidNewOption}
        styles={{
          control: (provided, _) => ({
            ...provided,
            width: "16rem",
            borderColor:
              patternErrors.length > 0 ? colours.lightRed : colours.black,
            background:
              patternErrors.length > 0 ? colours.extraLightRed : colours.white,
          }),
          valueContainer: (provided, _) => ({
            ...provided,
            padding: "2px 5px",
          }),
        }}
      />
      {!validationResult.ok && <FieldErrors errors={patternErrors} />}
      <S.Button onClick={handleContinueClick}>{t("Continue")}</S.Button>
    </S.WorkComplete>
  );
};

export default FittedDetails;
