import styled from "styled-components";

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Detail = styled.span`
  margin-bottom: 5px;
`;

const DetailContent = styled.span`
  margin-bottom: 5px;
  margin-left: 5px;
`;

export default { DetailContainer, Detail, DetailContent };
