import styled from "styled-components";
import colours from "../../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Bubble = styled.li`
  border-radius: 5px;
  max-width: 700px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const NotificationContent = styled.div`
  padding: 5px 0px;
`;

const NotificationSpacer = styled.hr`
  border: 1px solid #b0afb1;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const NotificationIcon = styled(FontAwesomeIcon)`
  font-size: 48px;
`;

const GreenIcon = styled(NotificationIcon)`
  color: ${colours.darkGreen};
`;

const YellowIcon = styled(NotificationIcon)`
  color: ${colours.yellow};
`;

const IconWrapper = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationDate = styled.p`
  color: gray
  font-size: 13px;
  width: 100%
  text-align:right;
`;

const NoticeBubble = styled(Bubble)`
  background-color: ${colours.pastelGreen};
`;

const WarningBubble = styled(Bubble)`
  background-color: ${colours.lightYellow};
`;

const NotificationDetails = styled.span`
  margin-right: 10px;
  margin-left: 5px;
`;

const BubbleContent = styled.div`
  padding: 5px;
`;

export default {
  NoticeBubble,
  WarningBubble,
  NotificationDetails,
  GreenIcon,
  YellowIcon,
  NotificationDate,
  NotificationSpacer,
  NotificationContent,
  IconWrapper,
  BubbleContent
};
