import React from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  position: string;
  onAddWork: () => void;
  onChangeWork: () => void;
  onRemoveWork: () => void;
  onCancel: () => void;
  hasExistingWork: boolean;
};

const Prompt = (props: Props) => {
  const {
    position,
    onAddWork,
    onChangeWork,
    onRemoveWork,
    onCancel,
    hasExistingWork,
  } = props;
  const { t } = useTranslation();

  return (
    <S.Prompt>
      <S.PromptText>
        {t("You have selected position {{position}}.", { position })}
      </S.PromptText>
      {!hasExistingWork && (
        <S.Button onClick={onAddWork}>{t("Add work")}</S.Button>
      )}
      {hasExistingWork && (
        <S.Button onClick={onChangeWork}>{t("Change existing work")}</S.Button>
      )}
      {hasExistingWork && (
        <S.Button onClick={onRemoveWork}>{t("Remove existing work")}</S.Button>
      )}
      <S.Button onClick={onCancel}>{t("Back")}</S.Button>
    </S.Prompt>
  );
};

export default Prompt;
