import React from "react";
import S from "./styles";
import JobLocationType from "../../job-location-type/job-location-type-icon/JobLocationTypeIcon";
import Button from "../../button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faClipboardList } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { JobLocationType as LocationType, JobStatus } from "360";
import JobStatusIcon from "../../job-status/JobStatusIcon";

type Props = {
  id: number;
  registration: string;
  locationType: LocationType | null;
  jobStatus: JobStatus | null;
  onViewJob: (id: number) => void;
};

const JobResult = (props: Props) => {
  const { id, registration, locationType, onViewJob, jobStatus } = props;
  const { t } = useTranslation();

  return (
    <S.JobListing>
      <S.JobDetails>
        <S.DetailsContainer>
          <S.IconContainer>
            <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
          </S.IconContainer>
          {registration}
        </S.DetailsContainer>
        <S.DetailsContainer>
          <S.IconContainer>
            <FontAwesomeIcon icon={faClipboardList}></FontAwesomeIcon>
          </S.IconContainer>
          {id}
        </S.DetailsContainer>
      </S.JobDetails>

      <S.Icons>
        {jobStatus !== null && (
          <JobStatusIcon jobStatus={jobStatus}></JobStatusIcon>
        )}

        <JobLocationType
          height={30}
          width={30}
          locationType={locationType}
        ></JobLocationType>
      </S.Icons>
      <S.ButtonContainer>
        <Button onClick={() => onViewJob(id)}>{t("View")}</Button>
      </S.ButtonContainer>
    </S.JobListing>
  );
};

export default JobResult;
