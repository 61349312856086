import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colours from "../../../../utils/colours";
import colors from "../../../../utils/colours";

type Props = {
  alert?: boolean;
};

const Button = styled.button<Props>`
  cursor: pointer;
  display: flex;
  width: 16rem;
  align-items: center;
  padding: 10px 10px;
  margin: 2px;

  background-color: ${props =>
    !props.alert ? colors.lightOrange : colours.darkRed};
  border: none;
  padding: 5px;
  border-radius: 5px;

  & > span {
    padding-left: 15px;
  }

  &:disabled {
    display: none;
  }
`;

const Text = styled.p`
  padding-top: 5px;
  padding-left: 15px;
  width: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 60px;
`;

export default { Button, Text, Icon };
