import React, { ReactNode } from "react";
import S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  children: ReactNode;
  icon: IconDefinition;
  title: string;
};

const JobCategory = (props: Props) => {
  return (
    <S.JobCategory>
      <S.TitleContent>
        <S.Title>{props.title}</S.Title>
        <S.JobCategoryIcon>
          <FontAwesomeIcon icon={props.icon} />
        </S.JobCategoryIcon>
      </S.TitleContent>

      <S.JobCategoryContent>{props.children}</S.JobCategoryContent>
    </S.JobCategory>
  );
};

export default JobCategory;
