import styled from "styled-components";
import _Button from "../button/Button";

const JobDetails = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const HeaderDetails = styled.span``;

const IconContainer = styled.span`
  width: 10px;
`;

const Spacer = styled.hr`
  border: 1px solid #b0afb1;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
`;

const Map = styled.div`
  width: 100%;
  height: 300px;
  background-color: gray;
`;

const SectionTitle = styled.div`
  margin-bottom: 5px;
`;

const DateDetail = styled.div`
  margin-bottom: 3px;
  text-align: left;

  & div {
    text-align: right;
    width: 100%;
  }
  padding: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const DetailColumn = styled.div`
  min-width: 50%;
`;

const DetailItem = styled.div`
  text-align: left;
  padding-left: 5px;
  margin-bottom: 3px;

  & svg {
    width: 30px !important;
  }
`;

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
`;

const Detail = styled.span``;

const DetailContent = styled.span``;

const Button = styled(_Button)`
  width: 16rem;
  margin: 5px 0px;
`;

const NoItems = styled.div`
  width: 100%;
  text-align: center;
  font-style: italic;
`;

export default {
  JobDetails,
  Title,
  HeaderDetails,
  Spacer,
  IconContainer,
  Map,
  SectionTitle,
  DateDetail,
  HeaderContainer,
  DetailColumn,
  DetailItem,
  Detail,
  DetailContainer,
  DetailContent,
  Button,
  NoItems,
};
