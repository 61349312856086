import React from "react";
import S from "./styles";
import JobCategory from "../job-category/JobCategory";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import JobSubCategory from "../job-sub-category/JobSubCategory";

type Props = {
  additionalInformation: string | null;
};

const AdditionalInformation = (props: Props) => {
  const { additionalInformation } = props;
  const { t } = useTranslation();

  return (
    <JobCategory icon={faInfo} title={t("Additional information")}>
      <JobSubCategory>
        <S.DetailContainer>{additionalInformation}</S.DetailContainer>
      </JobSubCategory>
    </JobCategory>
  );
};

export default AdditionalInformation;
