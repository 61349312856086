import { createContext, useContext } from "react";
import { User } from "360";

export const UserContext = createContext<User | null>(null);

export const useUserContext = () => {
  const ctx = useContext(UserContext);

  if (!ctx)
    throw new Error(
      "attempted to use user context inside provider with no value."
    );

  return ctx;
};
