import styled from "styled-components";

const Parent = styled.div`
  width: 100%;
  background: #f5f5f5;
  text-align: center;
`;

const Thumbnails = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default {
  Parent,
  Thumbnails,
};
