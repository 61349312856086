import styled from "styled-components";
import _Button from "../../button/Button";
import _Input from "../../input/Input";
import _TextArea from "../../textarea/TextArea";

const SingleFieldComponent = styled.div`
  & > * {
    margin-bottom: 5px;
  }

  max-width: 16rem;
`;
const Header = styled.p``;
const Button = styled(_Button)`
  width: 16rem;
`;
const Input = styled(_Input)`
  width: 16rem;
`;
const TextArea = styled(_TextArea)`
  height: 20rem;
  width: 16rem;
`;

export default {
  SingleFieldComponent,
  Header,
  Button,
  Input,
  TextArea
};
