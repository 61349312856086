import React, { useState } from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";
import { useApi, Api, isNoContent } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";

function addContact(api: Api, reference: string, contact: string) {
  return api.post(`api/breakdowns/${reference}/contacts`, "1.0", { contact });
}

function removeContact(api: Api, reference: string, contact: string) {
  return api.delete(`api/breakdowns/${reference}/contacts/${contact}`, "1.0");
}

function isValidContact(contact: string) {
  const ats = contact.match(/@/g);
  return (
    ats !== null &&
    ats.length === 1 &&
    contact[0] !== "@" &&
    contact[contact.length - 1] !== "@"
  );
}

type Props = {
  reference: string;
  initialValue: string[] | null;
  onComplete: (contacts: string[]) => void;
  onBackClick: () => void;
  onCancel: () => void;
  onFail: () => void;
};

function Contacts(props: Props) {
  const { reference, initialValue, onComplete, onBackClick, onCancel, onFail } =
    props;

  const [loading, setLoading] = useState<boolean>(false);
  const [contacts, setContacts] = useState<string[]>(initialValue || []);
  const [contact, setContact] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const api = useApi();
  const { t } = useTranslation();

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setContact(e.target.value);

  const handleAddClick = async () => {
    try {
      setErrors([]);

      if (contact === "") {
        return;
      }

      if (!isValidContact(contact)) {
        return setErrors(["Must be a valid email address."]);
      }

      setLoading(true);
      const response = await addContact(api, reference, contact);
      setLoading(false);
      if (isNoContent(response)) {
        setContact("");
        return setContacts([...contacts, contact]);
      }
    } catch {}
    return onFail();
  };

  const handleRemoveClick = async (contact: string) => {
    try {
      setLoading(true);
      const response = await removeContact(api, reference, contact);
      setLoading(false);
      if (isNoContent(response)) {
        const updated = contacts.filter((x) => x !== contact);
        return setContacts(updated);
      }
    } catch {}
    return onFail();
  };

  if (loading) {
    return <Spinner />;
  }

  const sorted = contacts.sort((a, b) => a.localeCompare(b));

  return (
    <S.Contacts>
      <S.Header>{t("Email contacts")}</S.Header>
      <p>
        {t(
          "Please add any email addresses to which you would like job updates to be sent."
        )}
      </p>
      <S.Input value={contact} errors={errors} onChange={handleContactChange} />
      <S.Button onClick={handleAddClick}>{t("Add")}</S.Button>
      {sorted.map((c, i) => (
        <S.Contact key={i} onClick={() => handleRemoveClick(c)}>
          {c}
        </S.Contact>
      ))}
      <S.Button onClick={() => onComplete(contacts)}>
        {t(contacts.length > 0 ? "Continue" : "Skip")}
      </S.Button>
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
      <S.Button onClick={onCancel}>{t("Save for later or cancel")}</S.Button>
    </S.Contacts>
  );
}

export default Contacts;
