import React, { useState } from "react";
import S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCheck, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

type Props = {
  onComplete: (files: File[]) => void;
  photoLimit?: number;
  photoCount?: number;
  onBackClick: () => void;
};

const Camera = (props: Props) => {
  const { onComplete, photoLimit, photoCount, onBackClick } = props;
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [tooManyPhotos, setTooManyPhotos] = useState<boolean>(false);
  const { t } = useTranslation();

  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      return;
    }

    setTooManyPhotos(false);
    const currentFileCount = files.length;
    const newFileCount = e.target.files.length;

    if (photoLimit && currentFileCount + newFileCount > photoLimit) {
      return setTooManyPhotos(true);
    }

    const newFileUrls = Array.from(e.target.files).map((f) =>
      URL.createObjectURL(f)
    );
    setFileUrls([...fileUrls, ...newFileUrls]);
    setFiles([...files, ...e.target.files]);
  };

  const onRemoveImage = (index: number) => {
    const newFileURLs: string[] = [];
    const newFiles: File[] = [];

    for (let i = 0; i < fileUrls.length; i++) {
      if (i !== index) {
        newFileURLs.push(fileUrls[i]);
        newFiles.push(files[i]);
      }
    }

    setFileUrls(newFileURLs);
    setFiles(newFiles);
  };

  const noImages = fileUrls.length === 0 && files.length === 0;

  return (
    <S.Parent>
      {noImages && (
        <S.DataUsageWarning>
          {t(
            "Please be aware that submitting high quality images may result in significant data usage. Methods for reducing image quality will vary by device so please check with your device manufacturer."
          )}
        </S.DataUsageWarning>
      )}
      <S.Gallery>
        {fileUrls.map((file, index) => (
          <S.ImageContainer key={index}>
            <S.Trash icon={faTrash} size={"2x"}></S.Trash>
            <S.Image
              src={file}
              onClick={() => {
                onRemoveImage(index);
              }}
            />
          </S.ImageContainer>
        ))}
      </S.Gallery>
      <S.Footer>
        <S.UploadContainer>
          {photoCount !== undefined && (
            <S.Error alert={tooManyPhotos}>
              {t("You are limited to only {{count}} photos.", {
                count: photoLimit,
              })}
            </S.Error>
          )}
          <S.UploadButton>
            <S.Upload htmlFor="files">
              <FontAwesomeIcon icon={faCamera} />
              <span>{t("Upload Photos")}</span>
            </S.Upload>
            <S.FileSelect
              id="files"
              onChange={onChangeEvent}
              multiple={true}
              type="File"
              accept="image/*"
            />
          </S.UploadButton>
          <S.ConfirmButton
            onClick={() => {
              onComplete(files);
            }}
          >
            <S.Upload>
              <FontAwesomeIcon icon={faCheck} />
              <span>{t("Confirm")}</span>
            </S.Upload>
          </S.ConfirmButton>
          <S.BackButton onClick={onBackClick}>{t("Back")}</S.BackButton>
        </S.UploadContainer>
      </S.Footer>
    </S.Parent>
  );
};

export default Camera;
