import React from "react";
import Tyre from "../WorkRequested";
import S from "./styles";
import { useTranslation } from "react-i18next";
import JobSubCategory from "../job-sub-category/JobSubCategory";

type Props = {
  tyre: Tyre;
  jobDetailsEditClicked?: () => void;
};

const TyreDetails = (props: Props) => {
  const { t } = useTranslation();

  return (
    <JobSubCategory onEditClicked={props.jobDetailsEditClicked}>
      <S.TyreDetails>
        <S.DetailContainer>
          <S.Detail>{t("Position")}</S.Detail>
          <S.DetailContent>{props.tyre.position}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Size")}</S.Detail>
          <S.DetailContent>{props.tyre.size}</S.DetailContent>
        </S.DetailContainer>
        {props.tyre.removalReason != null && (
          <S.DetailContainer>
            <S.Detail>{t("Removal reason")}</S.Detail>
            <S.DetailContent>{t(props.tyre.removalReason)}</S.DetailContent>
          </S.DetailContainer>
        )}
        {props.tyre.make != null && (
          <S.DetailContainer>
            <S.Detail>{t("Make")}</S.Detail>
            <S.DetailContent>{props.tyre.make}</S.DetailContent>
          </S.DetailContainer>
        )}
        {props.tyre.pattern != null && (
          <S.DetailContainer>
            <S.Detail>{t("Pattern")}</S.Detail>
            <S.DetailContent>{props.tyre.pattern}</S.DetailContent>
          </S.DetailContainer>
        )}
      </S.TyreDetails>
    </JobSubCategory>
  );
};

export default TyreDetails;
