import styled from "styled-components";
import _Button from "../../button/Button";
import colours from "../../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 16rem;

  & > * {
    margin-bottom: 5px;
  }
`;

export const Button = styled(_Button)`
  width: 16rem;
`;

export const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 48px;
  color: ${colours.yellow};
  align-self: center;
`;
