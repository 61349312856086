import React from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";

export type Option = {
  value: string;
  label: string;
};

type Props = {
  heading: string;
  options: Option[];
  onSelect: (option: Option) => void;
  onBackClick: () => void;
};

const ListComponent = (props: Props) => {
  const { heading, options, onSelect, onBackClick } = props;
  const { t } = useTranslation();

  return (
    <S.ListComponent>
      <S.Heading>{heading}</S.Heading>
      <ul>
        {options.map((o) => (
          <S.ListItem key={o.value}>
            <S.Button onClick={() => onSelect(o)}>{o.label}</S.Button>
          </S.ListItem>
        ))}
      </ul>
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
    </S.ListComponent>
  );
};

export default ListComponent;
