import React from "react";
import S from "./styles";
import {
  faEllipsisH,
  faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

type Props = {
  onSelect: (rrt: boolean) => void;
  onBackClick: () => void;
};

const RemovalReasonType = (props: Props) => {
  const { onSelect, onBackClick } = props;
  const { t } = useTranslation();

  return (
    <S.RemovalReasonType>
      <S.Heading>{t("Removal reason")}</S.Heading>
      <S.Button onClick={() => onSelect(true)}>
        <S.IconContainer>
          <S.Icon icon={faExclamationTriangle} />
        </S.IconContainer>
        <S.ButtonText>{t("Damage")}</S.ButtonText>
      </S.Button>
      <S.Button onClick={() => onSelect(false)}>
        <S.IconContainer>
          <S.Icon icon={faEllipsisH} />
        </S.IconContainer>
        <S.ButtonText>{t("Other")}</S.ButtonText>
      </S.Button>
      <S.BackButton onClick={onBackClick}>{t("Back")}</S.BackButton>
    </S.RemovalReasonType>
  );
};

export default RemovalReasonType;
