import React from "react";
import S from "./styles";
import FieldErrors from "../field-errors/FieldErrors";

type Props = {
  errors?: string[];
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const Input = (props: Props) => {
  const { errors } = props;

  const hasErrors = errors !== undefined && errors.length > 0;

  return (
    <S.Wrapper>
      <S.Select {...props} invalid={hasErrors} />
      {hasErrors && <FieldErrors errors={errors!} />}
    </S.Wrapper>
  );
};

export default Input;
