import styled from "styled-components";
import _Button from "../../button/Button";

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
`;

const SubTitle = styled.div`
  width: 100%;
  text-align: center;
`;

const Parent = styled.div`
  width: 100%;
  height: 100%;
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
`;

const Button = styled(_Button)`
  margin: 5px;

  :disabled{
    background
  }
`;

const ContinueButton = styled(_Button)`
  width: 16rem;
  margin-bottom: 5px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const AllDayContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CurrentDayContainer = styled.div`
  background-color: lightgray;
  border-radius: 5px;
  padding: 10px;
  margin: auto;
  width 80%;
  display:flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3px;
`;

const CurrentDay = styled.span`
  font-size: large;
  font-weight: bold;
  text-align: center;
`;

export default {
  Parent,
  Button,
  ContinueButton,
  BtnContainer,
  Title,
  SubTitle,
  AllDayContainer,
  CurrentDayContainer,
  CurrentDay,
};
