import React from "react";
import S from "./styles";

type Props = {
  isChecked: boolean;
  onCheckBoxClicked?: () => void;
};

const CheckBox = (props: Props) => {
  return (
    <S.CheckBox
      isChecked={props.isChecked}
      onClick={props.onCheckBoxClicked}
    ></S.CheckBox>
  );
};

export default CheckBox;
