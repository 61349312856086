import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";
import VR from "../../utils/ValidationResult";
import { Country } from "360";
import { useApi, Response, isSuccess, isFailure } from "../../api/Api";
import GeneralErrors from "../general-errors/GeneralErrors";
import Spinner from "../spinner/Spinner";

type Props = { onLogin: (token: string) => void };

const Login = (props: Props) => {
  const { onLogin } = props;
  const { t } = useTranslation();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [country, setCountry] = useState<Country | null>(null);
  const [countries, setCountries] = useState<Country[]>([]);
  const [vr, setVr] = useState<VR>(VR.empty);
  const [errors, setErrors] = useState<string[]>([]);
  const api = useApi();
  const [loading, setLoading] = useState<boolean>(true);

  const login = async (
    username: string,
    password: string,
    country: Country
  ): Promise<Response<string>> =>
    api.post("api/auth/authenticate", "1.1", {
      username,
      password,
      countryId: country.id,
      datasetId: country.datasetId,
    });

  useEffect(() => {
    (async () => {
      const tokenPromise = api.post<string>("api/auth/refresh", "1.1");
      const countriesPromise = api.get<Country[]>("api/countries", "1.0");

      try {
        // if we can generate a new auth token using an existing refresh token, do so
        const tokenResponse = await tokenPromise;

        if (isSuccess(tokenResponse)) {
          return onLogin(tokenResponse.data);
        }
      } catch {
        // if we failed to use a refresh token, allow the user to log in
      }

      try {
        const response = await countriesPromise;
        setLoading(false);

        if (isSuccess(response)) return setCountries(response.data);
      } catch {}

      setErrors([
        t(
          "Failed to load required data. Please reload 360\u{B0}Smart and try again."
        ),
      ]);
    })();
  }, [t, api, onLogin]);

  const validate = (): VR => {
    const result = VR.empty;

    if (!username) result.add("username", t("Field is required."));
    if (!password) result.add("password", t("Field is required."));
    if (!country) result.add("country", t("Field is required."));

    return result;
  };

  const handleLoginClick = async () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    try {
      const response = await login(username, password, country!);

      if (isSuccess(response)) return onLogin(response.data);
      if (isFailure(response))
        return setErrors(response.errors.map((e) => t(e)));
    } catch {}

    return setErrors([t("Login failed. Please try again.")]);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(e.target.value);
    setCountry(countries.find((c) => c.id === id) || null);
  };

  if (loading)
    return (
      <S.Login>
        <Spinner />
      </S.Login>
    );

  return (
    <S.Login>
      {errors.length > 0 && (
        <GeneralErrors errors={errors} onClose={() => setErrors([])} />
      )}
      <S.Background>
        <form>
          <S.Label htmlFor="username">{t("Username")}</S.Label>
          <S.Input
            autoComplete="username"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            errors={vr.getErrors("username")}
          />
          <S.Label htmlFor="password">{t("Password")}</S.Label>
          <S.Input
            autoComplete="current-password"
            id="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            errors={vr.getErrors("password")}
          />
          <S.Label htmlFor="country">{t("Country")}</S.Label>
          <S.Select
            id="country"
            onChange={handleCountryChange}
            value={(country && country.id) || ""}
            errors={vr.getErrors("country")}
          >
            <option value="" disabled hidden></option>
            {countries.map((c) => (
              <option key={c.id} value={c.id}>
                {c.description}
              </option>
            ))}
          </S.Select>
        </form>
        <S.Button onClick={handleLoginClick}>{t("Sign in")}</S.Button>
        <S.HelpMessage>{t("Having trouble signing in?")}</S.HelpMessage>
        <S.HelpMessage>{t("Please contact us for assistance.")}</S.HelpMessage>
      </S.Background>
    </S.Login>
  );
};

export default Login;
