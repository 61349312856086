import styled from "styled-components";
import _Button from "../../button/Button";

const Button = styled(_Button)`
  width: 16rem;
  margin-bottom: 3px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const NoPhotosFound = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
`;

export default { Button, ButtonContainer, NoPhotosFound };
