import styled from "styled-components";
import colors from "../../../../utils/colours";

type Props = {
  loneWorkerStatus: boolean;
};

const IconContainer = styled.div`
  width: 30px !important;
  text-align: center;
`;

const JobListing = styled.li<Props>`
  background-color: ${props =>
    props.loneWorkerStatus ? colors.darkRed : colors.white};

  border-radius: 5px;
  border: 1px solid black;
  padding: 5px;
  display: flex;
  align-items: center;

  margin: auto;
  margin-bottom: 3px;
  width: 95%;

  & > button,
  & > :nth-child(2) {
    margin-left: 5px;
  }

  @media screen and (min-width: 600px) {
    min-width: 500px;
  }
`;

const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  min-width: 175px;
  padding-bottom: 2px;
`;

const JobDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const JobDate = styled.div``;

type LocationProps = { height: number; width: number };

const JobStatusIcon = styled.div<LocationProps>`
  background-color: lightgray;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: center;
  margin-bottom: 3px;

  @media screen and (min-width: 600px) {
    margin-bottom: 0px;
    margin-right: 3px;
  }

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  & svg {
    padding-top: 5px;
    font-size: ${props => props.width - 10}px;
  }
`;

const JobFlagContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export default {
  JobListing,
  JobDetails,
  IconContainer,
  DetailContainer,
  JobDate,
  JobDetailsContainer,
  JobStatusIcon,
  JobFlagContainer
};
