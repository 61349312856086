import React, { useEffect, useState } from "react";
import ListComponent, { Option } from "../list-component/ListComponent";
import TyreSize from "../tyre-size/TyreSize";
import { useTranslation } from "react-i18next";
import { Husbandry as H } from "360";
import Camera from "../../../../../camera/Camera";
import { useApi, isSuccess, isNoContent } from "../../../../../../api/Api";
import Spinner from "../../../../../spinner/Spinner";

type Props = {
  positionId: number;
  reference: string;
  onComplete: () => void;
  onBackClick: () => void;
  onFail: () => void;
};

const Husbandry = (props: Props) => {
  const { positionId, reference, onComplete, onBackClick, onFail } = props;
  const [husbandry, setHusbandry] = useState<H | null>(null);
  const [husbandries, setHusbandries] = useState<H[]>([]);
  const [tyreSizeSkipped, setTyreSizeSkipped] = useState<boolean>(false);
  const [tyreSize, setTyreSize] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const api = useApi();

  const submitHusbandry = async (
    reference: string,
    positionId: number,
    husbandryId: number,
    tyreSize: string | null,
    photos: File[]
  ) => {
    const form = new FormData();
    form.append("husbandryId", husbandryId.toString());
    if (tyreSize !== null) form.append("tyreSize", tyreSize);

    for (var photo of photos) form.append("photos", photo);

    return api.postForm(
      `api/breakdowns/${reference}/work-required/${positionId}`,
      "1.0",
      form
    );
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<H[]>("api/husbandries", "1.0");
        setLoading(false);

        if (isSuccess(response)) {
          if (response.data.length === 0) onFail();
          return setHusbandries(response.data);
        }
      } catch {}

      onFail();
    })();
  }, [t, onFail, api]);

  const complete = async (photos: File[]) => {
    if (husbandry === null)
      throw new Error("attempted to submit null husbandry.");

    try {
      setLoading(true);
      const response = await submitHusbandry(
        reference,
        positionId,
        husbandry.id,
        tyreSize,
        photos
      );
      setLoading(false);

      if (isNoContent(response)) return onComplete();
    } catch {}

    onFail();
  };

  const handleHusbandrySelect = (o: Option) => {
    const husbandry = husbandries.find((h) => h.id === Number(o.value));

    if (husbandry) setHusbandry(husbandry);
  };

  const handleCameraComplete = (photos: File[]) => {
    complete(photos);
  };

  const options = husbandries.map((h) => ({
    value: h.id.toString(),
    label: t(h.description),
  }));

  if (loading) return <Spinner />;

  if (husbandry === null)
    return (
      <ListComponent
        heading={t("Specify work")}
        onSelect={handleHusbandrySelect}
        options={options}
        onBackClick={onBackClick}
      />
    );

  if (tyreSize === null && !tyreSizeSkipped)
    return (
      <TyreSize
        reference={reference}
        positionId={positionId}
        onSelect={setTyreSize}
        isSkippable
        onSkip={() => setTyreSizeSkipped(true)}
        onBackClick={() => setHusbandry(null)}
      />
    );

  return (
    <Camera
      onComplete={handleCameraComplete}
      onBackClick={() => {
        setTyreSize(null);
        setTyreSizeSkipped(false);
      }}
    />
  );
};

export default Husbandry;
