import styled from "styled-components";
import colours from "../../../../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _Button from "../../../../button/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    display: block;
    margin-bottom: 5px;
  }

  & > button,
  & > label,
  & > p {
    width: 16rem;
  }
`;
const Button = styled(_Button)`
  width: 16rem;
`;
const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 60px;
  margin-bottom: 10px;
  color: ${colours.yellow};
`;
const WarningParagraph = styled.p`
  margin-bottom: 10px;
`;

export default {
  Container,
  Button,
  WarningContainer,
  WarningIcon,
  WarningParagraph
};
