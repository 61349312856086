import React, { useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Reset } from "styled-reset";
import * as tkn from "../utils/token";
import Content from "./content/Content";
import LoggedInContent from "./content/LoggedInContent";
import GlobalStyle from "./GlobalStyle";
import S from "./styles";
import { setLocale } from "../i18n/i18n";
import { UserContext } from "../contexts/UserContext";
import { ApiContext } from "../contexts/ApiContext";
import { User } from "360";
import GeneralErrors from "./general-errors/GeneralErrors";
import { useTranslation } from "react-i18next";
import { setGlobalToken } from "../api/Api";

const parseUser = (token: string): User => {
  var countryId = tkn.getCountryId(token);
  var languageId = tkn.getLanguageId(token);
  var callCentreContactNumber = tkn.getPhoneNumber(token);
  var typeId = tkn.getUserTypeId(token);
  var id = tkn.getUserId(token);
  return {
    id,
    typeId,
    callCentreContactNumber,
    countryId,
    languageId,
  };
};

const App = () => {
  const [user, setUser] = useState<User | null>(null);
  const [warnUnauthenticated, setWarnUnauthenticated] = useState<boolean>(
    false
  );
  const { t } = useTranslation();

  const handleLogout = () => setUser(null);

  const handleLogin = (token: string) => {
    const user = parseUser(token);
    setGlobalToken(token);
    unstable_batchedUpdates(() => {
      setLocale(user.countryId, user.languageId);
      setUser(user);
    });
  };

  const handleUnauthenticated = () => setWarnUnauthenticated(true);

  const handleUnauthenticatedWarningClose = () => {
    setWarnUnauthenticated(false);
    handleLogout();
  };

  if (warnUnauthenticated) {
    return (
      <Wrapper>
        <GeneralErrors
          onClose={handleUnauthenticatedWarningClose}
          errors={[t("Your session has expired. Please log in to continue.")]}
        />
      </Wrapper>
    );
  }

  if (user) {
    return (
      <Wrapper onUnauthenticated={handleUnauthenticated}>
        <UserContext.Provider value={user}>
          <LoggedInContent onLogout={handleLogout} />
        </UserContext.Provider>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Content onLogin={handleLogin} />
    </Wrapper>
  );
};

const onNetworkError = () => {
  window.location.replace("offline.html");
};

const Wrapper = ({
  children,
  onUnauthenticated,
}: {
  children: React.ReactNode;
  onUnauthenticated?: () => void;
}) => (
  <S.App>
    <ApiContext.Provider
      value={{
        onNetworkError,
        onUnauthenticated,
      }}
    >
      <Reset />
      <GlobalStyle />
      {children}
    </ApiContext.Provider>
  </S.App>
);

export default App;
