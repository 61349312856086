import React from "react";
import NoticeBubble from "./noticeBubble/NoticeBubble";
import WarningBubble from "./warningBubble/WarningBubble";
import { Notification } from "360";

type Props = {
  notification: Notification;
};

const NotificationBubble = (props: Props) => {
  if (props.notification.typeId === 2 || props.notification.typeId === 6)
    return <WarningBubble notification={props.notification} />;

  return <NoticeBubble notification={props.notification} />;
};

export default NotificationBubble;
