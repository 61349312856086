import styled from "styled-components";

const ContactInformation = styled.div`
  width: 100%;
`;

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const Detail = styled.span`
  margin-bottom: 5px;
`;

const DetailContent = styled.span`
  margin-bottom: 5px;
  margin-left: 10px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 3px;
`;

export default {
  ContactInformation,
  DetailContainer,
  Detail,
  DetailContent,
  Title
};
