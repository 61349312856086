import React, { useState, useEffect } from "react";
import * as Components from "./";
import GeneralErrors from "../general-errors/GeneralErrors";
import Spinner from "../spinner/Spinner";
import { useTranslation } from "react-i18next";
import { Api, Response, useApi, mapSuccess, isSuccess } from "../../api/Api";
import {
  BreakdownSummary,
  BreakdownVehicle,
  Customer,
  SubmissionResult,
  ContactCaller,
  LocationType,
  Location,
  TimeSlot,
} from "360";
import { useUserContext } from "../../contexts/UserContext";

type ActiveComponent =
  | "HEALTH-SAFETY-WARNING"
  | "VEHICLE"
  | "CUSTOMER"
  | "PURCHASE-ORDER-DEFECT-NUMBER"
  | "MILEAGE"
  | "CONTACT-CALLER"
  | "LOCATION-TYPE"
  | "LOCATION"
  | "AVAILABILITY"
  | "WORK-REQUIRED"
  | "ADDITIONAL-INFORMATION"
  | "CONTACTS"
  | "SUMMARY"
  | "CONFIRMATION";

type Props = {
  onComplete: () => void;
  onFail: () => void;
  reference: string | null;
};

interface BreakdownInProgress extends BreakdownSummary {
  positionIdsWithWork: number[] | null;
}

const getBreakdownInProgress = async (
  api: Api,
  reference: string
): Promise<Response<BreakdownInProgress>> => {
  const response = await api.get(`api/breakdowns/${reference}`, "1.1");

  return mapSuccess<any, BreakdownInProgress>(response, (data) => ({
    ...data.summary,
    availability: data.summary.availability.map((a: any) => ({
      from: new Date(a.from),
      to: new Date(a.to),
    })),
    positionIdsWithWork: data.summary.workRequired.map(
      (wr: any) => wr.position.id
    ),
  }));
};

const getFirstComponentForResumedBreakdown = (
  breakdown: BreakdownInProgress,
  enableContacts: boolean
): ActiveComponent => {
  if (
    (enableContacts && breakdown.contacts && breakdown.contacts.length > 0) ||
    (!enableContacts && breakdown.additionalInformation)
  ) {
    return "SUMMARY";
  }

  if (enableContacts && breakdown.additionalInformation) {
    return "CONTACTS";
  }

  if (
    breakdown.positionIdsWithWork &&
    breakdown.positionIdsWithWork.length > 0
  ) {
    return "ADDITIONAL-INFORMATION";
  }

  if (
    (breakdown.availability && breakdown.availability.length > 0) ||
    (breakdown.location &&
      breakdown.locationType &&
      breakdown.locationType.id === 1)
  ) {
    return "WORK-REQUIRED";
  }

  if (
    breakdown.location &&
    breakdown.locationType &&
    breakdown.locationType.id !== 1
  ) {
    return "AVAILABILITY";
  }

  if (breakdown.locationType) {
    return "LOCATION";
  }

  if (breakdown.contactCaller) {
    return "LOCATION-TYPE";
  }

  if (breakdown.mileage) {
    return "CONTACT-CALLER";
  }

  if (breakdown.purchaseOrderDefectNumber) {
    return "MILEAGE";
  }

  return "PURCHASE-ORDER-DEFECT-NUMBER";
};

const Breakdown = (props: Props) => {
  const { reference, onComplete, onFail } = props;
  const [criticalError, setCriticalError] = useState<boolean>(false);
  const [reachedSummary, setReachedSummary] = useState<boolean>(false);
  const [activeComponent, setActiveComponent] = useState<ActiveComponent>(
    "HEALTH-SAFETY-WARNING"
  );
  const [submissionResult, setSubmissionResult] =
    useState<SubmissionResult | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const api = useApi();
  const user = useUserContext();
  const [showCancelPrompt, setShowCancelPrompt] = useState<boolean>(false);
  const [breakdownInProgress, setBreakdownInProgress] =
    useState<BreakdownInProgress | null>(null);

  const breakdownIsRoadside = breakdownInProgress?.locationType?.id === 1;
  const canSaveForLater =
    breakdownInProgress?.vehicle !== null &&
    breakdownInProgress?.customer !== null;
  const enableContacts = user.countryId === 2;

  useEffect(() => {
    (async () => {
      try {
        if (!reference) {
          setLoading(false);
          return;
        }

        const response = await getBreakdownInProgress(api, reference);

        if (isSuccess(response)) {
          setBreakdownInProgress({ ...response.data, reference });
          return setLoading(false);
        }
      } catch {}

      setCriticalError(true);
      setLoading(false);
    })();
  }, [api, reference]);

  const handleHealthSafetyWarningComplete = async () => {
    try {
      if (reference && breakdownInProgress) {
        return showNextComponent(
          getFirstComponentForResumedBreakdown(
            breakdownInProgress,
            enableContacts
          )
        );
      }

      setLoading(true);
      const response = await api.get<string>("api/breakdowns/new", "1.0");
      setLoading(false);

      if (isSuccess(response)) {
        setBreakdownInProgress({
          reference: response.data,
          vehicle: null,
          customer: null,
          purchaseOrderDefectNumber: null,
          mileage: null,
          location: null,
          locationType: null,
          workRequired: null,
          positionIdsWithWork: null,
          additionalInformation: null,
          contactCaller: null,
          availability: null,
          contacts: null,
        });
        showNextComponent("VEHICLE");
        return;
      }
    } catch {}

    return setCriticalError(true);
  };

  const showNextComponent = (nextComponent: ActiveComponent) =>
    setActiveComponent(reachedSummary ? "SUMMARY" : nextComponent);

  const handleCancel = () => setShowCancelPrompt(true);

  if (loading) return <Spinner />;

  if (criticalError)
    return (
      <GeneralErrors
        errors={[
          t(
            "A serious error has been encountered whilst processing your breakdown. Please contact us for assistance."
          ),
        ]}
        onClose={onFail}
      />
    );

  if (activeComponent === "HEALTH-SAFETY-WARNING")
    return (
      <Components.HealthSafetyWarning
        showContinue={true}
        onContinue={handleHealthSafetyWarningComplete}
      />
    );

  if (breakdownInProgress === null)
    throw new Error(
      "invalid component state. breakdown in progress is null after the health and safety stage."
    );

  const handleVehicleComplete = (
    vehicle: BreakdownVehicle,
    customer: Customer | null
  ) => {
    setBreakdownInProgress({
      ...breakdownInProgress,
      vehicle,
      customer: customer || breakdownInProgress.customer,
    });

    // if the customer couldn't be automatically determined, we must get the user
    // to select it. this is irrespective of whether we have come from the summary screen.
    if (customer === null) {
      return setActiveComponent("CUSTOMER");
    }

    showNextComponent("PURCHASE-ORDER-DEFECT-NUMBER");
  };

  const handleCustomerComplete = (customer: Customer) => {
    setBreakdownInProgress({ ...breakdownInProgress, customer });
    showNextComponent("PURCHASE-ORDER-DEFECT-NUMBER");
  };

  const handlePurchaseOrderDefectNumberComplete = (
    purchaseOrderDefectNumber: string
  ) => {
    setBreakdownInProgress({
      ...breakdownInProgress,
      purchaseOrderDefectNumber,
    });
    showNextComponent("MILEAGE");
  };

  const handleMileageComplete = (mileage: number | null) => {
    setBreakdownInProgress({ ...breakdownInProgress, mileage });
    showNextComponent("CONTACT-CALLER");
  };

  const handleContactCallerComplete = (contactCaller: ContactCaller) => {
    setBreakdownInProgress({ ...breakdownInProgress, contactCaller });
    showNextComponent("LOCATION-TYPE");
  };

  const handleLocationTypeComplete = (locationType: LocationType) => {
    setBreakdownInProgress({ ...breakdownInProgress, locationType });
    showNextComponent("LOCATION");
  };

  const handleLocationComplete = (location: Location) => {
    setBreakdownInProgress({ ...breakdownInProgress, location });
    showNextComponent(breakdownIsRoadside ? "WORK-REQUIRED" : "AVAILABILITY");
  };

  const handleAvailabilityComplete = (availability: TimeSlot[]) => {
    setBreakdownInProgress({ ...breakdownInProgress, availability });
    showNextComponent("WORK-REQUIRED");
  };

  const handleWorkRequiredComplete = (positionIdsWithWork: number[]) => {
    setBreakdownInProgress({
      ...breakdownInProgress,
      positionIdsWithWork,
    });
    showNextComponent("ADDITIONAL-INFORMATION");
  };

  const handleAdditionalInformationComplete = (
    additionalInformation: string
  ) => {
    setBreakdownInProgress({ ...breakdownInProgress, additionalInformation });
    const next = enableContacts ? "CONTACTS" : "SUMMARY";
    showNextComponent(next);
  };

  const handleContactsComplete = (contacts: string[]) => {
    setBreakdownInProgress({ ...breakdownInProgress, contacts });
    setReachedSummary(true);
    showNextComponent("SUMMARY");
  };

  const handleSummaryComplete = (result: SubmissionResult) => {
    setSubmissionResult(result);
    setActiveComponent("CONFIRMATION");
  };

  if (showCancelPrompt)
    return (
      <Components.SaveOrCancel
        onComplete={onComplete}
        onReturnToBreakdown={() => setShowCancelPrompt(false)}
        canSave={canSaveForLater}
        reference={breakdownInProgress.reference}
      />
    );

  if (activeComponent === "VEHICLE")
    return (
      <Components.Vehicle
        initialValue={breakdownInProgress.vehicle}
        reference={breakdownInProgress.reference}
        onContinue={handleVehicleComplete}
        onFail={() => setCriticalError(true)}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "CUSTOMER")
    return (
      <Components.Customer
        initialValue={breakdownInProgress.customer}
        reference={breakdownInProgress.reference}
        onComplete={handleCustomerComplete}
        onBackClick={() => showNextComponent("VEHICLE")}
        onFail={() => setCriticalError(true)}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "PURCHASE-ORDER-DEFECT-NUMBER")
    return (
      <Components.PurchaseOrderDefectNumber
        initialValue={breakdownInProgress.purchaseOrderDefectNumber}
        reference={breakdownInProgress.reference}
        onComplete={handlePurchaseOrderDefectNumberComplete}
        onFail={() => setCriticalError(true)}
        onBackClick={() => showNextComponent("VEHICLE")}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "MILEAGE")
    return (
      <Components.Mileage
        initialValue={breakdownInProgress.mileage}
        reference={breakdownInProgress.reference}
        onComplete={handleMileageComplete}
        onBackClick={() => showNextComponent("PURCHASE-ORDER-DEFECT-NUMBER")}
        onFail={() => setCriticalError(true)}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "CONTACT-CALLER")
    return (
      <Components.ContactCaller
        initialValue={breakdownInProgress.contactCaller}
        reference={breakdownInProgress.reference}
        onComplete={handleContactCallerComplete}
        onBackClick={() => showNextComponent("MILEAGE")}
        onFail={() => setCriticalError(true)}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "LOCATION")
    return (
      <Components.Location
        initialValue={breakdownInProgress.location}
        reference={breakdownInProgress.reference}
        onComplete={handleLocationComplete}
        onBackClick={() => showNextComponent("LOCATION-TYPE")}
        onFail={() => setCriticalError(true)}
        useAutomaticLocation={breakdownIsRoadside}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "LOCATION-TYPE")
    return (
      <Components.LocationType
        reference={breakdownInProgress.reference}
        onComplete={handleLocationTypeComplete}
        onBackClick={() => showNextComponent("CONTACT-CALLER")}
        onFail={() => setCriticalError(true)}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "AVAILABILITY")
    return (
      <Components.VehicleAvailability
        initialValue={breakdownInProgress.availability}
        onFail={() => setCriticalError(true)}
        reference={breakdownInProgress.reference}
        onComplete={handleAvailabilityComplete}
        onBackClick={() => showNextComponent("LOCATION")}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "WORK-REQUIRED")
    return (
      <Components.WorkRequired
        initialValue={breakdownInProgress.positionIdsWithWork || null}
        reference={breakdownInProgress.reference}
        onComplete={handleWorkRequiredComplete}
        onBackClick={() =>
          showNextComponent(breakdownIsRoadside ? "LOCATION" : "AVAILABILITY")
        }
        onCancel={handleCancel}
        onFail={() => setCriticalError(true)}
      />
    );

  if (activeComponent === "ADDITIONAL-INFORMATION")
    return (
      <Components.AdditionalInformation
        initialValue={breakdownInProgress.additionalInformation}
        reference={breakdownInProgress.reference}
        onComplete={handleAdditionalInformationComplete}
        onBackClick={() => showNextComponent("WORK-REQUIRED")}
        onCancel={handleCancel}
        onFail={() => setCriticalError(true)}
      />
    );

  if (activeComponent === "CONTACTS") {
    return (
      <Components.Contacts
        reference={breakdownInProgress.reference}
        initialValue={breakdownInProgress.contacts}
        onComplete={handleContactsComplete}
        onBackClick={() => showNextComponent("ADDITIONAL-INFORMATION")}
        onCancel={handleCancel}
        onFail={() => setCriticalError(true)}
      />
    );
  }

  if (activeComponent === "SUMMARY")
    return (
      <Components.Summary
        onEditCallerDetails={() => setActiveComponent("CONTACT-CALLER")}
        onEditDriverDetails={() => setActiveComponent("CONTACT-CALLER")}
        onEditMileage={() => setActiveComponent("MILEAGE")}
        onEditLocation={() => setActiveComponent("LOCATION")}
        onEditCustomer={() => setActiveComponent("CUSTOMER")}
        onEditVehicle={() => setActiveComponent("VEHICLE")}
        onEditWorkRequired={() => setActiveComponent("WORK-REQUIRED")}
        onEditAvailability={() => setActiveComponent("AVAILABILITY")}
        onEditLocationType={() => setActiveComponent("LOCATION-TYPE")}
        onEditPurchaseOrderDefectNumber={() =>
          setActiveComponent("PURCHASE-ORDER-DEFECT-NUMBER")
        }
        onEditAdditionalInformation={() =>
          setActiveComponent("ADDITIONAL-INFORMATION")
        }
        onEditContacts={() => setActiveComponent("CONTACTS")}
        reference={breakdownInProgress.reference}
        onComplete={handleSummaryComplete}
        onFail={() => setCriticalError(true)}
        onCancel={handleCancel}
      />
    );

  if (activeComponent === "CONFIRMATION") {
    if (submissionResult === null) {
      throw new Error(
        "attempted to display confirmation with no submission result"
      );
    }

    return (
      <Components.Confirmation
        result={submissionResult}
        onContinue={onComplete}
      />
    );
  }

  throw new Error(`unhandled active component: ${activeComponent}`);
};

export default Breakdown;
