import React, { useState } from "react";
import Login from "../login/Login";
import PhoneUs from "../phone-us/PhoneUs";
import Menu from "../menu/Menu";
import S from "./styles";

type Props = {
  onLogin: (token: string) => void;
};

type ActiveComponent = "LOGIN" | "PHONE-US";

const Content = (props: Props) => {
  const { onLogin } = props;
  const [activeComponent, setActiveComponent] = useState<ActiveComponent>(
    "LOGIN"
  );

  const component =
    activeComponent === "LOGIN" ? <Login onLogin={onLogin} /> : <PhoneUs />;
  const handlePhoneClick = () => setActiveComponent("PHONE-US");
  const handleHomeClick = () => setActiveComponent("LOGIN");

  return (
    <S.Content>
      <Menu onPhoneClick={handlePhoneClick} onHomeClick={handleHomeClick} />
      <S.Wrapper>{component}</S.Wrapper>
    </S.Content>
  );
};

export default Content;
