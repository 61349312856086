import React, { useEffect, useState } from "react";
import S from "./styles";
import AllocatedJob from "./AllocatedJob/AllocatedJob";
import { useApi, isSuccess, mapSuccess } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import CheckBox from "../../check-box/CheckBox";
import { useTranslation } from "react-i18next";
import { JobDetail } from "360";

type Props = {
  onContinue: (jobId: number) => void;
  onFail: () => void;
  userId: number;
};

const AllocatedJobs = (props: Props) => {
  const { onContinue, onFail, userId } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [jobs, setJobs] = useState<JobDetail[]>([]);
  const [userJobsFilter, setUserJobsFilter] = useState<boolean>(false);
  const [customerFilter, setCustomerFilter] = useState<string | null>(null);
  const [depotFilter, setDepotFilter] = useState<string | null>(null);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<any>("api/allocated-jobs", "1.0");
        const mapped = mapSuccess(response, (r): JobDetail[] =>
          r.map((job: any) => ({
            ...job,
            estimatedTimeOfArrival: new Date(job.estimatedTimeOfArrival),
          }))
        );
        setLoading(false);

        if (isSuccess(mapped)) {
          if (mapped.data.length > 0) setJobs(mapped.data);
          return;
        }
      } catch {}

      onFail();
    })();
  }, [onFail, api]);

  const handleCustomerChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setCustomerFilter(e.target.value || null);

  const handleDepotChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setDepotFilter(e.target.value || null);

  if (loading) return <Spinner />;

  const filteredJobs = jobs.filter(
    (j) =>
      (customerFilter === null || j.customer === customerFilter) &&
      (depotFilter === null || j.serviceProviderDepot === depotFilter) &&
      (!userJobsFilter || j.allocatedFitter === userId)
  );
  const orderedJobs = filteredJobs.sort((a, b) =>
    a.estimatedTimeOfArrival > b.estimatedTimeOfArrival
      ? 1
      : a.estimatedTimeOfArrival < b.estimatedTimeOfArrival
      ? -1
      : 0
  );
  const depots = [...new Set(jobs.map((j) => j.serviceProviderDepot))];
  const customers = [...new Set(jobs.map((j) => j.customer))];

  return (
    <S.AllocatedJob>
      <S.Title>{t("Allocated jobs")}</S.Title>
      <S.Filters>
        <S.Select onChange={handleDepotChange}>
          <option value="">{t("All Depots")}</option>
          {depots.map((d) => (
            <option key={d}>{d}</option>
          ))}
        </S.Select>
        <S.Select onChange={handleCustomerChange}>
          <option value="">{t("All Customers")}</option>
          {customers.map((c) => (
            <option key={c}>{c}</option>
          ))}
        </S.Select>
        <S.UserJobsCheckBox>
          <CheckBox
            isChecked={userJobsFilter}
            onCheckBoxClicked={() => setUserJobsFilter(!userJobsFilter)}
          />
          <span>{t("Your jobs only")}</span>
        </S.UserJobsCheckBox>
      </S.Filters>
      {orderedJobs.map((j) => (
        <AllocatedJob
          key={j.jobId}
          jobDetail={j}
          onClicked={onContinue}
          loneWorkerStatus={j.loneWorkerWarning}
        ></AllocatedJob>
      ))}
    </S.AllocatedJob>
  );
};

export default AllocatedJobs;
