import styled from "styled-components";
import colours from "../../../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotificationIcon = styled.div`
  position: relative;
  padding: 2px;
`;

const Count = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${colours.lightRed};
  color: ${colours.white};
  border-radius: 50%;
  padding: 0.25em;
  font-size: 66%;
  height: 14px;
  width: 14px;
  line-height: 14px;
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 30px;
`;

export default { NotificationIcon, Count, Icon, IconWrapper };
