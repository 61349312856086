import styled from "styled-components";
import _Button from "../../../../../button/Button";

const Prompt = styled.div`
  & > * {
    display: block;
    margin-bottom: 5px;
  }
`;

const PromptText = styled.p`
  width: 16rem;
`;

const Button = styled(_Button)`
  width: 16rem;
`;

export default { Prompt, PromptText, Button };
