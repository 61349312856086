import React, { useState, useEffect } from "react";
import Button from "../button/Button";
import { useTranslation } from "react-i18next";
import S from "./styles";
import { useApi, isSuccess } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import GeneralErrors from "../../general-errors/GeneralErrors";

type Country = {
  id: number;
  description: string;
  datasetId: number;
  contactNumber: string;
};

const SelectNumber = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [country, setCountry] = useState<Country | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<Country[]>("api/countries", "1.0");
        setLoading(false);

        if (isSuccess(response)) {
          setCountries(response.data);
          return;
        }
      } catch {}

      setErrors([
        t(
          "Failed to load required data. Please reload 360\u{B0}Smart and try again."
        ),
      ]);
    })();
  }, [t, api]);

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(e.target.value);
    setCountry(countries.find((c) => c.id === id) || null);
  };

  if (loading) return <Spinner />;
  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  return (
    <S.SelectNumber>
      <S.HelpMessage>
        {t(
          "If you require assistance, please select your country to phone us."
        )}
      </S.HelpMessage>
      <S.Select
        value={(country && country.id) || ""}
        onChange={handleCountryChange}
      >
        <option value="" disabled hidden></option>
        {countries.map((c) => (
          <option key={c.id} value={c.id}>
            {c.description}
          </option>
        ))}
      </S.Select>
      {country && <Button number={country.contactNumber} />}
    </S.SelectNumber>
  );
};

export default SelectNumber;
