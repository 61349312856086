import styled from "styled-components";

const LocationDetails = styled.div`
  width: 100%;
`;

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const Detail = styled.span`
  margin-bottom: 5px;
`;

const DetailContent = styled.span`
  margin-bottom: 5px;
  margin-left: 10px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 3px;
`;

const MapContainer = styled.div`
  display: flex;
  justify-content: center;

  & .map {
    min-height: 16rem;
    min-width: 16rem;
    border-radius: 5px;
    height: 300px;
    width: 100%;
  }
`;

export default {
  LocationDetails,
  DetailContainer,
  Detail,
  DetailContent,
  Title,
  MapContainer
};
