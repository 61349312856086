import styled from "styled-components";
import colours from "../../utils/colours";

type TextAreaProps = { invalid: boolean };

const TextArea = styled.textarea<TextAreaProps>`
  background-color: ${({ invalid }: TextAreaProps) =>
    invalid ? colours.extraLightRed : colours.white};
  border: 1px solid
    ${({ invalid }: TextAreaProps) =>
      invalid ? colours.lightRed : colours.black};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2px 5px;
  resize: none;
`;

export default { TextArea };
