import styled from "styled-components";
import _Button from "../../../button/Button";
import _TextArea from "../../../textarea/TextArea";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    display: block;
    margin-bottom: 5px;
  }

  & > button,
  & > label,
  & > p {
    width: 16rem;
  }
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const TextArea = styled(_TextArea)`
  width: 16rem;
  height: 10rem;
`;

export default { TextArea, Container, Button };
