import React from "react";
import S from "./styles";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

type Props = {
  icon: IconDefinition;
  onClick: () => void;
};

const Icon = (props: Props) => {
  const { icon, onClick } = props;
  return (
    <span onClick={onClick}>
      <S.Icon icon={icon} />
    </span>
  );
};

export default Icon;
