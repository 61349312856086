import React from "react";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import S from "./styles";

type Props = {
  onRetry: () => void;
  onManualLocation: () => void;
};

const LocationFailed = (props: Props) => {
  const { onRetry, onManualLocation } = props;

  const { t } = useTranslation();

  return (
    <S.Container>
      <S.WarningIcon icon={faExclamationTriangle} />
      <S.WarningParagraph>
        {t("Your location could not be automatically retrieved at this time.")}
      </S.WarningParagraph>
      <S.WarningParagraph>
        {t(
          "Please ensure that location services are enabled and permissions have been granted."
        )}
      </S.WarningParagraph>
      <S.Button onClick={onRetry}>{t("Retry")}</S.Button>
      <S.Button onClick={onManualLocation}>{t("Enter location")}</S.Button>
    </S.Container>
  );
};

export default LocationFailed;
