import styled from "styled-components";
import screens from "../../utils/screens";
import _Button from "../button/Button";

export const BreakdownsInProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const Heading = styled.div`
  font-weight: bold;
  margin: 5px;
`;

export const Breakdown = styled.li`
  display: flex;
  flex-direction: column;

  margin-bottom: 5px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;

  @media screen and ${screens.small} {
    flex-direction: row;
  }
`;

export const BreakdownContent = styled.div`
  & > * {
    margin-bottom: 5px;
  }

  @media screen and ${screens.small} {
    margin-right: 5px;
    flex-grow: 1;

    & > *:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const BreakdownButtons = styled.div`
  display: flex;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: 5px;
  }

  @media screen and ${screens.small} {
    flex-direction: column;
    justify-content: center;

    & > *:not(:last-child) {
      margin-right: 0px;
      margin-bottom: 5px;
    }
  }
`;

export const BreakdownRow = styled.div`
  display: flex;
`;

export const BreakdownButton = styled(_Button)`
  width: 8rem;
`;
