import styled from "styled-components";
import _Input from "../input/Input";

const Date = styled(_Input)`
  width: 10rem;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
`;

const Time = styled(_Input)`
  width: 6rem;
  border-radius: 0px 5px 5px 0px;
  border-text-align: center;
  border-left-width: 0px;
  text-align: center;
`;

const DateTime = styled.div`
  width: 16rem;
  margin-bottom: 3px;
`;

const Error = styled.div`
  color: red;
  margin-top: 3px;
`;

const InputWrapper = styled.div`
  height: 30px;
  display: flex;
`;

export default {
  DateTime,
  Date,
  Time,
  Error,
  InputWrapper
};
