import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import VR from "../../../utils/ValidationResult";
import SingleFieldComponent from "../single-field-component/SingleFieldComponent";
import { useApi, isNoContent } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";

type Props = {
  onComplete: (additionalInformation: string) => void;
  initialValue: string | null;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
  reference: string;
};

const AdditionalInformation = (props: Props) => {
  const {
    onCancel,
    initialValue,
    reference,
    onComplete,
    onFail,
    onBackClick,
  } = props;
  const [additionalInformation, setAdditionalInformation] = useState<string>(
    initialValue || ""
  );
  const [vr, setVr] = useState<VR>(VR.empty);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useApi();
  const { t } = useTranslation();

  const validate = (): VR => {
    const vr = VR.empty;

    if (additionalInformation.trim().length > 1000)
      vr.add(
        "additionalInfo",
        t("Maximum length is {{len}} characters.", { len: 1000 })
      );

    return vr;
  };

  const handleContinue = async () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    try {
      setLoading(true);
      const response = await api.post(
        `api/breakdowns/${reference}/additional-information`,
        "1.0",
        { additionalInformation }
      );
      setLoading(false);

      if (isNoContent(response)) return onComplete(additionalInformation);
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  return (
    <SingleFieldComponent
      header={t("Additional Information")}
      type="TEXTAREA"
      value={additionalInformation}
      errors={vr.getErrors("additionalInfo")}
      onChange={setAdditionalInformation}
      onContinue={handleContinue}
      onBackClick={onBackClick}
      onCancel={onCancel}
    >
      <p>
        {t(
          "If you are submitting multiple jobs at the same location, please note this above."
        )}
      </p>
    </SingleFieldComponent>
  );
};

export default AdditionalInformation;
