import { TFunction } from "i18next";
import { Notification } from "360";

type Parser = (t: TFunction, notification: Notification) => string;
type Parsers = { [key: number]: { [key: number]: Parser } };

const parsers: Parsers = {
  1: {
    1: (t, notification) => {
      const parsed = JSON.parse(notification.data);

      return t("NOTIFICATION_ALLOCATED_V1", {
        ...parsed,
        eta: new Date(parsed.eta)
      });
    }
  },
  2: {
    1: (t, notification) => {
      const parsed = JSON.parse(notification.data);

      return t("NOTIFICATION_REVISEDETA_V1", {
        ...parsed,
        revisedEta: new Date(parsed.revisedEta)
      });
    }
  },
  3: {
    1: (t, notification) => {
      const parsed = JSON.parse(notification.data);

      return t("NOTIFICATION_ENROUTE_V1", {
        ...parsed,
        enRoute: new Date(parsed.enRoute)
      });
    }
  },
  4: {
    1: (t, notification) => {
      const parsed = JSON.parse(notification.data);

      return t("NOTIFICATION_ONSCENE_V1", {
        ...parsed,
        onScene: new Date(parsed.onScene)
      });
    }
  },
  5: {
    1: (t, notification) => {
      const parsed = JSON.parse(notification.data);

      return t("NOTIFICATION_COMPLETED_V1", {
        ...parsed,
        completed: new Date(parsed.completed)
      });
    }
  },
  6: {
    1: (t, notification) => {
      const parsed = JSON.parse(notification.data);

      return t("NOTIFICATION_REALLOCATED_V1", {
        ...parsed,
        eta: new Date(parsed.eta)
      });
    }
  }
};

const parseNotification = (n: Notification, t: TFunction): string => {
  const parsersForType = parsers[n.typeId];

  if (parsersForType === undefined) return t("NOTIFICATION_NOFORMAT");

  const parser = parsersForType[n.version];

  if (parser === undefined) return t("NOTIFICATION_NOFORMAT");

  return parser(t, n);
};

export default parseNotification;
