import styled from "styled-components";
import colours from "../../utils/colours";

type Colour = "ORANGE" | "GREY";

type Props = {
  colour?: Colour;
};

const Button = styled.button<Props>`
  background-color: ${props =>
    props.colour && props.colour === "GREY"
      ? colours.grey3
      : colours.lightOrange};
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.colour && props.colour === "GREY"
        ? colours.grey2
        : colours.darkOrange};
  }

  &:disabled {
    background-color: ${colours.grey3};
  }
`;

export default { Button };
