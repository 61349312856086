import { createGlobalStyle } from "styled-components";
import openSans from "./OpenSans-Regular.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Open Sans";
    src: url(${openSans});
  }

  body {
      font-family: "Open Sans";
      font-size: 16px;
      overflow-x: hidden;

      & * {
        font-family: "Open Sans";
        font-size: 16px;
      }
  }

  #root {
    height: 100vh;
  }
`;

export default GlobalStyle;
