import styled from "styled-components";
import colours from "../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _Button from "../button/Button";

const HealthSafetyWarning = styled.div`
  & > * {
    margin-bottom: 5px;
  }

  max-width: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 48px;
  color: ${colours.yellow};
  margin: 10px;
`;

export default { HealthSafetyWarning, WarningIcon, Button };
