import React, { useState, useEffect } from "react";
import S from "./styles";
import WheelSecurity from "../wheel-security/WheelSecurity";
import { Api, useApi, isSuccess, isFailure } from "../../../api/Api";
import { WheelSecurityModel } from "360";
import Spinner from "../../spinner/Spinner";
import { useTranslation } from "react-i18next";
import DateTimeInput from "../../date-time-input/DateTimeInput";
import VR from "../../../utils/ValidationResult";
import formatISO from "date-fns/formatISO";
import ContactTSU from "./contact-tsu/ContactTSU";
import PositionsMissingPhotos from "./PositionsMissingPhotos";
import { ConfirmedWork, ConfirmationRequired, Husbandry } from "./models";
import WorkConfirmation from "./WorkConfirmation";
import HusbandryConfirmation from "./HusbandryConfirmation";

type ActiveComponent =
  | "MISSING-PHOTOS"
  | "COMPLETION-DATE"
  | "WHEEL-SECURITY"
  | "HUSBANDRY-COMPLETION"
  | "WORK-COMPLETION"
  | "CONTACT-TSU";

type ComponentState = {
  activeComponent: ActiveComponent;
  canSubmitAfterCompletion: boolean;
};

function getComponentState(
  missingPhotos: string[] | null,
  dateEntered: boolean,
  confirmWheelSecurity: boolean,
  wheelSecurity: WheelSecurityModel | null,
  confirmWork: boolean,
  unconfirmedWork: ConfirmationRequired[],
  unconfirmedHusbandries: Husbandry[],
  contactTsu: boolean
): ComponentState {
  if (missingPhotos !== null && missingPhotos.length > 0) {
    return {
      activeComponent: "MISSING-PHOTOS",
      canSubmitAfterCompletion: false,
    };
  }

  if (!dateEntered) {
    return {
      activeComponent: "COMPLETION-DATE",
      canSubmitAfterCompletion: !confirmWheelSecurity && !confirmWork,
    };
  }

  if (confirmWheelSecurity && wheelSecurity == null) {
    return {
      activeComponent: "WHEEL-SECURITY",
      canSubmitAfterCompletion: !confirmWork,
    };
  }

  if (confirmWork && unconfirmedHusbandries.length > 0) {
    return {
      activeComponent: "HUSBANDRY-COMPLETION",
      canSubmitAfterCompletion: unconfirmedWork.length === 0,
    };
  }

  if (confirmWork && unconfirmedWork.length > 0) {
    return {
      activeComponent: "WORK-COMPLETION",
      canSubmitAfterCompletion: true,
    };
  }

  if (contactTsu) {
    return {
      activeComponent: "CONTACT-TSU",
      canSubmitAfterCompletion: true,
    };
  }

  return {
    activeComponent: "COMPLETION-DATE",
    canSubmitAfterCompletion: false,
  };
}

type Props = {
  jobId: number;
  phoneNumber: string;
  countryId: number;
  onContinue: () => void;
  onReturn: () => void;
  onReturnToPhotos: () => void;
  onFail: (errors?: string[]) => void;
  confirmationRequired: ConfirmationRequired[];
  husbandriesNeedingConfirmation: Husbandry[];
};

const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

const submitCompletion = async (
  api: Api,
  jobId: number,
  completed: Date,
  wheelSecurity: WheelSecurityModel | null,
  confirmedWork: ConfirmedWork[],
  confirmedHusbandries: Husbandry[]
) =>
  api.post(`api/allocated-jobs/${jobId}/work-completed`, "1.2", {
    workCompleted: formatISO(completed),
    wheelSecurity,
    confirmedWork,
    confirmedHusbandries,
  });

const WorkComplete = (props: Props) => {
  const {
    jobId,
    phoneNumber,
    onContinue,
    onReturn,
    onFail,
    onReturnToPhotos,
    countryId,
    confirmationRequired,
    husbandriesNeedingConfirmation,
  } = props;

  const confirmWheelSecurity = countryId !== 2;
  const confirmWork = countryId === 2;

  const [loading, setLoading] = useState<boolean>(false);
  const [positionsMissingPhotos, setPositionsMissingPhotos] = useState<
    string[] | null
  >(null);
  const [dateWorkCompleted, setDateWorkCompleted] = useState<Date>(new Date());
  const [dateEntered, setDateEntered] = useState<boolean>(false);
  const [wheelSecurity, setWheelSecurity] = useState<WheelSecurityModel | null>(
    null
  );
  const [contactTSU, setContactTSU] = useState<boolean>(false);
  const [confirmedHusbandries, setConfirmedHusbandries] = useState<Husbandry[]>(
    []
  );
  const [unconfirmedHusbandries, setUnconfirmedHusbandries] = useState<
    Husbandry[]
  >(husbandriesNeedingConfirmation);
  const [vr, setVr] = useState<VR>(VR.empty);
  const { t } = useTranslation();
  const api = useApi();

  const { activeComponent, canSubmitAfterCompletion } = getComponentState(
    positionsMissingPhotos,
    dateEntered,
    confirmWheelSecurity,
    wheelSecurity,
    confirmWork,
    confirmationRequired,
    unconfirmedHusbandries,
    contactTSU
  );

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<string[]>(
          `api/allocated-jobs/${jobId}/positions-missing-photos`,
          "1.0"
        );
        setLoading(false);

        if (isFailure(response)) {
          return onFail(response.errors);
        }

        if (isSuccess(response))
          return setPositionsMissingPhotos(response.data);
      } catch {}

      onFail();
    })();
  }, [jobId, onFail, api]);

  const validate = () => {
    const result = VR.empty;

    if (!isValidDate(dateWorkCompleted))
      result.add("completed", t("You must enter a date and time"));

    if (dateWorkCompleted > new Date())
      result.add("completed", t("Date must not be in the future."));

    return result;
  };

  const handleDateContinue = () => {
    const vr = validate();
    setVr(vr);
    if (!vr.ok) return;

    setDateEntered(true);

    const confirmedWork: ConfirmedWork[] = [];

    if (canSubmitAfterCompletion) {
      submitWorkComplete(wheelSecurity, confirmedWork, confirmedHusbandries);
      return;
    }
  };

  const handleHusbandriesComplete = (confirmedHusbandries: Husbandry[]) => {
    setUnconfirmedHusbandries([]);
    setConfirmedHusbandries(confirmedHusbandries);
    const confirmedWork: ConfirmedWork[] = [];

    if (canSubmitAfterCompletion) {
      submitWorkComplete(wheelSecurity, confirmedWork, confirmedHusbandries);
      return;
    }
  };

  const handleWheelSecurityComplete = (wheelSecurity: WheelSecurityModel) => {
    setWheelSecurity(wheelSecurity);
    const confirmedWork: ConfirmedWork[] = [];

    if (canSubmitAfterCompletion) {
      submitWorkComplete(wheelSecurity, confirmedWork, confirmedHusbandries);
      return;
    }
  };

  const handleConfirmedWorkComplete = (confirmedWork: ConfirmedWork[]) => {
    if (canSubmitAfterCompletion) {
      submitWorkComplete(wheelSecurity, confirmedWork, confirmedHusbandries);
    }
  };

  const submitWorkComplete = async (
    wheelSecurity: WheelSecurityModel | null,
    confirmedWork: ConfirmedWork[],
    confirmedHusbandries: Husbandry[] | null
  ) => {
    try {
      setLoading(true);
      const response = await submitCompletion(
        api,
        jobId,
        dateWorkCompleted,
        wheelSecurity,
        confirmedWork,
        !confirmedHusbandries?.length ? [] : confirmedHusbandries
      );
      setLoading(false);

      if (isFailure(response)) {
        return onFail(response.errors);
      }

      if (isSuccess(response)) {
        if (response.data) return setContactTSU(true);
        else return onContinue();
      }
    } catch {}

    onFail();
  };

  if (loading) return <Spinner />;

  if (activeComponent === "MISSING-PHOTOS")
    return (
      <PositionsMissingPhotos
        positions={positionsMissingPhotos!}
        onReturnToPhotos={onReturnToPhotos}
        onContinue={() => {
          setPositionsMissingPhotos([]);
        }}
      />
    );

  if (activeComponent === "CONTACT-TSU")
    return <ContactTSU phoneNumber={phoneNumber} onContinue={onContinue} />;

  if (activeComponent === "HUSBANDRY-COMPLETION") {
    return (
      <HusbandryConfirmation
        onComplete={handleHusbandriesComplete}
        unconfirmedHusbandries={unconfirmedHusbandries}
      />
    );
  }

  if (activeComponent === "WHEEL-SECURITY")
    return (
      <WheelSecurity
        onContinue={handleWheelSecurityComplete}
        onReturn={onReturn}
        onFail={onFail}
      />
    );

  if (activeComponent === "WORK-COMPLETION") {
    return (
      <WorkConfirmation
        confirmationRequired={confirmationRequired}
        onComplete={handleConfirmedWorkComplete}
        onFail={onFail}
      />
    );
  }

  return (
    <S.WorkComplete>
      <S.Title>{t("Work Complete")}</S.Title>
      <DateTimeInput
        onChange={setDateWorkCompleted}
        value={dateWorkCompleted}
        errors={vr.getErrors("completed")}
      />
      <S.Button onClick={handleDateContinue}> {t("Continue")}</S.Button>
      <S.Button onClick={onReturn}> {t("Return to job")}</S.Button>
    </S.WorkComplete>
  );
};

export default WorkComplete;
