import React, { useState } from "react";
import { ConfirmedWork, ConfirmationRequired } from "./models";
import FittedDetails from "./FittedDetails";
import FailureDescription from "./FailureDescription";
import RemovedDetails from "./RemovedDetails";
import PositionContainer from "./PositionContainer";

type Props = {
  confirmationRequired: ConfirmationRequired;
  makes: string[];
  onComplete: (confirmedWork: ConfirmedWork) => void;
};

type FittedDetails = { make: string; pattern: string };

const PositionWorkConfirmation = (props: Props) => {
  const { confirmationRequired, onComplete, makes } = props;
  const [fittedDetails, setFittedDetails] = useState<FittedDetails | null>(
    null
  );
  const [failureDescription, setFailureDescription] = useState<string | null>(
    null
  );

  const handleFittedDetailsComplete = (make: string, pattern: string) =>
    setFittedDetails({ make, pattern });

  const handleFailureDescriptionComplete = (failureDescription: string) =>
    setFailureDescription(failureDescription);

  if (fittedDetails === null) {
    return (
      <PositionContainer position={confirmationRequired.position}>
        <FittedDetails
          size={confirmationRequired.size}
          makes={makes}
          onComplete={handleFittedDetailsComplete}
        />
      </PositionContainer>
    );
  }

  if (failureDescription === null) {
    return (
      <PositionContainer position={confirmationRequired.position}>
        <FailureDescription onComplete={handleFailureDescriptionComplete} />
      </PositionContainer>
    );
  }

  const handleRemovedDetailsComplete = (
    make: string,
    pattern: string,
    treadDepth: number | null
  ) => {
    return onComplete({
      id: confirmationRequired.id,
      make: fittedDetails.make,
      pattern: fittedDetails.pattern,
      failureDescription,
      removedMake: make,
      removedPattern: pattern,
      treadDepth,
    });
  };

  return (
    <PositionContainer position={confirmationRequired.position}>
      <RemovedDetails
        size={confirmationRequired.size}
        makes={makes}
        onComplete={handleRemovedDetailsComplete}
      />
    </PositionContainer>
  );
};

export default PositionWorkConfirmation;
