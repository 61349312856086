import React, { useState } from "react";
import S from "./styles";
import { useApi, isNoContent, isFailure } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import { useTranslation } from "react-i18next";
import DateTimeInput from "../../date-time-input/DateTimeInput";
import VR from "../../../utils/ValidationResult";

type Props = {
  jobId: number;
  onContinue: (eta: Date) => void;
  onReturn: () => void;
  onFail: (errors?: string[]) => void;
  eta: Date | null;
};

const isValidDate = (date: Date | null) => {
  if (date === null) return false;
  return date instanceof Date && !isNaN(date.getTime());
};

const JobTakeover = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [vr, setVr] = useState<VR>(VR.empty);
  const [takeOverDate, setTakeOverDate] = useState<Date | null>(null);
  const api = useApi();
  const takeOverJob = (jobId: number, etaDate: Date) =>
    api.post(`api/allocated-jobs/${jobId}/taken-over`, "1.0", {
      time: etaDate,
    });

  const validate = (): VR => {
    const result = VR.empty;

    if (takeOverDate !== null && takeOverDate < new Date())
      result.add("takeOverDate", t("Date must be in the future."));

    if (!isValidDate(takeOverDate))
      result.add("takeOverDate", t("You must enter a date and time."));

    return result;
  };

  const handleDateChange = (value: Date) => {
    setTakeOverDate(value);
  };

  const onConfirmTakeover = async (jobId: number) => {
    const vr = validate();
    setVr(vr);
    if (!vr.ok) return;

    try {
      setLoading(true);
      const response = await takeOverJob(jobId, new Date(takeOverDate!));
      setLoading(false);

      if (isFailure(response)) {
        return props.onFail(response.errors);
      }

      if (isNoContent(response)) {
        return props.onContinue(new Date(takeOverDate!));
      }
    } catch {}

    props.onFail();
  };

  if (loading) return <Spinner></Spinner>;

  return (
    <S.JobTakeover>
      <S.TextContent>
        {t("Please enter an ETA in order to take responsibility of the job.")}
      </S.TextContent>

      <DateTimeInput
        onChange={handleDateChange}
        value={takeOverDate}
        errors={vr.getErrors("takeOverDate")}
      ></DateTimeInput>

      <S.Button
        onClick={() => {
          onConfirmTakeover(props.jobId);
        }}
      >
        {t("Confirm")}
      </S.Button>
      <S.Button onClick={props.onReturn}>{t("Return to job")}</S.Button>
    </S.JobTakeover>
  );
};

export default JobTakeover;
