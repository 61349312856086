import styled from "styled-components";
import _Button from "../../button/Button";
import _Input from "../../input/Input";
import _Select from "../../select/Select";

const Button = styled(_Button)`
  width: 16rem;
`;

const Input = styled(_Input)`
  width: 16rem;
  margin-bottom: 5px;
`;
const Title = styled.div`
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
`;

const Select = styled(_Select)`
  width: 16rem;
`;

const WheelSecurity = styled.div`
  & > * {
    display: block;
    margin-bottom: 5px;
    max-width: 16rem;
  }
`;

export default { Button, Input, Title, Select, WheelSecurity };
