import styled from "styled-components";
import colours from "../../utils/colours";

const Parent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000e;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const IconWrapper = styled.span`
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  margin: 5px 8px 0 0;
  color: ${colours.white};

  &:hover {
    cursor: pointer;
    color: ${colours.grey2};
  }
`;

export default { Parent, IconWrapper };
