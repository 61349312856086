import React from "react";
import S from "../styles";
import BubbleContent from "../bubbleContent/BubbleContent";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { Notification } from "360";

type Props = {
  notification: Notification;
};

const NoticeBubble = (props: Props) => {
  return (
    <S.NoticeBubble>
      <S.IconWrapper>
        <S.GreenIcon icon={faCheckCircle} />
      </S.IconWrapper>
      <BubbleContent notification={props.notification} />
    </S.NoticeBubble>
  );
};

export default NoticeBubble;
