import styled from "styled-components";
import _Select from "../../select/Select";

const SelectNumber = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 5px;
  }
`;

const HelpMessage = styled.p`
  margin-bottom: 5px;
  text-align: center;
`;

const Select = styled(_Select)`
  width: 16rem;
`;

export default { SelectNumber, HelpMessage, Select };
