import _Button from "../../button/Button";
import styles from "styled-components";

const ReturnedHome = styles.div`
  display: flex;
  flex-direction: column;
`;

const Button = styles(_Button)`
  width: 16rem;
`;

export default { ReturnedHome, Button };
