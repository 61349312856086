import styled from "styled-components";
import colours from "../../../utils/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 5px;
`;

const Button = styled.a`
  border-radius: 5px;
  background-color: ${colours.lightOrange};
  display: flex;
  align-items: center;
  color: ${colours.black};
  text-decoration: none;
  padding: 5px;

  &:hover {
    background-color: ${colours.darkOrange};
  }
`;

export default { Icon, Button };
