import styled from "styled-components";
import colours from "../../../utils/colours";

const Thumbnail = styled.div`
  width: 150px;
  height: 150px;
  margin: 5px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  background-color: ${colours.grey3};
  padding: 5px;
  border-radius: 5px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Position = styled.span`
  padding: 0.4em;
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgb(0, 0, 0, 0.75);
  color: ${colours.white};
  border-radius: 5px;
`;

export default {
  Thumbnail,
  Image,
  Position,
};
