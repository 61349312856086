import React, { useState, useEffect } from "react";
import TimeReason from "../../time-reason/TimeReason";
import ReasonItem from "../../time-reason/ReasonItem";
import { useApi, isSuccess, isNoContent, isFailure } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import S from "./styles";
import { useTranslation } from "react-i18next";
import formatISO from "date-fns/formatISO";

type Props = {
  jobId: number;
  onComplete: () => void;
  onReturn: () => void;
  onFail: (errors?: string[]) => void;
};

const ReturnedHome = (props: Props) => {
  const { jobId, onComplete, onReturn, onFail } = props;
  const [reasons, setReasons] = useState<ReasonItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const api = useApi();

  const submitReturnedHome = async (
    jobId: number,
    reasonId: number,
    time: Date
  ) =>
    api.post(`api/allocated-jobs/${jobId}/returned-home`, "1.0", {
      reasonId,
      time: formatISO(time),
    });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<ReasonItem[]>(
          "api/returned-home-confirmations",
          "1.0"
        );
        setLoading(false);

        if (isFailure(response)) {
          return onFail(response.errors);
        }

        if (isSuccess(response) && response.data.length > 0) {
          return setReasons(response.data);
        }
      } catch {}

      onFail();
    })();
  }, [onFail, api]);

  const handleContinueClick = async (reasonId: number, time: Date) => {
    try {
      setLoading(true);
      const response = await submitReturnedHome(jobId, reasonId, time);
      setLoading(false);

      if (isNoContent(response)) return onComplete();
    } catch {}

    onFail();
  };

  if (loading) return <Spinner></Spinner>;

  return (
    <S.ReturnedHome>
      <TimeReason
        onContinue={handleContinueClick}
        reasons={reasons}
        title={t("Returning Home")}
      ></TimeReason>
      <S.Button onClick={onReturn}>{t("Return to job")}</S.Button>
    </S.ReturnedHome>
  );
};

export default ReturnedHome;
