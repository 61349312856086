import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import S from "./styles";
import VR from "../../../utils/ValidationResult";
import { Customer as CustomerModel } from "360";
import Spinner from "../../spinner/Spinner";
import { useApi, isSuccess, isNoContent } from "../../../api/Api";

type Props = {
  onComplete: (customer: CustomerModel) => void;
  initialValue: CustomerModel | null;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
  reference: string;
};

const Customer = (props: Props) => {
  const { initialValue, onCancel, onComplete, onFail, onBackClick } = props;
  const [customer, setCustomer] = useState<CustomerModel | null>(initialValue);
  const [customers, setCustomers] = useState<CustomerModel[]>([]);
  const [vr, setVr] = useState<VR>(VR.empty);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const api = useApi();

  const submitCustomer = async (reference: string, customerId: number) =>
    api.post(`api/breakdowns/${reference}/customers`, "1.0", {
      Id: customerId,
    });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<CustomerModel[]>("api/customers", "1.0");
        setLoading(false);

        if (isSuccess(response) && response.data.length > 0) {
          return setCustomers(response.data);
        }
      } catch {}

      onFail();
    })();
  }, [onFail, api]);

  const handleCustomerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(e.target.value);
    const customer = customers.find((c) => c.id === id);

    if (!customer) {
      throw new Error(`couldn't find customer id ${id}.`);
    }

    setCustomer(customer);
  };

  const validate = (): VR => {
    const vr = VR.empty;

    if (!customer) vr.add("customer", t("Field is required."));

    return vr;
  };

  const handleContinueClick = async () => {
    const vr = validate();
    setVr(vr);

    if (!vr.ok) return;

    if (!customer) throw new Error("customer is null.");

    try {
      setLoading(true);
      const response = await submitCustomer(props.reference, customer.id);
      setLoading(false);

      if (isNoContent(response)) return onComplete(customer);
    } catch {}

    onFail();
  };

  const orderedCustomers = customers.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  if (loading) return <Spinner />;

  return (
    <S.Customer>
      <label htmlFor="customer">{t("Customer")}</label>
      <S.Select
        id="customer"
        value={(customer && customer.id) || ""}
        onChange={handleCustomerChange}
        errors={vr.getErrors("customer")}
      >
        <option value="" disabled hidden></option>
        {orderedCustomers.map((c) => (
          <option key={c.id} value={c.id}>
            {c.name}
          </option>
        ))}
      </S.Select>
      <S.Button onClick={handleContinueClick}>{t("Continue")}</S.Button>
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
      <S.Button onClick={onCancel}>{t("Save for later or cancel")}</S.Button>
    </S.Customer>
  );
};

export default Customer;
