import styled from "styled-components";

const JobListing = styled.div`
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  border: 2px solid gray;
  padding: 5px;
`;

const JobDetails = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    width: 255px;
  }
`;

const IconContainer = styled.div`
  width: 30px;
  text-align: center;
  float: left;
`;

const DetailsContainer = styled.div`
  margin-bottom: 3px;
  width: 120px;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

const Icons = styled.div`
  width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  margin-left: 3px;
`;

export default {
  JobListing,
  JobDetails,
  IconContainer,
  DetailsContainer,
  Icons,
  ButtonContainer
};
