import React, { useState } from "react";
import S from "./styles";
import NotificationBubble from "./notification-bubble/NotificationBubble";
import useInterval from "../../utils/Interval";
import { useTranslation } from "react-i18next";
import { useApi, mapSuccess, isSuccess } from "../../api/Api";
import { Notification } from "360";
import Spinner from "../spinner/Spinner";
import GeneralErrors from "../general-errors/GeneralErrors";

type Props = {
  lastViewedNotificationId: number | null;
  setLastViewedNotificationId: (id: number) => void;
};

const Notifications = (props: Props) => {
  const { lastViewedNotificationId, setLastViewedNotificationId } = props;
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const api = useApi();

  useInterval(
    async () => {
      try {
        setLoading(true);
        const response = mapSuccess(
          await api.get<any[]>("api/notifications", "1.0", {
            lastViewedNotificationId: firstLoad
              ? null
              : lastViewedNotificationId,
          }),
          (xs) => xs.map((x) => ({ ...x, date: new Date(x.date) }))
        );
        setFirstLoad(false);
        setLoading(false);

        if (isSuccess(response)) {
          const merged = [...response.data, ...notifications];
          const maxId = Math.max(...merged.map((n) => n.id));
          setNotifications(merged);
          if (maxId > 0) setLastViewedNotificationId(maxId);
          return;
        }
      } catch {}

      return setErrors([
        t(
          "Failed to load required data. Please reload 360\u{B0}Smart and try again."
        ),
      ]);
    },
    1e3 * 60 * 5,
    true
  );

  if (loading) return <Spinner />;

  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  const grouped = groupNotifications(notifications);

  return (
    <S.Notifications>
      <S.NotificationHeader>{t("Notifications")}</S.NotificationHeader>
      {notifications.length === 0 && (
        <p>{t("You have no new notifications.")}</p>
      )}
      {notifications.length > 0 && (
        <S.NotificationList>
          {grouped.map((group) => (
            <S.NotificationGroup key={group[0].jobId}>
              {group.map((item) => (
                <NotificationBubble notification={item} key={item.id} />
              ))}
            </S.NotificationGroup>
          ))}
        </S.NotificationList>
      )}
    </S.Notifications>
  );
};

// groups notifications by job id, orders by notification id descending within the group,
// and then returns the whole lot ordered by vehicle registration
const groupNotifications = (notifications: Notification[]) => {
  const groups = notifications.reduce(
    (acc: { [key: number]: Notification[] }, val: Notification) => ({
      ...acc,
      [val.jobId]: [...(acc[val.jobId] || []), val],
    }),
    {}
  );

  return Object.values(groups)
    .sort((a, b) => {
      return a[0].vehicleReg.localeCompare(b[0].vehicleReg);
    })
    .map((group) => group.sort((a, b) => b.id - a.id));
};

export default Notifications;
