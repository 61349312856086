import styled from "styled-components";

type LocationProps = { height: number; width: number };

const JobLocation = styled.div<LocationProps>`
  background-color: lightgray;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  & svg {
    padding-top: 5px;
    font-size: ${props => props.width - 10}px;
  }
`;

export default { JobLocation };
