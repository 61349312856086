import React, { useState } from "react";
import Home from "../home/Home";
import Notifications from "../notifications/Notifications";
import PhoneUs from "../phone-us/PhoneUs";
import Breakdown from "../breakdown/Breakdown";
import BreakdownsInProgress from "../breakdowns-in-progress/BreakdownsInProgress";
import RecentJobs from "../todays-jobs/RecentJobs";
import SearchJobs from "../search-jobs/SearchJobs";
import LoggedInMenu from "../menu/LoggedInMenu";
import S from "./styles";
import SupplierDocuments from "../tech/documents/SupplierDocuments";
import AllocatedJobs from "../tech/allocated-jobs/AllocatedJobs";
import JobManagement from "../tech/job-management/JobManagement";
import { useUserContext } from "../../contexts/UserContext";
import { useApi } from "../../api/Api";

type Props = {
  onLogout: () => void;
};

type ActiveComponent =
  | "HOME"
  | "PHONE-US"
  | "NOTIFICATIONS"
  | "LOG-BREAKDOWN"
  | "BREAKDOWNS-IN-PROGRESS"
  | "RECENT-JOBS"
  | "SEARCH-JOBS"
  | "JOB-DETAILS"
  | "SUPPLIER-DOCUMENTS"
  | "ALLOCATED-JOBS"
  | "JOB-MANAGEMENT";

const LoggedInContent = (props: Props) => {
  const { onLogout } = props;
  const [activeComponent, setActiveComponent] = useState<ActiveComponent>(
    "HOME"
  );
  const [currentReference, setCurrentReference] = useState<string | null>(null);
  const [currentJobId, setCurrentJobId] = useState<number | null>(null);
  const [lastViewedNotificationId, setLastViewedNotificationId] = useState<
    number | null
  >(null);
  const user = useUserContext();
  const api = useApi();

  const handleLogoutClick = async () => {
    try {
      await api.post("api/auth/revoke", "1.1");
    } catch {
      // if refresh token revocation fails, just log the user out.
    }
    onLogout();
  };

  const handlePhoneClick = () => setActiveComponent("PHONE-US");
  const handleHomeClick = () => setActiveComponent("HOME");
  const handleNotificationsClick = () => setActiveComponent("NOTIFICATIONS");
  const handleBreakdownClick = () => {
    setCurrentReference(null);
    setActiveComponent("LOG-BREAKDOWN");
  };
  const handleBreakdownsInProgressClick = () =>
    setActiveComponent("BREAKDOWNS-IN-PROGRESS");
  const handleTodaysJobsClick = () => setActiveComponent("RECENT-JOBS");
  const handleSearchClick = () => setActiveComponent("SEARCH-JOBS");
  const handleAllocatedJobsClick = () => setActiveComponent("ALLOCATED-JOBS");
  const handleSupplierDocumentsClick = () =>
    setActiveComponent("SUPPLIER-DOCUMENTS");

  const handleBreakdownContinue = (reference: string) => {
    setCurrentReference(reference);
    setActiveComponent("LOG-BREAKDOWN");
  };

  let component = null;

  switch (activeComponent) {
    case "NOTIFICATIONS":
      component = (
        <Notifications
          lastViewedNotificationId={lastViewedNotificationId}
          setLastViewedNotificationId={setLastViewedNotificationId}
        />
      );
      break;
    case "PHONE-US":
      component = <PhoneUs number={user.callCentreContactNumber} />;
      break;
    case "LOG-BREAKDOWN":
      component = (
        <Breakdown
          reference={currentReference}
          onComplete={() => setActiveComponent("HOME")}
          onFail={() => setActiveComponent("PHONE-US")}
        />
      );
      break;
    case "BREAKDOWNS-IN-PROGRESS":
      component = <BreakdownsInProgress onContinue={handleBreakdownContinue} />;
      break;
    case "RECENT-JOBS":
      component = <RecentJobs />;
      break;
    case "SEARCH-JOBS":
      component = <SearchJobs />;
      break;
    case "HOME":
      component = (
        <Home
          onBreakdownClick={handleBreakdownClick}
          onBreakdownsInProgressClick={handleBreakdownsInProgressClick}
          onTodaysJobsClick={handleTodaysJobsClick}
          onPhoneUsClick={handlePhoneClick}
          onSearchClick={handleSearchClick}
          onAllocatedJobsClick={handleAllocatedJobsClick}
          onSupplierDocumentsClick={handleSupplierDocumentsClick}
          supplier={user.typeId === 2}
          countryId={user.countryId}
        />
      );
      break;
    case "SUPPLIER-DOCUMENTS":
      component = (
        <SupplierDocuments onBackClick={() => setActiveComponent("HOME")} />
      );
      break;

    case "JOB-MANAGEMENT":
      currentJobId !== null
        ? (component = (
            <JobManagement
              jobNumber={currentJobId}
              phoneNumber={user.callCentreContactNumber}
              userId={user.id}
              onBackClicked={() => setActiveComponent("ALLOCATED-JOBS")}
            ></JobManagement>
          ))
        : setActiveComponent("PHONE-US");
      break;

    case "ALLOCATED-JOBS":
      component = (
        <AllocatedJobs
          onContinue={(jobId: number) => {
            setCurrentJobId(jobId);
            setActiveComponent("JOB-MANAGEMENT");
          }}
          onFail={() => setActiveComponent("PHONE-US")}
          userId={user.id}
        />
      );
      break;

    default:
      throw new Error(`unhandled active component: ${activeComponent}`);
  }

  return (
    <S.Content>
      <LoggedInMenu
        onPhoneClick={handlePhoneClick}
        onHomeClick={handleHomeClick}
        onLogoutClick={handleLogoutClick}
        onNotificationClick={handleNotificationsClick}
        lastViewedNotificationId={lastViewedNotificationId}
        userTypeId={user.typeId}
      />
      <S.Wrapper>
        <S.SubWrapper>{component}</S.SubWrapper>
      </S.Wrapper>
    </S.Content>
  );
};

export default LoggedInContent;
