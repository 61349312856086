import styled from "styled-components";
import colours from "../../utils/colours";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

const Header = styled.h2`
  color: ${colours.lightRed};
  font-size: 1.2em;
  margin: 2px;
  font-weight: bold;
`;

const List = styled.ul``;

const ListItem = styled.li`
  margin: 5px 0px;
  color: ${colours.white};
`;

export default { Container, Header, List, ListItem };
