import React from "react";
import S from "./styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  icon: IconProp;
  label: string;
  status?: number;
  onClick: () => void;
};

const Button = (props: Props) => {
  const { onClick, label, icon } = props;
  return (
    <S.Button
      disabled={props.status === 1 || props.status === 5}
      alert={props.status === 4}
      onClick={onClick}
    >
      <S.Icon icon={icon} />
      <S.Text>{label}</S.Text>
    </S.Button>
  );
};

export default Button;
