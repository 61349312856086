import React, { useState, useEffect } from "react";
import S from "./styles";
import TyreDetails from "./tyre-details/TyreDetails";
import Job from "./Job";
import ContactInformation from "./contact-information/ContactInformation";
import JobHistory from "./job-history/JobHistory";
import LocationDetails from "./location-details/LocationDetails";
import AdditionalInformation from "./additional-information/AdditionalInformation";
import JobOverview from "./job-overview/JobOverview";
import JobCategory from "./job-category/JobCategory";
import { faTire, faImage } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Availability from "./availability/Availability";
import { useApi, isSuccess, mapSuccess } from "../../api/Api";
import Spinner from "../spinner/Spinner";
import GeneralErrors from "../general-errors/GeneralErrors";
import ImageGallery from "../image-gallery/ImageGallery";
import HusbandryDetails from "./husbandry-details/HusbandryDetails";
import WheelSecurity from "./wheel-security/WheelSecurity";

type Props = {
  jobId: number;
  onBackClick: () => void;
};

const JobDetails = (props: Props) => {
  const { onBackClick, jobId } = props;
  const [job, setJob] = useState<Job | null>(null);
  const [key, setKey] = useState<string | null>(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = mapSuccess(
          await api.get<any>(`api/jobs/${jobId}`, "1.0"),
          (r) => ({
            key: r.key,
            job: {
              ...r.job,
              jobDate: new Date(r.job.jobDate),
              timeAllocated:
                r.job.timeAllocated === null
                  ? null
                  : new Date(r.job.timeAllocated),
              estimatedTimeOfArrival:
                r.job.estimatedTimeOfArrival === null
                  ? null
                  : new Date(r.job.estimatedTimeOfArrival),
              onSceneTime:
                r.job.onSceneTime === null ? null : new Date(r.job.onSceneTime),
              enRoute: r.job.enRoute === null ? null : new Date(r.job.enRoute),
              completed:
                r.job.completed === null ? null : new Date(r.job.completed),
              availability: r.job.availability.map((a: any) => ({
                from: new Date(a.from),
                to: new Date(a.to),
              })),
            },
          }),
        );
        setLoading(false);

        if (isSuccess(response)) {
          setJob(response.data.job);
          setKey(response.data.key);
          return;
        }
      } catch {}

      setErrors([
        t(
          "Failed to load required data. Please reload 360\u{B0}Smart and try again.",
        ),
      ]);
    })();
  }, [jobId, t, api]);

  const handleSummaryPdfClick = async () => {
    try {
      setLoading(true);
      const response = await api.getBlob(
        `api/jobs/${jobId}/summary-pdf`,
        "1.0",
      );
      setLoading(false);
      if (isSuccess(response)) {
        const filename = `job-summary-${jobId}.pdf`;
        const file = new File([response.data], filename, {
          type: "application/pdf",
        });
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
        return;
      }
    } catch {}
    setErrors([
      t(
        "Failed to load required data. Please reload 360\u{B0}Smart and try again.",
      ),
    ]);
  };

  if (job === null) return <Spinner />;

  const images = job.photos.map((item) => ({
    src: `api/jobs/${job.id}/photos/${item.id}?v=1&key=${key}`,
    position: item.position,
  }));

  if (loading) return <Spinner />;
  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  return (
    <S.JobDetails>
      <S.Title>{t("Job Details")}</S.Title>
      <JobOverview
        showForSummary={false}
        tsuNumber={job.id}
        locationType={job.locationType}
        vehicleReg={job.registration}
        attachedVehicleReg={job.attachedRegistration}
        jobDate={job.jobDate}
        poNumber={null}
        mileage={null}
      />
      <ContactInformation
        contactName={job.contactName}
        contactTelephone={job.contactTelephone}
        contactDepot={job.contactDepot || null}
        driverName={job.driverName || null}
        driverTelephone={job.driverTelephone || null}
        endUser={job.endUser}
        showEndUser={true}
      />
      {job.tyres !== undefined ? (
        <JobCategory icon={faTire} title={t("Tire Details")}>
          {job.tyres.map((item) => (
            <TyreDetails tyre={item} key={item.position} />
          ))}
        </JobCategory>
      ) : (
        <S.NoItems>{t("No Tyres Requested")}</S.NoItems>
      )}
      {job.husbandries !== undefined ? (
        <JobCategory icon={faTire} title={t("Husbandries")}>
          {job.husbandries.map((item) => (
            <HusbandryDetails husbandry={item} key={item.position} />
          ))}
        </JobCategory>
      ) : (
        <S.NoItems>{t("No Husbandries")}</S.NoItems>
      )}
      {job.countryId !== 2 && <WheelSecurity {...job} />}
      <Availability availability={job.availability}></Availability>
      <JobHistory
        allocated={job.timeAllocated}
        serviceProvider={job.serviceProvider}
        depot={job.depot}
        eta={job.estimatedTimeOfArrival}
        onScene={job.onSceneTime}
        enRoute={job.enRoute}
        completed={job.completed}
      />
      <LocationDetails
        location={job.location}
        longitude={job.longitude}
        latitude={job.latitude}
      ></LocationDetails>
      <AdditionalInformation
        additionalInformation={job.additionalInformation}
      />
      <JobCategory title={t("Photos")} icon={faImage}>
        <ImageGallery images={images}></ImageGallery>
      </JobCategory>
      {job.statusId === 4 && (
        <S.Button onClick={handleSummaryPdfClick}>
          {t("Get summary PDF")}
        </S.Button>
      )}
      <S.Button onClick={onBackClick}>{t("Back")}</S.Button>
    </S.JobDetails>
  );
};

export default JobDetails;
