import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _Button from "../../button/Button";

const LocationType = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 5px;
  }
`;

const Title = styled.label``;

const Button = styled(_Button)`
  display: flex;
  width: 16rem;
  align-items: center;
  padding: 10px 10px;

  & > span {
    padding-left: 15px;
  }
`;

const IconWrapper = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 48px;
`;

const BackButton = styled(_Button)`
  width: 16rem;
`;

export default {
  LocationType,
  Title,
  Button,
  Icon,
  IconWrapper,
  BackButton,
};
