import React from "react";
import S from "./styles";
import JobCategory from "../../../job-details/job-category/JobCategory";
import { faClipboardList } from "@fortawesome/pro-solid-svg-icons";
import JobSubCategory from "../../../job-details/job-sub-category/JobSubCategory";
import { useTranslation } from "react-i18next";

type Props = {
  tsuNumber: number;
  eta: Date | null;
  customer?: string;
  endUser?: string;

  locationType: string;
  vehicleReg: string;
  attachedVehicleReg?: string;
};

const JobOverview = (props: Props) => {
  const { t } = useTranslation();

  return (
    <JobCategory icon={faClipboardList} title={t("Job overview")}>
      <JobSubCategory>
        <S.JobOverview>
          <S.DetailContainer>
            <S.Detail>{t("TSU number")}</S.Detail>
            <S.DetailContent>{props.tsuNumber}</S.DetailContent>
          </S.DetailContainer>
        </S.JobOverview>
      </JobSubCategory>

      <JobSubCategory>
        <S.DetailContainer>
          <S.Detail>{t("Vehicle")}</S.Detail>
          <S.DetailContent>{props.vehicleReg}</S.DetailContent>
        </S.DetailContainer>
        <S.DetailContainer>
          <S.Detail>{t("Attached Vehicle")}</S.Detail>
          {props.attachedVehicleReg !== null ? (
            <S.DetailContent>{props.attachedVehicleReg}</S.DetailContent>
          ) : (
            <S.DetailContent>N/A</S.DetailContent>
          )}
        </S.DetailContainer>
      </JobSubCategory>

      <JobSubCategory>
        <S.JobOverview>
          <S.DetailContainer>
            <S.Detail>{t("Customer")}</S.Detail>
            <S.DetailContent>{props.customer}</S.DetailContent>
          </S.DetailContainer>
        </S.JobOverview>
      </JobSubCategory>

      <JobSubCategory>
        <S.JobOverview>
          <S.DetailContainer>
            <S.Detail>{t("End User")}</S.Detail>
            {props.endUser !== null ? (
              <S.DetailContent>{props.endUser}</S.DetailContent>
            ) : (
              <S.DetailContent>N/A</S.DetailContent>
            )}
          </S.DetailContainer>
        </S.JobOverview>
      </JobSubCategory>

      {props.eta !== null ? (
        <JobSubCategory>
          <S.JobOverview>
            <S.DetailContainer>
              <S.Detail>{t("ETA")}</S.Detail>
              <S.DetailContent>
                {t("DisplayDate", { date: props.eta })}
              </S.DetailContent>
            </S.DetailContainer>
          </S.JobOverview>
        </JobSubCategory>
      ) : (
        <div></div>
      )}

      <JobSubCategory>
        <S.JobOverview>
          <S.DetailContainer>
            <S.Detail>{t("Location type")}</S.Detail>
            <S.DetailContent>{t(props.locationType)}</S.DetailContent>
          </S.DetailContainer>
        </S.JobOverview>
      </JobSubCategory>
    </JobCategory>
  );
};

export default JobOverview;
