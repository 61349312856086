import styled from "styled-components";

const TyreDetails = styled.div`
  width: 100%;

  & svg {
    margin-right: 5px;
  }
`;

const DetailContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Detail = styled.span`
  margin-bottom: 5px;
`;

const DetailContent = styled.span`
  margin-bottom: 5px;
  margin-left: 10px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 3px;
`;

const DetailBlock = styled.span`
  text-align: right;
`;

export default {
  TyreDetails,
  DetailContainer,
  Detail,
  DetailContent,
  Title,
  DetailBlock
};
