import React, { useState } from "react";
import S from "./styles";
import Thumbnail from "./thumbnail/Thumbnail";
import FullImageViewer from "../modal/image-viewer/ImageViewer";

type Image = {
  position?: string;
  src: string;
};

type Props = {
  images: Image[];
};

const ImageGallery = (props: Props) => {
  const { images } = props;

  const [fullImage, setFullImage] = useState<Image | null>(null);

  const withThumbnails = images.map((x) => ({
    ...x,
    thumbnailSrc: x.src.replace("?", "/thumbnail?"),
  }));

  return (
    <S.Parent>
      {fullImage && (
        <FullImageViewer
          src={fullImage.src}
          position={fullImage.position}
          onClose={() => setFullImage(null)}
        />
      )}
      <S.Thumbnails>
        {withThumbnails.map((image, index) => (
          <Thumbnail
            key={index}
            src={image.thumbnailSrc}
            onClick={() => setFullImage(image)}
            position={image.position}
          />
        ))}
      </S.Thumbnails>
    </S.Parent>
  );
};

export default ImageGallery;
