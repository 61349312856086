import styled from "styled-components";
import _Button from "../../../../../button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WorkType = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled(_Button)`
  width: 16rem;
  margin-bottom: 5px;
  display: flex;
  padding: 10px 10px;
  align-items: center;
`;

const ButtonText = styled.span`
  padding-left: 15px;
`;

const IconContainer = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 48px;
  max-height: 48px;
  max-width: 48px;
`;

const Heading = styled.p`
  margin-bottom: 5px;
`;

const BackButton = styled(_Button)`
  width: 16rem;
`;

export default {
  BackButton,
  WorkType,
  Heading,
  Button,
  ButtonText,
  Icon,
  IconContainer,
};
