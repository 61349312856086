import React, { useState } from "react";
import PositionWorkRequired from "./position-work-required/PositionWorkRequired";
import S from "./styles";
import { useTranslation } from "react-i18next";
import { Configuration as Config } from "360";
import GeneralErrors from "../../../general-errors/GeneralErrors";
import { Position } from "360";

type Props = {
  initialValue: number[] | null;
  configuration: Config;
  reference: string;
  onChangeConfiguration: () => void;
  onComplete: (positionsWithWork: number[]) => void;
  onBackClick: () => void;
  onFail: () => void;
  onCancel: () => void;
};

const Configuration = (props: Props) => {
  const {
    onCancel,
    configuration,
    reference,
    onChangeConfiguration,
    onComplete,
    onBackClick,
    onFail,
    initialValue,
  } = props;

  const [editingPosition, setEditingPosition] = useState<Position | null>(null);
  const [positionIdsWithWork, setPositionsWithWork] = useState<number[]>(
    initialValue || []
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [promptedForLostWork, setPromptedForLostWork] = useState<boolean>(
    false
  );
  const { t } = useTranslation();
  const workAlreadySubmitted = initialValue !== null;

  const handlePositionComplete = () => {
    if (editingPosition !== null) {
      setPositionsWithWork([...positionIdsWithWork, editingPosition.id]);
    }

    setEditingPosition(null);
  };

  const handleContinueClick = () => {
    if (positionIdsWithWork.length > 0) return onComplete(positionIdsWithWork);

    setErrors([t("You must add work to at least one position.")]);
  };

  const handleBackClick = () => {
    if (positionIdsWithWork.length > 0 || !workAlreadySubmitted)
      return onBackClick();

    setErrors([t("You must add work to at least one position.")]);
  };

  const handleChangeConfigurationClick = () => {
    if (positionIdsWithWork.length === 0 || promptedForLostWork)
      return onChangeConfiguration();

    setErrors([
      t(
        "Warning: You will lose existing work on positions if you change the configuration."
      ),
    ]);
    setPromptedForLostWork(true);
  };

  const handlePositionRemoved = () => {
    if (editingPosition) {
      const newPositionIdsWithWork = positionIdsWithWork.filter(
        (id) => id !== editingPosition.id
      );
      setPositionsWithWork(newPositionIdsWithWork);
    }

    setEditingPosition(null);
  };

  const handlePositionCancel = () => setEditingPosition(null);

  const positions = [];

  for (const axleNumber in configuration.axles) {
    const axle = configuration.axles[axleNumber];

    for (const positionNumber in axle.positions) {
      const position = axle.positions[positionNumber];

      positions.push(
        <S.Position
          className={`axle${axleNumber} pos${positionNumber}`}
          key={position.id}
          hasWork={positionIdsWithWork.indexOf(position.id) > -1}
          onClick={() => setEditingPosition(position)}
        >
          {position.description}
        </S.Position>
      );
    }
  }

  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  if (editingPosition)
    return (
      <PositionWorkRequired
        position={editingPosition}
        reference={reference}
        onComplete={handlePositionComplete}
        onFail={onFail}
        onRemove={handlePositionRemoved}
        onCancel={handlePositionCancel}
        hasExistingWork={positionIdsWithWork.indexOf(editingPosition.id) > -1}
      />
    );

  return (
    <S.Configuration>
      <S.Grid>{positions}</S.Grid>
      <S.Button onClick={handleChangeConfigurationClick}>
        {t("Change configuration")}
      </S.Button>
      <S.Button onClick={handleContinueClick}>{t("Continue")}</S.Button>
      <S.Button onClick={handleBackClick}>{t("Back")}</S.Button>
      <S.Button onClick={onCancel}>{t("Save for later or cancel")}</S.Button>
    </S.Configuration>
  );
};

export default Configuration;
