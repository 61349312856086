import React, { useState, useEffect } from "react";
import { Api, useApi, Response, isSuccess } from "../../../api/Api";
import Spinner from "../../spinner/Spinner";
import { ConfirmedWork, ConfirmationRequired } from "./models";
import PositionWorkConfirmation from "./PositionWorkConfirmation";

const getMakes = (api: Api): Promise<Response<string[]>> =>
  api.get("api/tyre-makes", "1.0");

type Props = {
  confirmationRequired: ConfirmationRequired[];
  onComplete: (confirmedWork: ConfirmedWork[]) => void;
  onFail: (errors?: string[]) => void;
};

const WorkConfirmation = (props: Props) => {
  const { confirmationRequired, onComplete, onFail } = props;
  const [makes, setMakes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmedWork, setConfirmedWork] = useState<ConfirmedWork[]>([]);
  const api = useApi();
  const confirmedIds = confirmedWork.map((x) => x.id);
  const pendingConfirmation = confirmationRequired.filter(
    (cr) => !confirmedIds.includes(cr.id)
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await getMakes(api);
        setLoading(false);

        if (isSuccess(response)) {
          return setMakes(response.data);
        }
      } catch {}

      onFail();
    })();
  }, [api, onFail]);

  const handlePositionComplete = (cw: ConfirmedWork) => {
    const updated = [...confirmedWork, cw];

    if (updated.length === confirmationRequired.length) {
      return onComplete(updated);
    }

    setConfirmedWork(updated);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <PositionWorkConfirmation
      key={pendingConfirmation[0].id}
      confirmationRequired={pendingConfirmation[0]}
      makes={makes}
      onComplete={handlePositionComplete}
    />
  );
};

export default WorkConfirmation;
