import React from "react";
import S from "./styles";
import JobCategory from "../job-category/JobCategory";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import JobSubCategory from "../job-sub-category/JobSubCategory";
import { GoogleMap, Marker } from "@react-google-maps/api";

type MapProps = {
  latitude: number;
  longitude: number;
};

const Map = (props: MapProps) => {
  const { latitude, longitude } = props;
  const position = { lat: latitude, lng: longitude };

  return (
    <S.MapContainer>
      <GoogleMap
        mapContainerClassName="map"
        zoom={16}
        center={position}
        options={{
          fullscreenControl: false,
          clickableIcons: false,
          mapTypeControl: false,
          streetViewControl: false,
          draggable: false
        }}
      >
        <Marker position={position} />
      </GoogleMap>
    </S.MapContainer>
  );
};

type Props = {
  location: string | null;
  longitude: number | null;
  latitude: number | null;
  LocationEditClicked?: () => void;
};

const LocationDetails = (props: Props) => {
  const { location, longitude, latitude, LocationEditClicked } = props;
  const { t } = useTranslation();

  return (
    <JobCategory icon={faMapMarkedAlt} title={t("Location")}>
      <JobSubCategory onEditClicked={LocationEditClicked}>
        <S.DetailContainer>
          {location !== null && <S.Detail>{t("Location")}</S.Detail>}
          {location !== null && <S.DetailContent>{location}</S.DetailContent>}

          {latitude !== null && longitude !== null && (
            <S.Detail>{t("Coordinates")}</S.Detail>
          )}
          {latitude !== null && longitude !== null && (
            <Map latitude={latitude} longitude={longitude} />
          )}
        </S.DetailContainer>
      </JobSubCategory>
    </JobCategory>
  );
};

export default LocationDetails;
