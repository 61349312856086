import React, { useState, useEffect } from "react";
import S from "./styles";
import JobResult from "./job-result/JobResult";
import { useTranslation } from "react-i18next";
import { useApi, isSuccess } from "../../api/Api";
import GeneralErrors from "../general-errors/GeneralErrors";
import Spinner from "../spinner/Spinner";
import { JobDetails as JD } from "360";
import JobDetails from "../job-details/JobDetails";

const Results = (props: { jobs: JD[]; onViewJob: (id: number) => void }) => (
  <S.JobResults>
    {props.jobs.map((job) => (
      <JobResult
        key={job.id}
        id={job.id}
        registration={job.registration}
        locationType={job.locationType}
        onViewJob={props.onViewJob}
        jobStatus={job.jobStatus}
      />
    ))}
  </S.JobResults>
);

const NoRecentJobs = () => {
  const { t } = useTranslation();

  return <div>{t("You have no recent jobs.")}</div>;
};

const RecentJobs = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [jobs, setJobs] = useState<JD[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [viewingJobId, setViewingJobId] = useState<number | null>(null);
  const { t } = useTranslation();
  const api = useApi();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<JD[]>(`api/jobs`, "1.0");
        setLoading(false);

        if (isSuccess(response)) return setJobs(response.data);
      } catch {}

      setErrors([
        t(
          "Failed to load required data. Please reload 360\u{B0}Smart and try again."
        ),
      ]);
    })();
  }, [t, api]);

  if (loading) return <Spinner />;

  if (errors.length > 0)
    return <GeneralErrors errors={errors} onClose={() => setErrors([])} />;

  if (viewingJobId)
    return (
      <JobDetails
        jobId={viewingJobId}
        onBackClick={() => setViewingJobId(null)}
      />
    );

  return (
    <S.JobsContent>
      <S.Title>{t("Recent jobs")}</S.Title>
      {jobs.length > 0 && <Results jobs={jobs} onViewJob={setViewingJobId} />}
      {jobs.length === 0 && <NoRecentJobs />}
    </S.JobsContent>
  );
};

export default RecentJobs;
