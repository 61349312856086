import styled from "styled-components";
import _Button from "../../button/Button";
import _Input from "../../input/Input";
import _TextArea from "../../textarea/TextArea";

const WorkComplete = styled.div`
  display: flex;
  flex-direction: column;

  & p,
  input,
  select,
  button,
  label {
    display: block;
    margin: 3px 0;
  }
`;

const Button = styled(_Button)`
  width: 16rem;
`;

const TextContent = styled.span`
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
`;

const TextWarning = styled(TextContent)`
  font-weight: bold;
`;

const Input = styled(_Input)`
  width: 16rem;
  margin-bottom: 5px;
`;

const TextArea = styled(_TextArea)`
  width: 16rem;
  height: 20rem;
  margin-bottom: 5px;
`;

const Title = styled.div`
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
`;

const MissingPhotosWarning = styled.div`
  display: flex;
  flex-direction: column;
  width: 16rem;

  & > ul,
  & > p {
    list-style: inside;
    margin: 5px 0px;
  }
`;

const Heading = styled.p`
  width: 16rem;
`;

export default {
  WorkComplete,
  Button,
  TextContent,
  TextWarning,
  Title,
  Input,
  MissingPhotosWarning,
  TextArea,
  Heading,
};
