import React, { useState } from "react";
import HusbandryWorkConfirmation from "./HusbandryWorkConfirmation";
import { Husbandry } from "./models";

type Props = {
  unconfirmedHusbandries: Husbandry[];
  onComplete: (confirmedHusbandries: Husbandry[]) => void;
};

const HusbandryConfirmation = (props: Props) => {
  const { unconfirmedHusbandries, onComplete } = props;
  const [confirmedHusbandries, setConfirmedHusbandries] = useState<Husbandry[]>(
    []
  );
  const confirmedIds = confirmedHusbandries.map((x) => x.id);
  const pendingConfirmation = unconfirmedHusbandries.filter(
    (h) => !confirmedIds.includes(h.id)
  );

  const handleHusbandryComplete = (ch: Husbandry) => {
    const updated = [...confirmedHusbandries, ch];
    if (updated.length === unconfirmedHusbandries.length) {
      return onComplete(updated);
    }
    setConfirmedHusbandries(updated);
  };

  return (
    <HusbandryWorkConfirmation
      key={pendingConfirmation[0].id}
      unconfirmedHusbandry={pendingConfirmation[0]}
      onComplete={handleHusbandryComplete}
    />
  );
};

export default HusbandryConfirmation;
