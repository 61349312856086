import React from "react";
import { useTranslation } from "react-i18next";
import * as M from "360";
import { faFileDownload } from "@fortawesome/pro-solid-svg-icons";
import S from "./styles";

type DocumentWithUrl = M.Document & { url: string };

const Documents = ({ documents }: { documents: DocumentWithUrl[] }) => {
  return (
    <S.Documents>
      {documents
        .sort((a, b) => a.filename.localeCompare(b.filename))
        .map(document => (
          <Document key={document.id} {...document} />
        ))}
    </S.Documents>
  );
};

const Document = (document: DocumentWithUrl) => {
  const { id, filename, documentType, effective, expiry, url } = document;
  const { t } = useTranslation();

  return (
    <S.Document key={id}>
      <S.Header>
        <S.FileDetails>
          <h3>{filename}</h3>
          <h3>{documentType}</h3>
        </S.FileDetails>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <S.Icon icon={faFileDownload} />
        </a>
      </S.Header>
      {effective && (
        <S.FileDate>{t("DOCUMENT_EFFECTIVE", { effective })}</S.FileDate>
      )}
      {expiry && <S.FileDate>{t("DOCUMENT_EXPIRY", { expiry })}</S.FileDate>}
    </S.Document>
  );
};

export default Documents;
