import React from "react";
import MainMenu from "./main-menu/MainMenu";
import LoggedInSubMenu from "./sub-menu/LoggedInSubMenu";
import S from "./styles";

type Props = {
  onPhoneClick: () => void;
  onHomeClick: () => void;
  onNotificationClick: () => void;
  onLogoutClick: () => void;
  lastViewedNotificationId: number | null;
  userTypeId: number;
};

const Menu = (props: Props) => {
  const {
    onPhoneClick,
    onHomeClick,
    onNotificationClick,
    onLogoutClick,
    lastViewedNotificationId,
    userTypeId
  } = props;

  return (
    <S.MenuContainer>
      <MainMenu />
      <LoggedInSubMenu
        onHomeClick={onHomeClick}
        onNotificationClick={onNotificationClick}
        onPhoneClick={onPhoneClick}
        onLogoutClick={onLogoutClick}
        lastViewedNotificationId={lastViewedNotificationId}
        userTypeId={userTypeId}
      />
    </S.MenuContainer>
  );
};

export default Menu;
