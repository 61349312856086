import React from "react";
import StandardHome from "./StandardHome";
import SupplierHome from "./SupplierHome";

type Props =
  | {
      onBreakdownClick: () => void;
      onBreakdownsInProgressClick: () => void;
      onTodaysJobsClick: () => void;
      onSearchClick: () => void;
      onPhoneUsClick: () => void;
      countryId: number;
      supplier: false;
    }
  | {
      onTodaysJobsClick: () => void;
      onSearchClick: () => void;
      onAllocatedJobsClick: () => void;
      onPhoneUsClick: () => void;
      onSupplierDocumentsClick: () => void;
      countryId: number;
      supplier: true;
    };

const Home = (props: Props) =>
  props.supplier ? <SupplierHome {...props} /> : <StandardHome {...props} />;

export default Home;
