import React from "react";
import S from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faClipboardList } from "@fortawesome/pro-solid-svg-icons";
import { Notification } from "360";
import parseNotification from "../../../../utils/parseNotification";
import { useTranslation } from "react-i18next";

const BubbleContent = ({ notification }: { notification: Notification }) => {
  const { t } = useTranslation();

  return (
    <S.BubbleContent>
      <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
      <S.NotificationDetails>{notification.vehicleReg}</S.NotificationDetails>
      <FontAwesomeIcon icon={faClipboardList}></FontAwesomeIcon>
      <S.NotificationDetails>{notification.jobId}</S.NotificationDetails>
      <S.NotificationContent>
        {parseNotification(notification, t)}
      </S.NotificationContent>
    </S.BubbleContent>
  );
};

export default BubbleContent;
