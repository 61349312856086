import styled from "styled-components";
import _Button from "../../button/Button";
import _Input from "../../input/Input";

const Button = styled(_Button)`
  width: 16rem;
  margin-bottom: 3px;
`;

const Input = styled(_Input)`
  width: 16rem;
  margin-bottom: 5px;
`;
const Title = styled.div`
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
`;

const OnScene = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default { Button, Input, Title, OnScene };
