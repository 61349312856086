import React from "react";
import S from "./styles";
import { useTranslation } from "react-i18next";

type WrapperProps = {
  position: string;
  children: React.ReactNode;
};

const PositionContainer = (props: WrapperProps) => {
  const { position, children } = props;
  const { t } = useTranslation();
  return (
    <S.WorkComplete>
      <S.Heading>
        {t(
          "Please provide the following information for the {{position}} position.",
          { position }
        )}
      </S.Heading>
      {children}
    </S.WorkComplete>
  );
};

export default PositionContainer;
